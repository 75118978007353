import React, { Fragment } from "react";

import Box from "@mui/material/Box";
import { RenderComponent } from "../../ComponentController";
import Loader from "../LoadingPage/LoadingPage";
import SaitGlobalMessage from "../../components/atomic/SaitGlobalMessage";
import SaitBackdrop from "../../components/atomic/SaitBackdrop";
import SaitGlobalConfirm from "../../components/atomic/SaitGlobalConfirm";
import { useSelector } from "react-redux";

export default function MainPage(props) {
	const activeTabId = useSelector((state) => state["generalConfig"]["activeTabId"]);
	const openTabs = useSelector((state) => state["generalConfig"]["openTabs"]);

	return (
		<Fragment>
			{openTabs.length ? (
				openTabs.map((openTab, index) => (
					<Box
						key={openTab.id}
						component="span"
						sx={{
							display: activeTabId === openTab.id ? "flex" : "none",
							width: "100%",
							height: "100%",
							flexDirection: "column",
							padding: 2,
						}}
					>
						<SaitBackdrop module={openTab.id} />
						<SaitGlobalMessage module={openTab.id} />
						<SaitGlobalConfirm module={openTab.id} />
						<RenderComponent
							component={openTab}
							activeTabId={activeTabId}
							theme={props.theme}
						/>
					</Box>
				))
			) : (
				<Loader />
			)}
		</Fragment>
	);
}
