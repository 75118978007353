import React, { Fragment, useEffect } from "react";
import dayjs from "dayjs";
import "dayjs/locale/it";
import {
	TEXTFIELD,
	DATEFIELD,
	COMBO,
	SEARCHWINDOW,
	DYNAMICCOMBO,
	CHECKBOX,
	SWITCH,
} from "../../utils/ComponentList";
import SaitTextField from "./SaitTextField";
import SaitDateField from "./SaitDateField";
import { Box } from "@mui/material";
import SaitSearchTextField from "./SaitSearchTextField";
import {
	CONTAINS,
	ENDS_WITH,
	EQUAL,
	IN,
	NOT_NULL,
	NULL,
	STARTS_WITH,
	MULTICOLUMN_IN,
	NUMBER_EQUAL,
	NUMBER_NOT_EQUAL,
	NUMBER_GREATER,
	NUMBER_GREATER_EQUAL,
	NUMBER_LESS,
	NUMBER_LESS_EQUAL,
	DATE_EQUAL,
	DATE_NOT_EQUAL,
	DATE_GREATER,
	DATE_GREATER_EQUAL,
	DATE_LESS,
	DATE_LESS_EQUAL,
} from "../../utils/FiltersOperators";
import SaitDynamicCombo from "./SaitDynamicCombo";
import SaitCheckbox from "./SaitCheckbox";
import SaitSwitch from "./SaitSwitch";
import SaitComboFilter from "./SaitComboFilter";

export default function SaitFilters(props) {
	const defaultMinWidth = 150;
	const defaultDateWidth = 140;
	const defaultSize = "small";

	const [valueFilters, setValueFilters] = React.useState([]);

	useEffect(() => {
		updateFilters();
		// eslint-disable-next-line
	}, [JSON.stringify(props.filters)]);

	useEffect(() => {
		createJsonFilter();
		// eslint-disable-next-line
	}, [JSON.stringify(valueFilters)]);

	useEffect(() => {
		createJsonFilter();
		// eslint-disable-next-line
	}, [JSON.stringify(props.gridFilters)]);

	useEffect(() => {
		if (props.resetFilters) {
			resetFilters();
			props.setResetFilters(false);
		}
		// eslint-disable-next-line
	}, [props.resetFilters]);

	const updateFilters = () => {
		let newValueFilters = [];

		props.filters?.forEach((newFilter, index) => {
			if (
				newFilter.type === COMBO ||
				newFilter.type === DYNAMICCOMBO ||
				newFilter.type === SEARCHWINDOW
			) {
				let valueFilter = valueFilters.find((oldValueFilter) => {
					return JSON.stringify(oldValueFilter.name) === JSON.stringify(newFilter.name);
				});

				let comboValue = {};
				if (newFilter.hasOwnProperty("value")) {
					// LA COMBO HA UN VALORE IMPOSTATO
					comboValue = getComboValue(newFilter, newFilter.value);
				} else if (valueFilter?.value?.id || valueFilter?.value?.[0]?.id) {
					// SI STA RENDERIZZANDO UNA COMBO CON UN VALORE GIà SCELTO DALL'UTENTE
					if (newFilter.multiple) {
						comboValue = [];
						valueFilter.value.forEach((value, index) => {
							comboValue.push(value);
						});
					} else {
						comboValue = valueFilter.value;
					}
				} else if (newFilter.defaultValues) {
					// SI STA IMPOSTANDO UN DEFAULTVALUE
					if (newFilter.items.length > 0) {
						// I VALORI DELLA COMBO SON GIà STATI CARICATI
						if (newFilter.multiple) {
							comboValue = [];
							newFilter.defaultValues.forEach((defaultValue, index) => {
								comboValue.push(getComboValue(newFilter, defaultValue));
							});
						} else {
							comboValue = getComboValue(newFilter, newFilter.defaultValues);
						}
					} else {
						// I VALORI DELLA COMBO NON SONO ANCORA STATI CARICATI
						if (newFilter.multiple) {
							comboValue = [];
							newFilter.defaultValues.forEach((defaultValue, index) => {
								comboValue.push(
									defaultValue.reduce((acc, elemento) => {
										acc[elemento.defaultField] = elemento.defaultValue;
										return acc;
									}, {})
								);
							});
						} else {
							const comboValueReduce = newFilter.defaultValues.reduce(
								(acc, elemento) => {
									acc[elemento.defaultField] = elemento.defaultValue;
									return acc;
								},
								{}
							);
							comboValue = comboValueReduce;
						}
					}
				}

				newValueFilters.push({
					type: newFilter.type,
					name: newFilter.name,
					operator: newFilter.operator,
					value: comboValue,
				});
			} else {
				let valueFilter = valueFilters.find((oldValueFilter) => {
					return (
						oldValueFilter.name === newFilter.name &&
						oldValueFilter.operator === newFilter.operator
					);
				});

				let nullValue = newFilter.type === DATEFIELD ? null : "";

				if (newFilter.hasOwnProperty("customValue")) {
					newValueFilters.push({
						type: newFilter.type,
						name: newFilter.name,
						operator: newFilter.operator,
						value: newFilter.hasOwnProperty("value")
							? newFilter.value
							: valueFilter?.value || newFilter.defaultValue || nullValue,
						customValue: newFilter.customValue,
					});
				} else {
					newValueFilters.push({
						type: newFilter.type,
						name: newFilter.name,
						operator: newFilter.operator,
						value: newFilter.hasOwnProperty("value")
							? newFilter.value
							: valueFilter?.value || newFilter.defaultValue || nullValue,
					});
				}
			}
		});
		setValueFilters(newValueFilters);
	};

	const resetFilters = () => {
		let newValueFilters = [];

		props.filters?.forEach((newFilter, index) => {
			if (
				newFilter.type === COMBO ||
				newFilter.type === DYNAMICCOMBO ||
				newFilter.type === SEARCHWINDOW
			) {
				let comboValue = {};
				if (newFilter.hasOwnProperty("value")) {
					comboValue = getComboValue(newFilter, newFilter.value);
				} else if (newFilter.defaultValues) {
					if (newFilter.multiple) {
						comboValue = [];
						newFilter.defaultValues.forEach((defaultValue, index) => {
							comboValue.push(getComboValue(newFilter, defaultValue));
						});
					} else {
						comboValue = getComboValue(newFilter, newFilter.defaultValues);
					}
				}

				newValueFilters.push({
					type: newFilter.type,
					name: newFilter.name,
					operator: newFilter.operator,
					value: comboValue,
				});
			} else {
				let nullValue = newFilter.type === DATEFIELD ? null : "";

				newValueFilters.push({
					type: newFilter.type,
					name: newFilter.name,
					operator: newFilter.operator,
					value: newFilter.hasOwnProperty("value")
						? newFilter.value
						: newFilter.defaultValue || nullValue,
				});
			}
		});

		props.setGridFilters([]);

		setValueFilters(newValueFilters);
	};

	const getComboValue = (combo, value) => {
		let comboValue = {};
		combo.items.some((item) => {
			let rightItem = true;
			value.forEach((defaultValue, index) => {
				if (item[defaultValue.defaultField] !== defaultValue.defaultValue) {
					rightItem = false;
				}
			});

			if (rightItem) {
				comboValue = item;
				return true;
			}
			return false;
		});
		return comboValue;
	};

	const createJsonFilter = () => {
		let jsonFilters = [];
		valueFilters.forEach((filter) => {
			if (filter.value || filter.value === 0) {
				if (filter.type === COMBO || filter.type === DYNAMICCOMBO) {
					if (Array.isArray(filter.value)) {
						// COMBO MULTIPLE
						if (filter.value.length > 0) {
							const gridNamesArray = filter.name.map(
								(singleName) => singleName.storeName
							);

							const comboNamesArray = filter.name.map(
								(singleName) => singleName.comboName
							);

							const valuesArray = comboNamesArray.map((name) =>
								filter.value.map((singleValue) => singleValue[name])
							);
							jsonFilters.push({
								field: gridNamesArray,
								operator: MULTICOLUMN_IN,
								value: valuesArray,
							});
						}
					} else {
						if (Object.values(filter.value).some((item) => item !== "")) {
							filter.name.forEach((field) => {
								jsonFilters.push({
									field: field.storeName,
									operator: filter.operator || EQUAL,
									value: filter.value[field.comboName],
								});
							});
						}
					}
				} else if (filter.type === SEARCHWINDOW) {
					if (Object.values(filter.value).some((item) => item !== "")) {
						filter.name.forEach((field) => {
							jsonFilters.push({
								field: field.storeName,
								operator: filter.operator || EQUAL,
								value: filter.value[field.gridName],
							});
						});
					}
				} else if (filter.type === SWITCH) {
					return;
				} else {
					jsonFilters.push({
						field: filter.name,
						operator: filter.operator,
						value: filter.hasOwnProperty("customValue")
							? filter.customValue(filter.value)
							: filter.value,
					});
				}
			}
		});

		props.gridFilters?.forEach((gridFilter) => {
			if (
				gridFilter.operatorValue === "isEmpty" ||
				gridFilter.operatorValue === "isNotEmpty"
			) {
				jsonFilters.push({
					field: gridFilter.columnField,
					operator: getSaitOperator(gridFilter.operatorValue),
					value: null,
				});
			} else {
				if (gridFilter.value || gridFilter.value === 0) {
					let value = gridFilter.value;

					if (
						gridFilter.operatorValue === "is" ||
						gridFilter.operatorValue === "not" ||
						gridFilter.operatorValue === "after" ||
						gridFilter.operatorValue === "onOrAfter" ||
						gridFilter.operatorValue === "before" ||
						gridFilter.operatorValue === "onOrBefore"
					) {
						value = new Date(value).toISOString();
					}

					jsonFilters.push({
						field: gridFilter.columnField,
						operator: getSaitOperator(gridFilter.operatorValue),
						value: value,
					});
				}
			}
		});

		if (valueFilters.length === props.filters?.length) {
			props.impostaFiltri(jsonFilters);
		}
	};

	const getSaitOperator = (gridOperator) => {
		switch (gridOperator) {
			case "contains":
				return CONTAINS;
			case "equals":
				return EQUAL;
			case "startsWith":
				return STARTS_WITH;
			case "endsWith":
				return ENDS_WITH;
			case "isEmpty":
				return NULL;
			case "isNotEmpty":
				return NOT_NULL;
			case "isAnyOf":
				return IN;
			case "=":
				return NUMBER_EQUAL;
			case "!=":
				return NUMBER_NOT_EQUAL;
			case ">":
				return NUMBER_GREATER;
			case ">=":
				return NUMBER_GREATER_EQUAL;
			case "<":
				return NUMBER_LESS;
			case "<=":
				return NUMBER_LESS_EQUAL;
			case "is":
				return DATE_EQUAL;
			case "not":
				return DATE_NOT_EQUAL;
			case "after":
				return DATE_GREATER;
			case "onOrAfter":
				return DATE_GREATER_EQUAL;
			case "before":
				return DATE_LESS;
			case "onOrBefore":
				return DATE_LESS_EQUAL;

			default:
				return CONTAINS;
		}
	};

	/* Filtro Checkbox: vengono restituiti i record a Y oppure TUTTI i record della find */
	const onChangeCheckBoxFilter = (e, index) => {
		const newFilters = [...valueFilters];
		if (e === "Y") {
			newFilters[index].value = e;
		} else {
			newFilters[index].value = undefined;
		}
		setValueFilters(newFilters);
	};

	const onChangeFilter = (e, index) => {
		const newFilters = [...valueFilters];
		newFilters[index].value = e.target.value;
		setValueFilters(newFilters);
	};

	const onChangeComboFilter = (selection, index, multiple) => {
		const newFilters = [...valueFilters];

		if (multiple) {
			if (selection?.length > 0) {
				newFilters[index].value = selection;
			} else {
				newFilters[index].value = [];
			}
		} else {
			if (selection) {
				newFilters[index].value = selection;
			} else {
				newFilters[index].value = {};
			}
		}

		setValueFilters(newFilters);
	};

	const onChangeDateFilter = (yearOnly, newValue, index) => {
		const newFilters = [...valueFilters];
		let formattedValue = null;
		if (newValue) {
			if (yearOnly === true) {
				formattedValue = newValue.$y.toString();
			} else {
				if (!isNaN(newValue.$D)) {
					formattedValue = dayjs(newValue);
				}
			}
		}
		newFilters[index].value = formattedValue;
		setValueFilters(newFilters);
	};

	/* 	const onChangeSwitchFilter = (e, index) => {
		const newFilters = [...valueFilters];
		if (e === true) {
			newFilters[index].value = e;
		} else {
			newFilters[index].value = undefined;
		}
		setValueFilters(newFilters);
	}; */

	const onClearFilter = (index) => {
		const newFilters = [...valueFilters];
		newFilters[index].value.forEach((value, indexValue) => {
			newFilters[index].value[indexValue] = "";
		});
		setValueFilters(newFilters);
	};

	return (
		<Box
			sx={{
				display: "flex",
				flexWrap: "wrap",
				gap: "10px",
				alignItems: "center",
			}}
		>
			{props.filters?.map((filter, index) => (
				<Fragment key={index}>
					{filter.type ? (
						filter.type === TEXTFIELD ? (
							<SaitTextField
								{...filter}
								label={filter.label}
								value={valueFilters[index]?.value || ""}
								sx={{
									flex: filter.width ? null : "1 0 " + defaultMinWidth + "px",
									width: filter.width ? filter.width : "100%",
								}}
								size={filter.size || defaultSize}
								onChange={(e) => onChangeFilter(e, index)}
								onKeyDown={(event) => {
									if (event.key === "Enter") {
										event.preventDefault();
										props.handleFireFilter();
									}
								}}
							/>
						) : filter.type === DATEFIELD ? (
							<SaitDateField
								label={filter.label}
								views={filter.views}
								openTo={filter.openTo}
								disablePast={filter.disablePast}
								placeholder={filter.placeholder}
								inputFormat={filter.inputFormat}
								value={valueFilters[index]?.value || null}
								onChange={(newDate) =>
									onChangeDateFilter(filter.yearOnly, newDate, index)
								}
								sx={{
									flex: null,
									width: filter.width ? filter.width : defaultDateWidth,
								}}
								size={filter.size || defaultSize}
								onKeyDown={(event) => {
									if (event.key === "Enter") {
										event.preventDefault();
										props.handleFireFilter();
									}
								}}
							/>
						) : filter.type === COMBO ? (
							<SaitComboFilter
								label={filter.label}
								value={valueFilters[index]?.value || (filter.multiple ? [] : {})}
								sx={{
									flex: filter.width ? null : "1 0 " + defaultMinWidth + "px",
									width: filter.width ? filter.width : "100%",
								}}
								onClear={() => onClearFilter(index)}
								onChange={(row) => onChangeComboFilter(row, index, filter.multiple)}
								size={filter.size}
								description={filter.description}
								name={filter.name}
								findParameters={filter.findParameters}
								items={filter.items}
								multiple={filter.multiple}
								limitTags={filter.limitTags}
								required={filter.required}
								disabled={filter.disabled}
							/>
						) : filter.type === DYNAMICCOMBO ? (
							<SaitDynamicCombo
								{...filter}
								value={valueFilters[index]?.value || {}}
								sx={{
									flex: filter.width ? null : "1 0 " + defaultMinWidth + "px",
									width: filter.width ? filter.width : "100%",
								}}
								index={index}
								onClear={() => onClearFilter(index)}
								filterCombo={true}
								valueFilters={valueFilters}
								setValueFilters={setValueFilters}
							/>
						) : filter.type === SEARCHWINDOW ? (
							<SaitSearchTextField
								key={index}
								label={filter.label}
								value={valueFilters[index]?.value || {}}
								name={filter.name}
								sx={{
									flex: filter.width ? null : "1 0 " + defaultMinWidth + "px",
									width: filter.width ? filter.width : "100%",
								}}
								onChange={(row) => onChangeComboFilter(row, index, false)}
								searchWindow={filter.searchWindow}
								size={filter.size}
								description={filter.description}
								filterSearchWindow={true}
								noClearable={filter.noClearable}
							/>
						) : filter.type === CHECKBOX ? (
							<SaitCheckbox
								key={index}
								label={filter.label}
								value={valueFilters[index]?.value || "N"}
								onChange={(checked) => {
									onChangeCheckBoxFilter(checked, index);
								}}
							/>
						) : filter.type === SWITCH ? (
							<SaitSwitch
								key={index}
								startLabel={filter.startLabel || null}
								endLabel={filter.endLabel || null}
								checked={valueFilters[index]?.value || false}
								onChange={(checked) => {
									filter.onChange(checked);
								}}
							/>
						) : null
					) : null}
				</Fragment>
			))}
			{props.filterButton}
		</Box>
	);
}
