import React from "react";
import Tooltip from "@mui/material/Tooltip";
import { IconButton, Typography } from "@mui/material";

/*
	Obbligatori:
		id
		icon
		onClick
	Facoltativi:
		conditionalRendering
		tooltip
*/

export default function SaitRowAction(config) {
	return {
		headerName: "",
		field: config.id,
		width: 45,
		minWidth: 40,
		actionColumn: true,
		sortable: false,
		disableColumnMenu: true,
		renderCell: (params) => {
			if (!config.conditionalRendering || config.conditionalRendering(params)) {
				return (
					<Tooltip title={<Typography fontSize={14}>{config.tooltip}</Typography>}>
						<IconButton
							onClick={(e) => {
								config.onClick(params);
							}}
						>
							{config.icon}
						</IconButton>
					</Tooltip>
				);
			}
		},
	};
}
