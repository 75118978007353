export function getBlobPDF(responseFile) {
	var byteCharacters = window.atob(responseFile);
	var byteNumbers = new Array(byteCharacters.length);
	for (var i = 0; i < byteCharacters.length; i++) {
		byteNumbers[i] = byteCharacters.charCodeAt(i);
	}
	var byteArray = new Uint8Array(byteNumbers);
	return new Blob([byteArray], { type: "application/pdf;base64" });
}

export function getBlobImage(contentType, responseFile) {
	const base64ImageData = "data:" + contentType + ";base64," + encodeURI(responseFile);

	const byteCharacters = window.atob(
		base64ImageData.substring(`data:${contentType};base64,`.length)
	);
	const byteArrays = [];

	for (let offset = 0; offset < byteCharacters.length; offset += 1024) {
		const slice = byteCharacters.slice(offset, offset + 1024);

		const byteNumbers = new Array(slice.length);
		for (let i = 0; i < slice.length; i++) {
			byteNumbers[i] = slice.charCodeAt(i);
		}

		const byteArray = new Uint8Array(byteNumbers);

		byteArrays.push(byteArray);
	}
	return new Blob(byteArrays, { type: contentType });
}

export function getBlobXlsxFromBuffer(fileBuffer) {
	const fileType =
		"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
	return new Blob([fileBuffer], { type: fileType });
}

export function getBlobXlsx(fileBuffer) {
	var byteCharacters = window.atob(fileBuffer);
	var byteNumbers = new Array(byteCharacters.length);
	for (var i = 0; i < byteCharacters.length; i++) {
		byteNumbers[i] = byteCharacters.charCodeAt(i);
	}
	var byteArray = new Uint8Array(byteNumbers);
	const fileType =
		"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
	return new Blob([byteArray], { type: fileType });
}

export function getBlobXls(fileBuffer) {
	var byteCharacters = window.atob(fileBuffer);
	var byteNumbers = new Array(byteCharacters.length);
	for (var i = 0; i < byteCharacters.length; i++) {
		byteNumbers[i] = byteCharacters.charCodeAt(i);
	}
	var byteArray = new Uint8Array(byteNumbers);
	const fileType = "application/vnd.ms-excel";
	return new Blob([byteArray], { type: fileType });
}

export function downloadFile(blob, fileName) {
	const url = URL.createObjectURL(blob);

	// Crea un link nascosto per il download automatico
	const downloadLink = document.createElement("a");
	downloadLink.href = url;
	downloadLink.target = "_blank";
	downloadLink.download = fileName;

	// Simula il clic sul link per avviare il download
	document.body.appendChild(downloadLink);
	downloadLink.click();
	document.body.removeChild(downloadLink);
}
