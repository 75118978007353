import React, { useEffect } from "react";
import { Backdrop, CircularProgress } from "@mui/material";
import { useSelector } from "react-redux";
import { defaultAppBarHeight, defaultOpenDrawerWidth } from "../../theme";

export default function SaitBackdrop(props) {
	const module = useSelector((state) => state["module"]["module"]);
	const globalLoading = useSelector((state) => state["globalLoading"]["globalLoading"]);
	const menuOpen = useSelector((state) => state["generalConfig"]["menuOpen"]);

	const [visible, setVisible] = React.useState(true);

	useEffect(() => {
		if (module === props.module) {
			setVisible(true);
		} else {
			setVisible(false);
		}
	}, [module, props.module]);

	return (
		<Backdrop
			sx={{
				color: "#fff",
				zIndex: 9000,
				marginTop: `${window.Configs.appBarHeight || defaultAppBarHeight}px`,
				marginLeft: menuOpen
					? `${window.Configs.openDrawerWidth || defaultOpenDrawerWidth}px`
					: `64px`,
			}}
			open={visible && globalLoading.includes(props.module)}
			onClick={null}
		>
			<CircularProgress color="inherit" />
		</Backdrop>
	);
}
