import React, { useEffect, useRef } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import Paper from "@mui/material/Paper";
import { checkOpen } from "../../../utils/SaitUtils";
import SaitDialog from "../../../components/atomic/SaitDialog";
import i18next from "i18next";
import SaitButton from "../../../components/atomic/SaitButton";
import CancelIcon from "@mui/icons-material/Cancel";
import SaveIcon from "@mui/icons-material/Save";
import SaitTextField from "../../../components/atomic/SaitTextField";
import { standardAxiosPost } from "../../../utils/AxiosUtils";
import { useDispatch } from "react-redux";
import Divider from "@mui/material/Divider";
import { debounce } from "@mui/material";

export default function EditTemplate(props) {
	const dispatch = useDispatch();

	const handleChangeValue = (newValue, idElemento, idDimensione) => {
		let newValues = props.template.valori;
		newValues.forEach((valore, index) => {
			if (valore.idDimensione === idDimensione && valore.idElemento === idElemento) {
				newValues[index].valore = newValue;
				return;
			}
		});

		props.setTemplate((prevState) => ({
			...prevState,
			valori: newValues,
		}));

		return null;
	};

	const handleDescriptionTextFieldChange = (name, newValue) => {
		props.setTemplate((prevState) => ({
			...prevState,
			[name]: newValue,
		}));
	};

	const onSave = async () => {
		const reqObj = {
			idTemplate: props.template.id,
			descrizioneSopra: props.template.descrizioneSopra,
			descrizioneSotto: props.template.descrizioneSotto,
			valori: props.template.valori,
		};

		await standardAxiosPost({
			dispatch: dispatch,
			procedureName: "/norma/updateValuesTemplate",
			parameters: reqObj,
			onSuccess: (response) => {
				props.close();
			},
		});
	};

	return (
		<>
			<SaitDialog
				open={checkOpen(props.open)}
				title={i18next.t("edit_template")}
				width={"lg"}
				content={
					Object.keys(props.template).length > 0 && (
						<Box>
							<Box key={props.template["id"]}>
								<Typography variant="h5">{props.template["titolo"]}</Typography>
								<TextFieldDebounce
									value={props.template["descrizioneSopra"] || ""}
									label={i18next.t("top_description")}
									onChange={(e) =>
										handleDescriptionTextFieldChange(
											"descrizioneSopra",
											e.target.value
										)
									}
									sx={{ mt: 2, mb: 2 }}
									minRows={4}
									fullWidth
									multiline
								/>
								<Divider sx={{ mb: 2 }} />
								<Box
									sx={{ justifyContent: "center" }}
									display="flex"
									alignItems="center"
								>
									<TableContainer component={Paper} style={{ width: "80%" }}>
										<Table
											sx={{
												minWidth: 650,
											}}
											id={props.template["id"]}
										>
											<TableHead key={props.template["id"]}>
												<TableRow>
													<TableCell></TableCell>
													{props.template["elementi"].map((elem) => (
														<TableCell key={elem.idElemento}>
															{elem.descElemento}
														</TableCell>
													))}
												</TableRow>
											</TableHead>
											<TableBody>
												{props.template["dimensioni"].map((dim) => (
													<TableRow key={dim.idDimensione}>
														<TableCell component="th" scope="row">
															{dim.descDimensione}
														</TableCell>

														{props.template["elementi"].map(
															(elemento) => {
																const valore = props.template[
																	"valori"
																].find(
																	(val) =>
																		val.idElemento ===
																			elemento.idElemento &&
																		val.idDimensione ===
																			dim.idDimensione
																);
																return (
																	<TableCell
																		key={`${elemento.idElemento}-${dim.idDimensione}`}
																	>
																		<TextFieldDebounce
																			value={valore.valore}
																			onChange={(e) => {
																				handleChangeValue(
																					e.target.value,
																					elemento.idElemento,
																					dim.idDimensione
																				);
																			}}
																		/>
																	</TableCell>
																);
															}
														)}
													</TableRow>
												))}
											</TableBody>
										</Table>
									</TableContainer>
								</Box>
								<Divider sx={{ mt: 2 }} />
								<TextFieldDebounce
									value={props.template["descrizioneSotto"] || ""}
									label={i18next.t("bottom_description")}
									onChange={(e) =>
										handleDescriptionTextFieldChange(
											"descrizioneSotto",
											e.target.value
										)
									}
									sx={{ mt: 2 }}
									minRows={4}
									fullWidth
									multiline
								/>
							</Box>
						</Box>
					)
				}
				actions={
					<>
						<SaitButton
							text={i18next.t("close")}
							color="error"
							endIcon={<CancelIcon />}
							onClick={props.close}
						/>
						<div style={{ flexGrow: 1 }} />
						<SaitButton
							text={i18next.t("save")}
							color="success"
							endIcon={<SaveIcon />}
							onClick={onSave}
						/>
					</>
				}
			/>
		</>
	);
}

function TextFieldDebounce(props) {
	const { value, onChange, onEnter, ...textfieldProps } = props;

	const textFieldRef = useRef("");

	const debounceTime = 200;

	const handleChangeValue = (e) => {
		if (textFieldRef.current) {
			textFieldRef.current["value"] = e.target.value;
		}

		debouncedHandleChangeValue(e);
	};

	const debouncedHandleChangeValue = debounce((newValue) => {
		onChange(newValue);
	}, debounceTime);

	useEffect(() => {
		if (textFieldRef.current) {
			if (textFieldRef.current["value"] !== props.value) {
				textFieldRef.current["value"] = props.value;
			}
		}
	}, [props.value]);

	return (
		<SaitTextField
			{...textfieldProps}
			inputRef={textFieldRef}
			onChange={(e) => {
				handleChangeValue(e);
			}}
		/>
	);
}
