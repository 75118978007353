import React from "react";
import i18next from "i18next";
import EditWindow from "../../../components/template/EditWindow";
import { IMAGE_BOX, TEXTAREA, TEXTFIELD } from "../../../utils/ComponentList";

export default function DettagliObjects(props) {
	const handleClose = () => {
		props.close();
	};

	const fields = [
		{
			title: i18next.t("main_image"),
			items: [
				{
					type: IMAGE_BOX,
					imageUrl:
						props.object.mainImagePath &&
						window.Configs.cdnAddressDev + props.object.mainImagePath,
					alt: "mainImage",
				},
			],
		},
		{
			title: i18next.t("profile_details"),
			items: [
				{
					label: i18next.t("product"),
					name: "prodotto",
					type: TEXTFIELD,
					disabled: true,
				},
				{
					label: i18next.t("product_description"),
					name: "descrizioneProdotto",
					type: TEXTFIELD,
					disabled: true,
				},
				{
					label: i18next.t("brand"),
					name: "marchio",
					type: TEXTFIELD,
					disabled: true,
				},
				{
					label: i18next.t("item_catalogue"),
					name: "articoloArchivio",
					type: TEXTFIELD,
					disabled: true,
				},
				{
					label: i18next.t("item_code"),
					name: "articoloCod",
					type: TEXTFIELD,
					disabled: true,
				},
				{
					label: i18next.t("variant") + " " + 1,
					name: "keyTaglia",
					type: TEXTFIELD,
					disabled: true,
				},
				{
					label: i18next.t("variant") + " " + 2,
					name: "keyColore",
					type: TEXTFIELD,
					disabled: true,
				},
				{
					label: i18next.t("production_lot"),
					name: "lottoProduzione",
					type: TEXTFIELD,
					disabled: true,
				},
			],
		},
		{
			title: i18next.t("profile_description"),
			items: [
				{
					label: i18next.t("profile_description"),
					name: "descrizioneCommLingua1",
					type: TEXTAREA,
					minRows: 4,
					disabled: true,
				},
			],
		},
		{
			title: i18next.t("tech_image"),
			items: [
				{
					type: IMAGE_BOX,
					imageUrl:
						props.object.technicalImagePath &&
						window.Configs.cdnAddressDev + props.object.technicalImagePath,
					alt: "techImage",
				},
			],
		},
		{
			title: i18next.t("technical_description"),
			items: [
				{
					label: i18next.t("technical_description"),
					name: "descrizioneTecLingua1",
					type: TEXTAREA,
					minRows: 4,
					disabled: true,
				},
			],
		},
	];

	return (
		<>
			<EditWindow
				open={props.open}
				title={i18next.t("object_details")}
				close={handleClose}
				fields={fields}
				stateElement={props.object}
			/>
		</>
	);
}
