import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import HttpApi from "i18next-http-backend";

i18next
	.use(initReactI18next)
	.use(HttpApi)
	.init({
		// lng: navigator.language.substring(0, 2),
		lng: "en",
		supportedLngs: ["it", "en"],
		fallbackLng: "it",

		interpolation: {
			escapeValue: false,
		},
	});

export default i18next;

export function changeLocalization(isoLang) {
	i18next.changeLanguage(isoLang?.toLowerCase() || navigator.language.substring(0, 2));
	// i18next.changeLanguage("en");
}

export function browserLocalization() {
	i18next.changeLanguage(navigator.language.substring(0, 2));
	// i18next.changeLanguage("en");
}
