import React, { useEffect } from "react";
import {
	Box,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Divider,
	useMediaQuery,
	useTheme,
} from "@mui/material";
import { useSelector } from "react-redux";
import SaitButton from "./SaitButton";
import { checkOpen } from "../../utils/SaitUtils";
import { BUTTON_UPLOAD } from "../../utils/ButtonTypes";
import SaitButtonUpload from "./SaitButtonUpload";
import { defaultAppBarHeight, defaultOpenDrawerWidth } from "../../theme";

export default function SaitDialog(props) {
	const menuOpen = useSelector((state) => state["generalConfig"]["menuOpen"]);
	const autofocus = useSelector((state) => state["generalConfig"]["searchTextClicked"]);
	const theme = useTheme();
	const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

	const module = useSelector((state) => state["module"]["module"]);
	const [windowModule, setWindowModule] = React.useState("");
	const [visible, setVisible] = React.useState(true);
	useEffect(() => {
		if (!windowModule) {
			setWindowModule(localStorage.getItem("1trueIdModule"));
		} else {
			if (module === windowModule) {
				setVisible(true);
			} else {
				setVisible(false);
			}
		}
	}, [module, windowModule]);

	const titleTextAlign = props.titleTextAlign || "center";

	return (
		<Dialog
			fullScreen={props.fullScreen || fullScreen}
			fullWidth={true}
			onClose={props.onClose}
			maxWidth={props.width}
			open={checkOpen(props.open)}
			scroll={props.scroll}
			sx={{
				display: visible ? "initial" : "none",
				marginTop: `${window.Configs.appBarHeight || defaultAppBarHeight}px`,
				marginLeft: menuOpen
					? `${window.Configs.openDrawerWidth || defaultOpenDrawerWidth}px`
					: `64px`,
			}}
			disableEnforceFocus={autofocus}
		>
			<DialogTitle
				sx={{ textAlign: titleTextAlign, fontWeight: "bold", color: props.titleColor }}
			>
				{props.title}
				{props.topButtons ? (
					<Box sx={{ float: "right" }}>
						{props.topButtons.map((button, index) => {
							switch (button.type) {
								case BUTTON_UPLOAD:
									return (
										<SaitButtonUpload
											key={index}
											text={button.text}
											onUpload={button.onUpload}
											variant={button.variant || "text"}
											disabled={button.disabled}
											multiple={button.multiple}
											accept={button.accept}
										/>
									);
								default:
									return (
										<SaitButton
											key={index}
											text={button.text}
											onClick={button.function}
											endIcon={button.icon}
											variant={button.variant || "text"}
											sx={button.sx || {}}
											color={button.color || "primary"}
											disabled={button.disabled || false}
										/>
									);
							}
						})}
					</Box>
				) : null}
			</DialogTitle>
			{props.stepper ? props.stepper : <Divider />}
			<DialogContent
				sx={{
					margin: "1px",
					minHeight: props.minHeight || 50,
					display: "flex",
					flexDirection: "column",
				}}
			>
				{checkOpen(props.open) && props.content}
			</DialogContent>
			<DialogActions style={{ display: "flex", justifyContent: "space-between" }}>
				{props.actions}
			</DialogActions>
		</Dialog>
	);
}
