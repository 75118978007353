import React, { useEffect } from "react";
import i18next from "i18next";
import { useDispatch } from "react-redux";
import { axiosFetchAllCombo, standardAxiosPost } from "../../../../utils/AxiosUtils";
import { COMBO, NUMBERFIELD, TEXTFIELD } from "../../../../utils/ComponentList";
import EditWindow from "../../../../components/template/EditWindow";

export default function NuovaAssociazioneTemplate(props) {
	useEffect(() => {
		if (props.open) {
			const comboQueries = [
				{
					findFunction: "/norma/findIwswdnormaTemplateForCombo",
					orderBy: [{ property: "descrizioneTemplate", direction: "ASC" }],
					findFilters: [],
					setItems: setItemsIwswdnormaTemplate,
				},
			];
			axiosFetchAllCombo(comboQueries);
		}
		setAssignment(associazioneBase);
		// eslint-disable-next-line
	}, [props.open]);

	const associazioneBase = {
		idTemplate: "",
		idProfile: props.profile.idProfile,
		seqTemplate: "1",
	};

	const dispatch = useDispatch();
	const [assignment, setAssignment] = React.useState({});
	const [itemsIwswdnormaTemplate, setItemsIwswdnormaTemplate] = React.useState([]);
	const onClose = () => {
		setAssignment(associazioneBase);
		props.close();
	};

	const onSave = async () => {
		await standardAxiosPost({
			dispatch: dispatch,
			procedureName: "/norma/associaTemplateProfile",
			parameters: assignment,
			onSuccess: (response) => {
				props.setControlledFilter(true);
				props.setControlledExternalFilter(true);
				props.invalidaProfilo();
				props.fetchData(response.idTemplate);
				onClose();
			},
		});
	};

	const fields = [
		{
			title: i18next.t("parameters"),
			items: [
				{
					label: i18next.t("template"),
					name: [{ storeName: "idTemplate", comboName: "idTemplate" }],
					type: COMBO,
					description: "$(descNormativa) - $(descrizioneTemplate)",
					items: itemsIwswdnormaTemplate,
					required: true,
					width: 250,
				},
				{
					label: i18next.t("profile_id"),
					name: "idProfile",
					type: TEXTFIELD,
					required: true,
					disabled: true,
					value: props.profile.idProfile + " - " + props.profile.descrizioneProdotto,
				},
				{
					label: i18next.t("seq_nr"),
					name: "seqTemplate",
					type: NUMBERFIELD,
					required: true,
				},
			],
		},
	];
	return (
		<>
			<EditWindow
				title={i18next.t("new") + " " + i18next.t("assignment")}
				open={props.open}
				width="sm"
				close={() => onClose()}
				fields={fields}
				stateElement={assignment}
				setFunction={setAssignment}
				onSave={onSave}
			/>
		</>
	);
}
