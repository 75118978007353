import React, { useEffect } from "react";
import { CONTAINS } from "../../utils/FiltersOperators";
import { TEXTFIELD } from "../../utils/ComponentList";
import i18next from "../../localization/i18n";
import FiltersAndGrid from "../../components/template/FiltersAndGrid";
import AddIcon from "@mui/icons-material/Add";
import NuovoBrand from "./NuovoBrand/NuovoBrand";

export default function Brands() {
	const [reloadGrid, setReloadGrid] = React.useState(false);
	const [brand, setBrand] = React.useState({});
	const [openNewBrand, setOpenNewBrand] = React.useState(false);
	const brandBase = {
		name: "",
		appName: "",
	};

	useEffect(() => {
		if (openNewBrand) {
			setBrand(brandBase);
		}
		// eslint-disable-next-line
	}, [openNewBrand]);

	const gridParams = {
		findFunction: "/brand/getBrands",
		orderBy: [
			{
				property: "name",
				direction: "ASC",
			},
		],
		columns: [
			{
				headerName: i18next.t("brand"),
				field: "name",
				flex: 1,
			},
			{
				headerName: i18next.t("app_name"),
				field: "appName",
				flex: 1,
			},
		],
	};

	const filters = [
		{
			label: i18next.t("brand"),
			name: "name",
			operator: CONTAINS,
			type: TEXTFIELD,
		},
	];

	const topButtons = [
		{
			text: i18next.t("new") + " " + i18next.t("brand"),
			icon: <AddIcon color="primary" />,
			variant: "text",
			function: () => {
				setOpenNewBrand(true);
			},
		},
	];

	return (
		<>
			<FiltersAndGrid
				filters={filters}
				gridParams={gridParams}
				topButtons={topButtons}
				controlledFilter={reloadGrid}
				setControlledFilter={setReloadGrid}
				autoload
				resetFilter
				inLineButtonFilter
			/>
			<NuovoBrand
				open={brand}
				newProduct={openNewBrand}
				brand={brand}
				setBrand={setBrand}
				setControlledFilter={setReloadGrid}
				close={() => {
					setOpenNewBrand(false);
					setBrand({});
				}}
			/>
		</>
	);
}
