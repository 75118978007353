import React from "react";
import i18next from "../../../localization/i18n";
import EditNoteIcon from "@mui/icons-material/EditNote";
import SaitRowAction from "./SaitRowAction";

/*
	Obbligatori:
		id
		onClick
	Facoltativi:
*/

export default function SaitRowActionEdit(config) {
	return SaitRowAction({
		id: config.id,
		icon: <EditNoteIcon color="primary" />,
		tooltip: i18next.t("edit"),
		onClick: config.onClick,
		conditionalRendering: config.conditionalRendering,
	});
}
