import { createContext, useState, useMemo } from "react";
import { createTheme } from "@mui/material/styles";
import { itIT } from "@mui/material/locale";

// mui theme settings
export const themeSettings = (mode) => {
	return {
		palette: {
			mode: mode,
			...(mode === "dark"
				? {
						// palette values for dark mode
						primary: {
							main: window.Configs.primaryMain || "#ffffff",
							light: window.Configs.primaryLight || "#d1e3fa",
						},
						secondary: {
							main: window.Configs.secondary || "#ffffff",
						},
						error: {
							main: "#ca0f02",
						},
						evenRowGrid: {
							main: "#323232",
						},
						hoverRowGrid: {
							main: "#000000",
						},
				  }
				: {
						// palette values for light mode
						primary: {
							main: window.Configs.primaryMain || "#ffffff",
							light: window.Configs.primaryLight || "#d1e3fa",
						},
						secondary: {
							main: window.Configs.secondary || "#ffffff",
						},
						evenRowGrid: {
							main: "#f9f9f9",
						},
						hoverRowGrid: {
							main: "#e6e6e6",
						},
						menuBoxIcon: {
							main: window.Configs.menuBoxIconMain,
						},
						logoBox: {
							main: window.Configs.logoBoxMain || "#ffffff",
						},
						menuBox: {
							main: window.Configs.menuBoxMain || "#ffffff",
							secondary: window.Configs.menuBoxSecondary || "#ffffff",
							third: window.Configs.menuBoxThird || "#ffffff",
						},
						homeAppbar: {
							main: window.Configs.homeAppbarMain || "#297dea",
						},
						homeAppbarIcon: {
							main: window.Configs.homeAppbarIconMain || "#ffffff",
						},
						menuItemText: {
							main: window.Configs.menuItemTextMain,
						},
				  }),
		},
		typography: {
			fontFamily: [window.Configs.fontFamily || "tahoma"].join(","),
			fontSize: 12,
		},
	};
};

// context for color mode
export const ColorModeContext = createContext({
	toggleColorMode: () => {},
});

export const useMode = () => {
	const [mode, setMode] = useState("light");

	const colorMode = useMemo(
		() => ({
			toggleColorMode: () => setMode((prev) => (prev === "light" ? "dark" : "light")),
		}),
		[]
	);

	const theme = useMemo(() => createTheme(themeSettings(mode), itIT), [mode]);
	return [theme, colorMode];
};

export const defaultOpenDrawerWidth = 260;
export const defaultAppBarHeight = 64;
