export const CONTAINS = "CONTAINS";
export const EQUAL = "EQUAL";
export const NOT_EQUAL = "NOT_EQUAL";
export const IN = "IN";
export const STARTS_WITH = "STARTS_WITH";
export const EXTRA_PARAMS = "EXTRA_PARAMS";
export const ENDS_WITH = "ENDS_WITH";
export const NULL = "NULL";
export const NOT_NULL = "NOT_NULL";
export const MULTICOLUMN_IN = "MULTICOLUMN_IN";
export const NUMBER_NOT_EQUAL = "NUMBER_NOT_EQUAL";
export const NUMBER_LESS_EQUAL = "NUMBER_LESS_EQUAL";
export const NUMBER_GREATER_EQUAL = "NUMBER_GREATER_EQUAL";
export const NUMBER_EQUAL = "NUMBER_EQUAL";
export const NUMBER_GREATER = "NUMBER_GREATER";
export const NUMBER_LESS = "NUMBER_LESS";
export const DATE_GREATER_EQUAL = "DATE_GREATER_EQUAL";
export const DATE_LESS = "DATE_LESS";
export const DATE_LESS_EQUAL = "DATE_LESS_EQUAL";
export const DATE_EQUAL = "DATE_EQUAL";
export const DATE_GREATER = "DATE_GREATER";
export const DATE_NOT_EQUAL = "DATE_NOT_EQUAL";
