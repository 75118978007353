import React, { useEffect } from "react";
import { CONTAINS, EQUAL } from "../../utils/FiltersOperators";
import { COMBO, TEXTFIELD } from "../../utils/ComponentList";
import i18next from "../../localization/i18n";
import FiltersAndGrid from "../../components/template/FiltersAndGrid";
import AddIcon from "@mui/icons-material/Add";
import SaitRowActionDelete from "../../components/atomic/Columns/SaitRowActionDelete";
import SaitRowActionEdit from "../../components/atomic/Columns/SaitRowActionEdit";
import NuovoTemplate from "./NuovoTemplate/NuovoTemplate";
import { axiosFetchAllCombo, standardAxiosPost } from "../../utils/AxiosUtils";
import EditTemplate from "./EditTemplate/EditTemplate";
import { activateLoading, deactivateLoading } from "../../store/features/globalLoadingReducer";
import axiosInstance from "../../AxiosConfig";
import { useDispatch, useSelector } from "react-redux";

export default function TemplateNormative() {
	/*************
	 *	DICHIARAZIONI   *
	 **************/
	const dispatch = useDispatch();
	const token = useSelector((state) => state["auth"]["token"]);
	const module = useSelector((state) => state["module"]["module"]);
	/*************
	 *	MODEL   *
	 **************/

	/*************
	 *	  STATI    *
	 **************/

	const [itemsIwswdnormaMaker, setItemsIwswdnormaMaker] = React.useState([]);
	const [openNewTemplate, setOpenNewTemplate] = React.useState(false);
	const [selectedTemplate, setSelectedTemplate] = React.useState({});
	const [reloadGrid, setReloadGrid] = React.useState(false);

	/*************
	 *	LISTENER   *
	 **************/

	useEffect(() => {
		const comboQueries = [
			{
				findFunction: "/norma/findIwswdnormaMaker",
				orderBy: [{ property: "descNormativa", direction: "ASC" }],
				findFilters: [],
				setItems: setItemsIwswdnormaMaker,
			},
		];
		axiosFetchAllCombo(comboQueries);
	}, []);

	/*************
	 *	  GRID    *
	 **************/

	const gridParams = {
		findFunction: "/norma/findIwswdnormaTemplate",
		orderBy: [
			{
				property: "idTemplate",
				direction: "ASC",
			},
		],
		columns: [
			{
				headerName: i18next.t("idTemplate"),
				field: "idTemplate",
				width: 250,
			},
			{
				headerName: i18next.t("template_description"),
				field: "descrizioneTemplate",
				width: 250,
			},
			{
				headerName: i18next.t("regulation_id"),
				field: "idTipoNorma",
				width: 250,
			},
			{
				headerName: i18next.t("regulation_description"),
				field: "descNormativa",
				flex: 1,
			},
			SaitRowActionEdit({
				id: "EditTemplate",
				onClick: (params) => {
					fetchData(params.row.idTemplate);
				},
			}),
			SaitRowActionDelete({
				id: "EliminaTemplate",
				description: (params) => {
					return (
						"template " +
						params.row.descrizioneTemplate +
						" (" +
						i18next.t("regulation") +
						": " +
						params.row.descNormativa +
						")"
					);
				},
				onConfirm: (params) => {
					deleteTemplate(params.row.idTemplate);
				},
			}),
		],
	};

	/*************
	 *	  FILTERS    *
	 **************/

	const filters = [
		{
			label: i18next.t("template_description"),
			name: "descrizioneTemplate",
			operator: CONTAINS,
			type: TEXTFIELD,
		},
		{
			label: i18next.t("regulation"),
			name: [{ storeName: "idTipoNorma", comboName: "idTipoNorma" }],
			type: COMBO,
			description: "$(descNormativa)",
			items: itemsIwswdnormaMaker,
			operator: EQUAL,
		},
	];

	/*************
	 *	  BUTTONS    *
	 **************/

	const topButtons = [
		{
			text: i18next.t("new"),
			icon: <AddIcon color="primary" />,
			variant: "text",
			function: () => {
				setOpenNewTemplate(true);
			},
		},
	];

	/*************
	 *	FUNCTIONS *
	 **************/

	const deleteTemplate = async (item) => {
		await standardAxiosPost({
			dispatch: dispatch,
			procedureName: "/norma/deleteIwstdnormaTemplate",
			parameters: { idTemplate: item },
			onSuccess: () => {
				setReloadGrid(true);
			},
		});
	};

	const fetchData = async (idTemplate) => {
		dispatch(activateLoading(module));
		const language = navigator.language.substring(0, 2);
		await axiosInstance
			.get("norma/getTemplateInfo?id_template=" + idTemplate + "&lingua=" + language, {
				headers: {
					Authorization: `Bearer ${token}`,
					"X-Authentication-Strategy": "oauth2",
				},
			})
			.then((response) => {
				if (response.status === 200) {
					setSelectedTemplate(response.data.data);
				}
				dispatch(deactivateLoading(module));
			})
			.catch((error) => {
				console.error(error);
				dispatch(deactivateLoading(module));
			});
	};

	/*************
	 *	HTML   *
	 **************/

	return (
		<>
			<FiltersAndGrid
				filters={filters}
				gridParams={gridParams}
				topButtons={topButtons}
				resetFilter
				autoload
				controlledFilter={reloadGrid}
				setControlledFilter={setReloadGrid}
			/>
			<NuovoTemplate
				open={openNewTemplate}
				itemsIwswdnormaMaker={itemsIwswdnormaMaker}
				setControlledFilter={setReloadGrid}
				fetchData={fetchData}
				close={() => {
					setOpenNewTemplate(false);
				}}
			/>
			<EditTemplate
				open={selectedTemplate}
				template={selectedTemplate}
				setTemplate={setSelectedTemplate}
				close={() => {
					setSelectedTemplate({});
				}}
			/>
		</>
	);
}
