import React from "react";
import SaitTextField from "./SaitTextField";
import "../../App.css";

export default function SaitNumberField(props) {
	const handleInputChange = (event) => {
		const inputValue = event.target.value;
		let processedValue = inputValue;

		if (
			inputValue.length > 1 &&
			inputValue[0] === "0" &&
			inputValue[1] !== "," &&
			inputValue[1] !== "."
		) {
			processedValue = inputValue.slice(1);
		}

		// props.onChange(processedValue);
		event.target.value = processedValue;
		props.onChange(event);
	};

	const handleKeyDown = (e) => {
		if (e.key === "ArrowUp" || e.key === "ArrowDown") {
			e.preventDefault(); // Previene l'azione predefinita delle frecce su e giù
		}
	};

	return (
		<SaitTextField
			{...props}
			type="number"
			onChange={handleInputChange}
			onWheel={(e) => e.target.blur()}
			onKeyDown={handleKeyDown}
		/>
	);
}
