import i18next from "i18next";
import { activateLoading, deactivateLoading } from "../store/features/globalLoadingReducer";
import { setGlobalError } from "../store/features/globalMessageReducer";
import ExcelJS from "exceljs";
import { downloadFile, getBlobXlsxFromBuffer } from "./FileUtils";

export async function excelExport(dispatch, nomeFile, params) {
	const module = localStorage.getItem("1trueIdModule");

	dispatch(activateLoading(module));

	if (params.rowCount < 10000) {
		const result = await params.fetchDataForExcel(10000, 0);
		if (result?.rows?.length > 0) {
			const workbook = new ExcelJS.Workbook();
			const worksheet = workbook.addWorksheet("Dati"); // 'Dati' è il nome del foglio Excel

			const workSheetColumns = params.columns.filter(
				(colonna) =>
					colonna.headerName &&
					(params.columnsVisibility[colonna.field] ||
						!(colonna.field in params.columnsVisibility))
			); // Filtra le colonne con headerName non nullo e quelle attualmente visibili

			worksheet.columns = workSheetColumns.map((colonna) => ({
				header: colonna.headerName,
				key: colonna.field,
				width: colonna.width ? colonna.width / 6.5 : 30, // Usa la width della colonna diviso 6.5 per impostare la larghezza. Per le colonne con il flex metto 30
			}));

			var row = workSheetColumns.map((colonna) => ({
				[colonna.field]: "",
			}));
			worksheet.addRow(row);

			result.rows.forEach((riga, index) => {
				workSheetColumns.forEach((colonna) => {
					const cell = worksheet.getRow(index + 2).getCell(colonna.field); //righe del worksheet iniziano da 1, faccio +2 per escludere l'header
					renderCellValue(riga, colonna, cell);
				});
			});

			const headerRow = worksheet.getRow(1); //Imposta stile alla prima riga (intestazione con nome colonne)
			headerRow.font = {
				size: 12,
				bold: true,
			};
			headerRow.commit();

			// Genera il file Excel
			const buffer = await workbook.xlsx.writeBuffer();
			downloadFile(getBlobXlsxFromBuffer(buffer), nomeFile + ".xlsx");
		}
	} else {
		dispatch(
			setGlobalError({
				module: module,
				errorMessage: i18next.t("error_max_lines_too_export_is_10000"),
			})
		);
	}

	dispatch(deactivateLoading(module));
}

export async function exportTaskPivot(dispatch, nomeFile, params) {
	const module = localStorage.getItem("1trueIdModule");

	dispatch(activateLoading(module));

	if (params.rowCount < 10000) {
		const result = await params.fetchDataForExcel(10000, 0);
		if (result?.rows?.length > 0) {
			const workbook = new ExcelJS.Workbook();
			const worksheet = workbook.addWorksheet("Dati"); // 'Dati' è il nome del foglio Excel

			const setTipiTask = new Set();
			const tipiTask = result.rows //estrae i tipi task dai risultati
				.filter((elemento) => {
					// Controlla se il tipoTask è già stato aggiunto al set
					if (!setTipiTask.has(elemento.tipoTask)) {
						// Se non è stato aggiunto, aggiungi al set e restituisci true per mantenere l'elemento
						setTipiTask.add(elemento.tipoTask);
						return true;
					}
					// Se il tipoTask è già stato aggiunto, restituisci false per filtrare l'elemento duplicato
					return false;
				})
				.map((task) => ({
					tipoTask: task.tipoTask,
					tipoDescrizione: task.tipoDescrizione,
				}));

			// Filtra le colonne con headerName non nullo e quelle attualmente visibili
			const visibleColumns = params.columns.filter(
				(colonna) =>
					colonna.headerName &&
					(params.columnsVisibility[colonna.field] ||
						!(colonna.field in params.columnsVisibility)) &&
					![
						"tipoTask",
						"tipoDescrizione",
						"stato",
						"statoDescrizione",
						"personeAssegnate",
						"NoteAssegnazione",
						"NoteEsecuzione",
						"esitoPositivo",
					].includes(colonna.field)
			);

			const workSheetColumns = [
				...visibleColumns.map((colonna) => ({
					header: colonna.headerName,
					key: colonna.field,
					width: colonna.width ? colonna.width / 6.5 : 30, // Usa la width della colonna diviso 6.5 per impostare la larghezza. Per le colonne con il flex metto 30
				})),
			];
			tipiTask.forEach((task) => {
				workSheetColumns.push({
					header: task.tipoDescrizione + " stato",
					key: task.tipoTask + "stato",
					width: (task.tipoDescrizione + " stato").length * 1.25,
				});
				workSheetColumns.push({
					header: task.tipoDescrizione + " note",
					key: task.tipoTask + "note",
					width: (task.tipoDescrizione + " note").length * 1.25,
				});
			});

			worksheet.columns = workSheetColumns;

			const resultObject = {};
			result.rows.forEach((record) => {
				const {
					idContratto,
					tipoTask,
					stato,
					statoDescrizione,
					noteAssegnazione,
					noteChiusura,
					esitoPositivo,
				} = record;

				// Verifica se l'idContratto è già presente nell'oggetto risultato
				if (!resultObject[idContratto]) {
					resultObject[idContratto] = { idContratto };
				}

				resultObject[idContratto] = { ...resultObject[idContratto], ...record };

				resultObject[idContratto][tipoTask.toLowerCase()] = {
					stato,
					statoDescrizione,
					noteAssegnazione,
					noteChiusura,
					esitoPositivo,
				};
			});
			const pivotResult = Object.values(resultObject);

			var row = workSheetColumns.map((colonna) => ({
				[colonna.key]: "",
			}));
			worksheet.addRow(row);

			pivotResult.forEach((record, index) => {
				const row = worksheet.getRow(index + 2); //righe del worksheet iniziano da 1, faccio +2 per escludere l'header
				visibleColumns.forEach((colonna) => {
					const cell = row.getCell(colonna.field);
					renderCellValue(record, colonna, cell);
				});
				tipiTask.forEach((task) => {
					const keyTask = task.tipoTask.toLowerCase();
					const cellStato = row.getCell(task.tipoTask + "stato");
					cellStato.value = record[keyTask]?.statoDescrizione;

					let backgroundColor;
					switch (record[keyTask]?.stato) {
						case 5:
							backgroundColor = "ebfbff"; //azzurro
							break;
						case 10:
							backgroundColor = "fff9ba"; //giallo
							break;
						case 80:
							if (record[keyTask]?.esitoPositivo === "Y") {
								backgroundColor = "ccffcc"; //verde
							} else {
								backgroundColor = "ffcccc"; //rosso
							}
							break;
						case 99:
							backgroundColor = "f77272"; //rosso
							break;
						default:
							backgroundColor = "ffffff"; //bianco
					}

					if (record[keyTask]?.stato) {
						cellStato.fill = {
							type: "pattern",
							pattern: "solid",
							fgColor: {
								argb: backgroundColor,
							},
						};
					}

					const cellNote = row.getCell(task.tipoTask + "note");
					const noteAssegn = record[keyTask]?.noteAssegnazione
						? `Assegnazione: ${record[keyTask]?.noteAssegnazione}`
						: "";
					const noteEsec = record[keyTask]?.noteChiusura
						? `Esecuzione: ${record[keyTask]?.noteChiusura}`
						: "";
					cellNote.value = noteAssegn + (noteAssegn && noteEsec ? " - " : "") + noteEsec;
				});
			});

			const headerRow = worksheet.getRow(1); //Imposta stile alla prima riga (intestazione con nome colonne)
			headerRow.font = {
				size: 12,
				bold: true,
			};
			headerRow.commit();

			// Genera il file Excel
			const buffer = await workbook.xlsx.writeBuffer();
			downloadFile(getBlobXlsxFromBuffer(buffer), nomeFile + ".xlsx");
		}
	} else {
		dispatch(
			setGlobalError({
				module: module,
				errorMessage: i18next.t("error_max_lines_too_export_is_10000"),
			})
		);
	}

	dispatch(deactivateLoading(module));
}

async function renderCellValue(record, colonna, cell) {
	if (colonna.renderCell) {
		const renderedElement = colonna.renderCell({
			row: record,
			value: record[colonna.field],
		});

		if (renderedElement?.type === "div") {
			//div HTML
			cell.value = renderedElement.props?.children;
			if (renderedElement.props.style.backgroundColor) {
				cell.fill = {
					type: "pattern",
					pattern: "solid",
					fgColor: {
						argb: renderedElement.props.style.backgroundColor.replace("#", ""),
					},
				};
			}
		} else if (renderedElement?.props?.children?.type?.type?.render?.muiName === "SvgIcon") {
			//icona MUI
			const iconType = renderedElement.props.children.type.type.render.displayName;
			switch (iconType) {
				case "CheckIcon":
					cell.value = "V";
					cell.font = {
						color: { argb: "08A308" }, //verde
						bold: true,
						italic: true,
					};
					cell.alignment = { vertical: "middle", horizontal: "center" };
					break;
				case "CloseIcon":
					cell.value = "X";
					cell.font = {
						color: { argb: "FF0000" },
						bold: true,
					};
					cell.alignment = { vertical: "middle", horizontal: "center" };
					break;
				default:
					cell.value = " ";
					break;
			}
		} else if (colonna?.columnType === "notes") {
			//SaitRowActionNote
			cell.value = renderedElement?.props?.note;
		} else cell.value = renderedElement;
	} else if (colonna.valueFormatter) {
		cell.value = colonna.valueFormatter({
			value: record[colonna.field],
		});
	} else {
		cell.value = record[colonna.field];
	}
}
