export default class AsyncFileReader {
	constructor(files) {
		this.__files = files;
		return this;
	}

	readFile() {
		let readerFiles = this.__files;

		return {
			async asDataURL() {
				return new Promise((resolve, reject) => {
					let reader = new FileReader();
					let count = 0;
					let __files = [];

					reader.onload = () => {
						__files.push(reader.result);
						count++;

						if (count >= readerFiles.length) {
							console.log("FILES", __files);

							resolve(__files);
						} else {
							reader.readAsDataURL(readerFiles[count]);
						}
					};

					reader.onerror = reject;
					reader.readAsDataURL(readerFiles[count]);
				});
			},

			async asArrayBuffer() {
				return new Promise((resolve, reject) => {
					let reader = new FileReader();
					let count = 0;
					let __files = [];

					reader.onload = () => {
						__files.push({ filename: readerFiles[count].name, content: reader.result });
						count++;

						if (count >= readerFiles.length) {
							resolve(__files);
						} else {
							reader.readAsArrayBuffer(readerFiles[count]);
						}
					};

					reader.onerror = reject;
					reader.readAsArrayBuffer(readerFiles[count]);
				});
			},
		};
	}
}
