import { formatDate, formatDateTime } from "../../../utils/SaitUtils";

/*
	Obbligatori:
		headerName
		field
	Facoltativi:
		width
*/

export default function SaitDateColumn(config) {
	return {
		valueFormatter: (params) => (config.time ? formatDateTime(params) : formatDate(params)),
		headerName: config.headerName,
		width: config.width || (config.time ? 120 : 90),
		field: config.field,
		hidden: config.hidden || false,
		type: config.type || (config.time ? "dateTime" : "date"),
	};
}
