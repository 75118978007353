import React from "react";
import { Box, Switch, Typography } from "@mui/material";

export default function SaitSwitch(props) {
	const handleChange = (event) => {
		props.onChange(event.target.checked);
	};

	return (
		<Box
			sx={{
				...props.sx,
				display: "inline-flex",
				alignItems: "center",
				justifyContent: "center",
			}}
		>
			<Typography>{props.startLabel}</Typography>
			<Switch checked={props.value} onChange={handleChange} />
			<Typography>{props.endLabel}</Typography>
		</Box>
	);
}
