import React, { Fragment, useEffect, useRef } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import dayjs from "dayjs";
import "dayjs/locale/it";
import {
	COMBO,
	DATEFIELD,
	SEARCHWINDOW,
	SWITCH,
	TEXTAREA,
	TEXTFIELD,
	GRID,
	BUTTON,
	CHECKBOX,
	NUMBERFIELD,
	DIVIDER,
	DYNAMICCOMBO,
	TAB,
	PARAGRAPH,
	BUTTON_UPLOAD,
	IMAGE_BOX,
} from "../../utils/ComponentList";
import SaitCombo from "../atomic/SaitCombo";
import SaitSwitch from "../atomic/SaitSwitch";
import SaitDateField from "../atomic/SaitDateField";
import SaitTextField from "../atomic/SaitTextField";
import SaitSearchTextField from "../atomic/SaitSearchTextField";
import FiltersAndGrid from "./FiltersAndGrid";
import SaitCheckbox from "../atomic/SaitCheckbox";
import SaitButton from "../atomic/SaitButton";
import SaitNumberField from "../atomic/SaitNumberField";
import { Divider, Tab, Tabs } from "@mui/material";
import SaitDynamicCombo from "../atomic/SaitDynamicCombo";
import SaitButtonUpload from "../atomic/SaitButtonUpload";

export default function EditPanel(props) {
	const defaultWidth = 150;
	const defaultDateWidth = 140;
	const defaultColumnWidth = 500;
	const defaultSize = "small";

	const onChangeItem = (name, value) => {
		props.setFunction((prevState) => {
			return {
				...prevState,
				[name]: value,
			};
		});
	};

	const onChangeComboItem = (selectedRow, name, afterChange) => {
		if (!selectedRow) {
			selectedRow = {};
		}

		let newStateElement = { ...props.stateElement };
		name.forEach((item) => {
			newStateElement[item.storeName] = selectedRow[item.comboName];
		});

		props.setFunction(newStateElement);
		if (afterChange) {
			afterChange(selectedRow);
		}
	};

	const onChangeSearchItem = (selectedRow, name) => {
		if (!selectedRow) {
			selectedRow = {};
		}

		let newStateElement = { ...props.stateElement };
		name.forEach((item) => {
			newStateElement[item.storeName] = selectedRow[item.gridName];
		});

		props.setFunction(newStateElement);
	};

	const onChangeDateitem = (name, value) => {
		let formattedValue = null;
		if (value) {
			formattedValue = dayjs(value);
		}
		props.setFunction((prevState) => ({
			...prevState,
			[name]: formattedValue,
		}));
	};

	const onChangeDateitemNoDay = (name, value, last) => {
		let formattedValue = null;
		if (value) {
			if (last) {
				//se passata props.lastDayOfMonth: inserisce l'ultimo giorno del mese
				const month = dayjs(value).get("month");
				formattedValue = dayjs(value)
					.set("date", 1)
					.set("month", month + 1)
					.subtract(1, "day");
			} else {
				//default: inserisce il primo del mese
				formattedValue = dayjs(value).set("date", 1);
			}
		}
		props.setFunction((prevState) => ({
			...prevState,
			[name]: formattedValue,
		}));
	};

	const checkRequiredForError = (attemptSave, require, value) => {
		return attemptSave && require && !value;
	};

	const componentChoice = (paragraph, item, index) => {
		switch (item.type) {
			case TEXTFIELD:
				return (
					<TextFieldEditPanel
						{...item}
						key={index}
						label={item.label}
						error={
							item.error
								? item.error
								: checkRequiredForError(
										props.attemptSave,
										item.required,
										props.stateElement[item.name]
								  )
						}
						value={
							item.value || item.value === 0
								? item.value
								: props.stateElement[item.name] || ""
						}
						sx={{
							flex:
								item.width || paragraph.flexDirection === "column"
									? null
									: "1 0 " + defaultWidth + "px",
							width: item.width ? item.width : "100%",
							background: item.backgroundColor,
						}}
						disabled={item.disabled}
						size={item.size || defaultSize}
						onChange={(e) => {
							onChangeItem(item.name, e.target.value);
						}}
						onKeyDown={(e) => {
							if (e.key === "Enter") {
								e.preventDefault();
								item?.onEnter?.();
							}
						}}
						required={item.required}
					/>
				);
			case TEXTAREA:
				return (
					<TextFieldEditPanel
						{...item}
						key={index}
						label={item.label}
						error={
							item.error
								? item.error
								: checkRequiredForError(
										props.attemptSave,
										item.required,
										props.stateElement[item.name]
								  )
						}
						value={
							item.value || item.value === 0
								? item.value
								: props.stateElement[item.name] || ""
						}
						sx={{
							flex:
								item.width || paragraph.flexDirection === "column"
									? null
									: "1 0 " + defaultWidth + "px",
							width: item.width ? item.width : "100%",
							background: item.backgroundColor,
						}}
						disabled={item.disabled}
						size={item.size || defaultSize}
						onChange={(e) => onChangeItem(item.name, e.target.value)}
						multiline
						minRows={item.minRows}
						required={item.required}
					/>
				);
			case NUMBERFIELD:
				return (
					<NumberFieldEditPanel
						{...item}
						key={index}
						label={item.label}
						error={
							item.error
								? item.error
								: checkRequiredForError(
										props.attemptSave,
										item.required,
										props.stateElement[item.name]
								  )
						}
						value={
							item.value || item.value === 0
								? item.value
								: props.stateElement[item.name] || 0
						}
						sx={{
							flex:
								item.width || paragraph.flexDirection === "column"
									? null
									: "1 0 " + defaultWidth + "px",
							width: item.width ? item.width : "100%",
							background: item.backgroundColor,
						}}
						disabled={item.disabled}
						size={item.size || defaultSize}
						onChange={(e) => onChangeItem(item.name, e.target.value)}
						required={item.required}
					/>
				);
			case DATEFIELD:
				return (
					<SaitDateField
						{...item}
						key={index}
						label={item.label}
						error={
							item.error
								? item.error
								: checkRequiredForError(
										props.attemptSave,
										item.required,
										props.stateElement[item.name]
								  )
						}
						value={props.stateElement[item.name]}
						sx={{
							flex: null,
							width: item.width ? item.width : defaultDateWidth,
						}}
						disabled={item.disabled}
						size={item.size || defaultSize}
						onChange={
							item.noDay
								? (newDate) =>
										onChangeDateitemNoDay(
											item.name,
											newDate,
											item.lastDayOfMonth
										)
								: (newDate) => onChangeDateitem(item.name, newDate)
						}
						required={item.required}
					/>
				);
			case SEARCHWINDOW:
				return (
					<SaitSearchTextField
						{...item}
						key={index}
						label={item.label}
						value={props.stateElement}
						error={
							item.error
								? item.error
								: checkRequiredForError(
										props.attemptSave,
										item.required,
										props.stateElement[item.name[0].storeName]
								  )
						}
						name={item.name}
						sx={{
							flex:
								item.width || paragraph.flexDirection === "column"
									? null
									: "1 0 " + defaultWidth + "px",
							width: item.width ? item.width : "100%",
						}}
						onChange={(row) => onChangeSearchItem(row, item.name)}
						searchWindow={item.searchWindow}
						disabled={item.disabled}
						size={item.size || defaultSize}
						description={item.description}
						required={item.required}
					/>
				);
			case COMBO:
				return (
					<SaitCombo
						{...item}
						key={index}
						label={item.label}
						value={props.stateElement}
						error={
							item.error
								? item.error
								: checkRequiredForError(
										props.attemptSave,
										item.required,
										props.stateElement[item.name[0].storeName]
								  )
						}
						name={item.name}
						onChange={(row) => onChangeComboItem(row, item.name, item.afterChange)}
						sx={{
							flex:
								item.width || paragraph.flexDirection === "column"
									? null
									: "1 0 " + defaultWidth + "px",
							width: item.width ? item.width : "100%",
						}}
						disabled={item.disabled}
						size={item.size}
						description={item.description}
						findParameters={item.findParameters}
						items={item.items}
						required={item.required}
					/>
				);
			case DYNAMICCOMBO:
				return (
					<SaitDynamicCombo
						{...item}
						key={index}
						index={index}
						stateElement={props.stateElement}
						setFunction={props.setFunction}
						sx={{
							flex:
								item.width || paragraph.flexDirection === "column"
									? null
									: "1 0 " + defaultWidth + "px",
							width: item.width ? item.width : "100%",
						}}
						error={checkRequiredForError(
							props.attemptSave,
							item.required,
							props.stateElement[item.name[0].storeName]
						)}
					/>
				);
			case SWITCH:
				return (
					<SaitSwitch
						{...item}
						key={index}
						value={props.stateElement[item.name]}
						startLabel={item.startLabel}
						endLabel={item.endLabel}
						disabled={item.disabled}
						onChange={(checked) => {
							onChangeItem(item.name, checked);
							item.afterChange?.(checked);
						}}
					/>
				);
			case CHECKBOX:
				return (
					<SaitCheckbox
						{...item}
						key={index}
						label={item.label}
						value={props.stateElement[item.name]}
						disabled={item.disabled}
						onChange={(checked) => {
							onChangeItem(item.name, checked);
							item.afterChange?.(checked);
						}}
					/>
				);
			case GRID:
				return (
					<Fragment key={index}>
						<FiltersAndGrid {...item} />
					</Fragment>
				);
			case DIVIDER:
				return paragraph.flexDirection === "column" ? (
					<Divider key={index} flexItem />
				) : (
					<Divider key={index} orientation="vertical" flexItem />
				);
			case BUTTON:
				return (
					<SaitButton
						key={index}
						{...item}
						text={item.label}
						icon={item.icon}
						disabled={item.disabled}
					/>
				);
			case BUTTON_UPLOAD:
				return (
					<SaitButtonUpload
						key={index}
						sx={{ ml: "5px" }}
						text={item.text}
						onUpload={item.onUpload}
						endIcon={item.icon}
						multiple={item.multiple}
						accept={item.accept}
						variant={item.variant || "text"}
						disabled={item.disabled}
					/>
				);
			case IMAGE_BOX:
				return (
					<Box
						key={index}
						mt={2}
						display="flex"
						justifyContent="center"
						alignItems="center"
						height={450}
						width={450}
						bgcolor="#f0f0f0" // Colore di sfondo del box
						borderRadius={8} // Bordo arrotondato del box
						boxShadow={3} // Ombra del box
						p={2} // Padding interno del box
					>
						{item.imageUrl && (
							<img
								src={item.imageUrl}
								alt={item.alt}
								style={{
									height: "100%", // Altezza dell'immagine al 100% del contenitore
									maxHeight: "100%", // Altezza massima dell'immagine al 100% del contenitore
									width: "auto", // Larghezza automatica per mantenere le proporzioni
									maxWidth: "100%", // Larghezza massima dell'immagine al 100% del contenitore
									display: "block",
								}}
							/>
						)}
					</Box>
				);
			default:
				return null;
		}
	};

	const returnParagraph = (paragraph, index) => {
		return (
			<Box
				key={index}
				sx={{
					alignItems: paragraph.flexDirection === "column" ? "center" : null,
					display: "flex",
					flexDirection: "column",
				}}
			>
				<Card
					key={index}
					sx={{
						m: 1,
						mb: 2,
						width:
							paragraph.flexDirection === "column"
								? paragraph.width || defaultColumnWidth
								: paragraph.width || null,
					}}
					elevation={5}
				>
					<CardContent>
						<Typography variant="h5">{paragraph.title}</Typography>
						<Box
							component="form"
							sx={{
								"& > :not(style)": { m: 1 },
								display: "flex",
								flexWrap: "wrap",
								gap: "10px",
								flexDirection:
									paragraph.flexDirection === "column" ? "column" : "row",
								mt: "10px",
								mr: paragraph.flexDirection === "column" ? "30px" : "0px",
							}}
							noValidate
							autoComplete="off"
							alignItems="center"
						>
							{paragraph.items.map(
								(item, index) =>
									!item.hidden && componentChoice(paragraph, item, index)
							)}
						</Box>
					</CardContent>
				</Card>
			</Box>
		);
	};

	const [choosenTab, setChoosenTab] = React.useState(0);
	const handleChangeTab = (event, newValue) => {
		setChoosenTab(newValue);
	};

	return (
		<Box sx={{ display: "block" }}>
			{/* Inserisco i Titoli delle Tabs, se ce n'è almeno una */}
			{props.fields.some((item) => item.type === TAB) && (
				<Box sx={{ borderBottom: 1, borderColor: "divider" }}>
					<Tabs value={choosenTab} onChange={handleChangeTab}>
						{props.fields.map(
							(element, index) =>
								!element.hidden &&
								element.type === TAB && <Tab key={index} label={element.title} />
						)}
					</Tabs>
				</Box>
			)}
			<Box>
				{props.fields.map(
					(element, index) =>
						!element.hidden &&
						(element.type === TAB ? (
							<Fragment key={index}>
								{index === choosenTab &&
									element.items.map(
										(childElement, indexChildElement) =>
											!childElement.hidden &&
											// Se è un paragrafo
											(childElement.type === PARAGRAPH || !childElement.type
												? returnParagraph(childElement, indexChildElement)
												: // Se è un componente

												  componentChoice(
														element,
														childElement,
														indexChildElement
												  ))
									)}
							</Fragment>
						) : (
							returnParagraph(element, index)
						))
				)}
			</Box>
		</Box>
	);
}

function TextFieldEditPanel(props) {
	const { value, onChange, onEnter, ...textfieldProps } = props;

	const textFieldRef = useRef("");

	const debounceTime = 200;
	let debounceTimer;

	const handleChangeValue = (e) => {
		if (textFieldRef.current) {
			textFieldRef.current["value"] = e.target.value;
		}

		// Cancella il timer se l'utente continua a scrivere entro il debounceTime
		clearTimeout(debounceTimer);

		// Imposta un nuovo timer per chiamare l'aggiornamento dello stato dopo il debounceTime
		debounceTimer = setTimeout(() => {
			onChange(e);
		}, debounceTime);
	};

	useEffect(() => {
		if (textFieldRef.current) {
			if (textFieldRef.current["value"] !== props.value) {
				textFieldRef.current["value"] = props.value;
			}
		}
	}, [props.value]);

	// Cambiando il disabled cambia la variant, e si perde lo useRef
	useEffect(() => {
		textFieldRef.current["value"] = props.value;
		// eslint-disable-next-line
	}, [props.disabled]);

	return (
		<SaitTextField
			{...textfieldProps}
			inputRef={textFieldRef}
			onChange={(e) => {
				handleChangeValue(e);
			}}
			type={textfieldProps.tipo}
			InputLabelProps={textFieldRef.current["value"] || props.value ? { shrink: true } : {}}
		/>
	);
}

function NumberFieldEditPanel(props) {
	const { value, onChange, ...textfieldProps } = props;

	const textFieldRef = useRef("");

	const debounceTime = 200;
	let debounceTimer;

	const handleChangeValue = (e) => {
		if (textFieldRef.current) {
			textFieldRef.current["value"] = e.target.value;
		}

		// Cancella il timer se l'utente continua a scrivere entro il debounceTime
		clearTimeout(debounceTimer);

		// Imposta un nuovo timer per chiamare l'aggiornamento dello stato dopo il debounceTime
		debounceTimer = setTimeout(() => {
			onChange(e);
		}, debounceTime);
	};

	useEffect(() => {
		if (textFieldRef.current) {
			if (textFieldRef.current["value"] !== props.value) {
				textFieldRef.current["value"] = props.value;
			}
		}
	}, [props.value]);

	// Cambiando il disabled cambia la variant, e si perde lo useRef
	useEffect(() => {
		textFieldRef.current["value"] = props.value;
		// eslint-disable-next-line
	}, [props.disabled]);

	return (
		<SaitNumberField
			{...textfieldProps}
			inputRef={textFieldRef}
			onChange={(e) => {
				handleChangeValue(e);
			}}
		/>
	);
}
