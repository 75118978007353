import React, { useEffect } from "react";
import SaitButton from "../atomic/SaitButton";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckIcon from "@mui/icons-material/Check";
import "dayjs/locale/it";
import SaitDialog from "../atomic/SaitDialog";
import i18next from "../../localization/i18n";
import FiltersAndGrid from "./FiltersAndGrid";
import { Box } from "@mui/material";

export default function SearchWindow(props) {
	const [gridParamsSelection, setGridParamsSelection] = React.useState({});
	const [selectedRow, setSelectedRow] = React.useState();

	useEffect(() => {
		let newGridParams = { ...props.gridParams };
		newGridParams.selection = {
			selectionFunction: (selRow) => {
				setSelectedRow(selRow);
			},
		};
		setGridParamsSelection(newGridParams);
	}, [props.gridParams]);

	const handleClose = () => {
		props.close();
	};

	const handleOnSelection = () => {
		if (selectedRow) {
			props.onSelection?.(selectedRow);
			props.close();
			setSelectedRow(null);
		}
	};

	return (
		<SaitDialog
			open={props.open}
			title={props.title}
			width="lg"
			minHeight={props.minHeight}
			displayFlex={true}
			flexDirection="row"
			content={
				<FiltersAndGrid
					filters={props.filters}
					autoload={props.autoload}
					gridParams={gridParamsSelection}
					handleRowDoubleClick={handleOnSelection}
					inLineButtonFilter
					combinedRequiredFilters={props.combinedRequiredFilters}
				/>
			}
			actions={
				<Box
					sx={{
						flex: 1,
						display: "flex",
						flexDirection: "row",
						justifyContent: "space-between",
					}}
				>
					<SaitButton
						text={i18next.t("close")}
						color="error"
						endIcon={<CancelIcon />}
						onClick={handleClose}
					/>
					<SaitButton
						text={i18next.t("select")}
						endIcon={<CheckIcon />}
						onClick={handleOnSelection}
						color={"success"}
					/>
				</Box>
			}
		/>
	);
}
