import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { setActiveTabId } from "../../store/features/generalConfigReducer";
import { setOpenTabs } from "../../store/features/generalConfigReducer";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import MainPage from "./MainPage";
import SaitAppBar from "./SaitAppBar";
import SaitDrawer from "./SaitDrawer";
import { defaultAppBarHeight, defaultOpenDrawerWidth } from "../../theme";

export default function IndexDrawer(props) {
	const drawerWidth = window.Configs.openDrawerWidth || defaultOpenDrawerWidth;

	const userPreferences = useSelector((state) => state["auth"]);
	const open = useSelector((state) => state["generalConfig"]["menuOpen"]);
	const dispatch = useDispatch();
	const activeTabId = useSelector((state) => state["generalConfig"]["activeTabId"]);
	const openTabs = useSelector((state) => state["generalConfig"]["openTabs"]);

	const setMenuActiveTabId = (newId) => {
		dispatch(setActiveTabId(newId));
	};

	const setMenuOpenTabs = (newOpenTabs) => {
		dispatch(setOpenTabs(newOpenTabs));
	};

	const handleOpenTab = (newTab) => {
		if (activeTabId !== newTab.id) {
			setMenuActiveTabId(newTab.id);
			if (!openTabs.find((openTab) => openTab.id === newTab.id)) {
				setMenuOpenTabs(newTab);
			}
		}
	};

	return (
		<Box sx={{ display: "flex" }}>
			<CssBaseline />
			<SaitAppBar
				open={open}
				drawerwidth={drawerWidth}
				openTabs={openTabs}
				activeTabId={activeTabId}
				setMenuActiveTabId={setMenuActiveTabId}
				userPreferences={userPreferences}
				handleOpenTab={handleOpenTab}
				theme={props.theme}
				colorMode={props.colorMode}
			/>
			<>
				<SaitDrawer
					open={open}
					drawerwidth={drawerWidth}
					userPreferences={userPreferences}
					handleOpenTab={handleOpenTab}
				/>
				<Box
					component="main"
					sx={{
						display: "flex",
						flexDirection: "column",
						flexGrow: 1,
					}}
				>
					<main
						className="content"
						style={{
							flexGrow: 1,
							display: "flex",
							flexDirection: "column",
							height: `calc(100vh - ${
								window.Configs.appBarHeight || defaultAppBarHeight
							}px`,
							marginTop: `${window.Configs.appBarHeight || defaultAppBarHeight}px`,
							paddingLeft: open ? drawerWidth + "px" : "0px",
						}}
					>
						<MainPage theme={props.theme.palette.mode} />
					</main>
				</Box>
			</>
		</Box>
	);
}
