import React from "react";
import i18next from "i18next";
import { useDispatch } from "react-redux";
import { COMBO, TEXTFIELD } from "../../../utils/ComponentList";
import EditWindow from "../../../components/template/EditWindow";
import { standardAxiosPost } from "../../../utils/AxiosUtils";

export default function NuovoTemplate(props) {
	const templateBase = {
		idNormaMaker: "",
		descrizioneTemplate: "",
	};

	const dispatch = useDispatch();
	const [template, setTemplate] = React.useState(templateBase);
	const onClose = () => {
		setTemplate(templateBase);
		props.close();
	};

	const onSave = async () => {
		await standardAxiosPost({
			dispatch: dispatch,
			procedureName: "/norma/insertNewTemplate",
			parameters: template,
			onSuccess: (response) => {
				props.setControlledFilter(true);
				props.fetchData(response.idTemplate);
				onClose();
			},
		});
	};

	const fields = [
		{
			title: i18next.t("parameters"),
			items: [
				{
					label: i18next.t("regulation"),
					name: [{ storeName: "idNormaMaker", comboName: "idNormaMaker" }],
					type: COMBO,
					description: "$(descNormativa)",
					items: props.itemsIwswdnormaMaker,
					required: true,
				},
				{
					label: i18next.t("template_description"),
					name: "descrizioneTemplate",
					type: TEXTFIELD,
					required: true,
				},
			],
		},
	];
	return (
		<>
			<EditWindow
				title={i18next.t("new_template")}
				open={props.open}
				width="sm"
				close={() => onClose()}
				fields={fields}
				stateElement={template}
				setFunction={setTemplate}
				onSave={onSave}
			/>
		</>
	);
}
