import React from "react";
import i18next from "../../../localization/i18n";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import SaitRowAction from "./SaitRowAction";
import { setGlobalConfirm } from "../../../store/features/globalConfirmReducer";
import { useDispatch, useSelector } from "react-redux";

/*
	Obbligatori:
		id
		description
		onConfirm
	Facoltativi:
		onCancel
*/

export default function SaitRowActionDelete(config) {
	const dispatch = useDispatch();
	const module = useSelector((state) => state["module"]["module"]);

	return SaitRowAction({
		id: config.id,
		icon: <RemoveCircleIcon color="error" />,
		tooltip: config.annullato ? i18next.t("cancel") : i18next.t("delete"),
		onClick: (params) => {
			const finalDesc = config.description(params);
			const constConfirmMessage = config.annullato ? "cancel_row_desc" : "delete_row_desc";

			dispatch(
				setGlobalConfirm({
					module: module,
					confirmMessage: i18next.t(constConfirmMessage, {
						desc: finalDesc,
					}),
					onConfirm: function () {
						config.onConfirm?.(params);
					},
					onCancel: function () {
						config.onCancel?.(params);
					},
				})
			);
		},
		conditionalRendering: config.conditionalRendering,
	});
}
