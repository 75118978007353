import React, { useEffect } from "react";
import i18next from "i18next";
import { useDispatch } from "react-redux";
import { COMBO, NUMBERFIELD, SEARCHWINDOW, TEXTFIELD } from "../../../utils/ComponentList";
import EditWindow from "../../../components/template/EditWindow";
import { axiosFetchAllCombo, standardAxiosPost } from "../../../utils/AxiosUtils";
import SearchWindow from "../../../components/template/SearchWindow";
import { CONTAINS } from "../../../utils/FiltersOperators";

export default function NuovaAssociazione(props) {
	useEffect(() => {
		if (props.open) {
			const comboQueries = [
				{
					findFunction: "/norma/findIwswdnormaTemplateForCombo",
					orderBy: [{ property: "descrizioneTemplate", direction: "ASC" }],
					findFilters: [],
					setItems: setItemsIwswdnormaTemplate,
				},
			];
			axiosFetchAllCombo(comboQueries);
		}
	}, [props.open]);

	const associazioneBase = {
		idTemplate: "",
		idProfile: "",
		descrizioneProdotto: "",
		seqTemplate: "1",
	};

	const dispatch = useDispatch();
	const [assignment, setAssignment] = React.useState(associazioneBase);
	const [itemsIwswdnormaTemplate, setItemsIwswdnormaTemplate] = React.useState([]);
	const onClose = () => {
		setAssignment(associazioneBase);
		props.close();
	};

	const onSave = async () => {
		await standardAxiosPost({
			dispatch: dispatch,
			procedureName: "/norma/associaTemplateProfile",
			parameters: assignment,
			onSuccess: async (response) => {
				props.setControlledFilter(true);
				props.setSelectedProfile({ idProfile: assignment.idProfile });
				await props.fetchData(response.idTemplate);
				onClose();
			},
		});
	};

	const searchWindowGridParam = {
		findFunction: "/norma/findIwswotidObjectProfile",
		orderBy: [
			{
				property: "idProfile",
				direction: "ASC",
			},
		],
		columns: [
			{
				headerName: i18next.t("profile_id"),
				field: "idProfile",
				flex: 1,
			},
			{
				headerName: i18next.t("product"),
				field: "prodotto",
				flex: 1,
			},
			{
				headerName: i18next.t("product_description_short"),
				field: "descrizioneProdotto",
				flex: 1,
			},
			{
				headerName: i18next.t("item_code"),
				field: "articoloCod",
				flex: 1,
			},
			{
				headerName: i18next.t("production_lot"),
				field: "lottoProduzione",
				flex: 1,
			},
			{
				headerName: i18next.t("variant") + " " + 1,
				field: "keyTaglia",
				flex: 1,
			},
			{
				headerName: i18next.t("variant") + " " + 2,
				field: "keyColore",
				flex: 1,
			},
		],
	};

	const searchWindowFilters = [
		{
			label: i18next.t("profile_id"),
			operator: CONTAINS,
			name: "idProfile",
			type: TEXTFIELD,
		},
		{
			label: i18next.t("item_code"),
			operator: CONTAINS,
			name: "articoloCod",
			type: TEXTFIELD,
		},
		{
			label: i18next.t("production_lot"),
			operator: CONTAINS,
			name: "lottoProduzione",
			type: TEXTFIELD,
		},
		{
			label: i18next.t("variant") + " " + 1,
			operator: CONTAINS,
			name: "keyTaglia",
			type: TEXTFIELD,
		},
		{
			label: i18next.t("variant") + " " + 2,
			operator: CONTAINS,
			name: "keyColore",
			type: TEXTFIELD,
		},
	];

	const fields = [
		{
			title: i18next.t("parameters"),
			items: [
				{
					label: i18next.t("template"),
					name: [{ storeName: "idTemplate", comboName: "idTemplate" }],
					type: COMBO,
					description: "$(descNormativa) - $(descrizioneTemplate)",
					items: itemsIwswdnormaTemplate,
					required: true,
				},
				{
					label: i18next.t("profile_id"),
					flex: 1,
					name: [
						{ storeName: "idProfile", gridName: "idProfile" },
						{ storeName: "descrizioneProdotto", gridName: "descrizioneProdotto" },
					],
					description: "$(idProfile) - $(descrizioneProdotto)",
					searchWindow: (open, close, title, onSelection) => (
						<SearchWindow
							open={open}
							close={close}
							title={i18next.t("profile_selection")}
							onSelection={onSelection}
							filters={searchWindowFilters}
							gridParams={searchWindowGridParam}
							autoload
							minHeight={300}
						/>
					),
					type: SEARCHWINDOW,
					required: true,
				},
				{
					label: i18next.t("seq_nr"),
					name: "seqTemplate",
					type: NUMBERFIELD,
					required: true,
				},
			],
		},
	];
	return (
		<>
			<EditWindow
				title={i18next.t("f_new") + " " + i18next.t("assignment")}
				open={props.open}
				width="sm"
				close={() => onClose()}
				fields={fields}
				stateElement={assignment}
				setFunction={setAssignment}
				onSave={onSave}
			/>
		</>
	);
}
