import { SvgIcon } from "@mui/material";
import React from "react";

const ExcelIcon = (props) => {
	return (
		<SvgIcon {...props} viewBox="0 0 26 26">
			<defs>
				<linearGradient
					id="a"
					x1="110.829"
					x2="110.829"
					y1="729.648"
					y2="596.074"
					gradientTransform="matrix(0 -.09932 .098 0 -58.41 22.014)"
					gradientUnits="userSpaceOnUse"
				>
					<stop offset="0" stopOpacity="1" />
					<stop offset="1" stopOpacity="1" />
				</linearGradient>
			</defs>
			<path
				d="M9.725 2.893H23.38V21.18H9.725Zm0 0"
				stroke="none"
				fillRule="nonzero"
				fill="#fff"
				fillOpacity="1"
			/>
			<path
				d="M9.203 2.348a.971 1.016 0 0 0-.97 1.023v17.271c0 .567.433 1.022.97 1.022h13.84a.97 1.015 0 0 0 .965-1.022V3.371a.97 1.015 0 0 0-.966-1.023Zm-.195.848h14.218v17.612H9.008Zm0 0"
				stroke="none"
				fillOpacity="1"
			/>
			<path
				d="M17.69 4.887h3.828v2.19H17.69Zm-4.642 0h3.831v2.19h-3.831ZM17.69 7.88h3.828v2.19H17.69Zm-4.642-.01h3.831v2.191h-3.831Zm4.642 3.043h3.828v2.191H17.69Zm-4.642 0h3.831v2.191h-3.831Zm4.642 3.068h3.828v2.19H17.69Zm-4.642-.02h3.831v2.19h-3.831Zm4.642 3.033h3.828v2.19H17.69Zm-4.642 0h3.831v2.19h-3.831Zm0 0"
				stroke="none"
				fillRule="nonzero"
				fillOpacity="1"
			/>
			<path d="M13.66 24.017V0L0 2.4v19.214Zm0 0" stroke="none" fillRule="nonzero" />
			<path
				d="m5.344 7.994.945 2.604c.07.153.102.32.115.43-.004-.128.02-.23.09-.43l1.12-2.745 1.72-.11a114.663 119.875 0 0 0-2.009 4.266l2.094 4.385-1.854-.115-1.129-2.864c-.085-.218-.069-.243-.085-.35-.009.073-.013.137-.062.274l-1.133 2.785-1.594-.099 1.843-3.958-1.659-3.971Zm0 0"
				stroke="none"
				fillRule="nonzero"
				fill="#fff"
				fillOpacity="1"
			/>
		</SvgIcon>
	);
};

export default ExcelIcon;
