import React, { useEffect } from "react";
import { CONTAINS, EQUAL } from "../../utils/FiltersOperators";
import { COMBO, TEXTFIELD } from "../../utils/ComponentList";
import i18next from "../../localization/i18n";
import FiltersAndGrid from "../../components/template/FiltersAndGrid";
import CompareArrowsIcon from "@mui/icons-material/CompareArrows";
import SaitRowActionDelete from "../../components/atomic/Columns/SaitRowActionDelete";
import SaitRowActionEdit from "../../components/atomic/Columns/SaitRowActionEdit";
import { axiosFetchAllCombo, standardAxiosPost } from "../../utils/AxiosUtils";
import { activateLoading, deactivateLoading } from "../../store/features/globalLoadingReducer";
import axiosInstance from "../../AxiosConfig";
import { useDispatch, useSelector } from "react-redux";
import NuovaAssociazione from "./NuovaAssociazione/NuovaAssociazione";
import EditAssociazione from "./EditAssociazione/EditAssociazione";

export default function AssociazioneTemplateProfilo() {
	/*************
	 *	DICHIARAZIONI   *
	 **************/
	const dispatch = useDispatch();
	const token = useSelector((state) => state["auth"]["token"]);
	const module = useSelector((state) => state["module"]["module"]);
	/*************
	 *	MODEL   *
	 **************/

	/*************
	 *	  STATI    *
	 **************/

	const [itemsIwswdnormaMaker, setItemsIwswdnormaMaker] = React.useState([]);
	const [itemsIwswdnormaTemplate, setItemsIwswdnormaTemplate] = React.useState([]);
	const [itemsIwswdotidObjectProfile, setItemsIwswdotidObjectProfile] = React.useState([]);
	const [openNewAssignment, setOpenNewAssignment] = React.useState(false);
	const [selectedAssignment, setSelectedAssignment] = React.useState({});
	const [selectedProfile, setSelectedProfile] = React.useState({});
	const [reloadGrid, setReloadGrid] = React.useState(false);

	/*************
	 *	LISTENER   *
	 **************/

	useEffect(() => {
		const comboQueries = [
			{
				findFunction: "/norma/findIwswdnormaMaker",
				orderBy: [{ property: "descNormativa", direction: "ASC" }],
				findFilters: [],
				setItems: setItemsIwswdnormaMaker,
			},
			{
				findFunction: "/norma/findIwswdnormaTemplateForCombo",
				orderBy: [{ property: "descrizioneTemplate", direction: "ASC" }],
				findFilters: [],
				setItems: setItemsIwswdnormaTemplate,
			},
			{
				findFunction: "/norma/findIwswotidObjectProfileForCombo",
				orderBy: [{ property: "idProfile", direction: "ASC" }],
				findFilters: [],
				setItems: setItemsIwswdotidObjectProfile,
			},
		];
		axiosFetchAllCombo(comboQueries);
	}, []);

	/*************
	 *	  GRID    *
	 **************/

	const gridParams = {
		findFunction: "/norma/findIwswdnormaProfileTemplates",
		orderBy: [
			{
				property: "idTemplate",
				direction: "ASC",
			},
		],
		columns: [
			// {
			// 	headerName: i18next.t("regulation_id"),
			// 	field: "idTipoNorma",
			// 	width: 150,
			// },
			{
				headerName: i18next.t("regulation_description"),
				field: "descNormativa",
				flex: 1,
			},
			// {
			// 	headerName: i18next.t("idTemplate"),
			// 	field: "idTemplate",
			// 	width: 100,
			// },
			{
				headerName: i18next.t("template_description"),
				field: "descrizioneTemplate",
				flex: 1,
			},
			{
				headerName: i18next.t("seq_nr_short"),
				field: "seqTemplate",
				width: 120,
			},
			{
				headerName: i18next.t("profile_id"),
				field: "idProfile",
				width: 100,
			},
			{
				headerName: i18next.t("profile_description"),
				field: "descrizioneProdotto",
				flex: 1,
			},
			{
				headerName: i18next.t("item_code"),
				field: "articoloCod",
				width: 120,
			},
			{
				headerName: i18next.t("variant") + " " + 1,
				field: "keyTaglia",
				width: 120,
			},
			{
				headerName: i18next.t("variant") + " " + 2,
				field: "keyColore",
				width: 120,
			},
			{
				headerName: i18next.t("production_lot"),
				field: "lottoProduzione",
				width: 120,
			},
			SaitRowActionEdit({
				id: "EditAssociazione",
				onClick: (params) => {
					setSelectedProfile(params.row);
					fetchData(params.row.idProfileTemplate);
				},
			}),
			SaitRowActionDelete({
				id: "EliminaAssociazione",
				description: (params) => {
					return (
						"template " +
						params.row.descrizioneTemplate +
						" (" +
						i18next.t("regulation") +
						": " +
						params.row.descNormativa +
						")"
					);
				},
				onConfirm: (params) => {
					deleteAssignedTemplate(params.row.idProfileTemplate, params.row.idProfile);
				},
			}),
		],
	};

	/*************
	 *	  FILTERS    *
	 **************/

	const filters = [
		{
			label: i18next.t("regulation"),
			name: [{ storeName: "idTipoNorma", comboName: "idTipoNorma" }],
			type: COMBO,
			description: "$(idTipoNorma) - $(descNormativa)",
			items: itemsIwswdnormaMaker,
			operator: EQUAL,
			width: 300,
		},
		{
			label: i18next.t("template"),
			name: [{ storeName: "idTemplate", comboName: "idTemplate" }],
			type: COMBO,
			description: "$(descNormativa) - $(descrizioneTemplate)",
			items: itemsIwswdnormaTemplate,
			operator: EQUAL,
			width: 300,
		},
		{
			label: i18next.t("profile_id"),
			name: [{ storeName: "idProfile", comboName: "idProfile" }],
			type: COMBO,
			description: "$(idProfile) - $(descrizioneProdotto)",
			items: itemsIwswdotidObjectProfile,
			operator: EQUAL,
			width: 300,
		},
		{
			label: i18next.t("item_code"),
			name: "articoloCod",
			operator: CONTAINS,
			type: TEXTFIELD,
		},
		{
			label: i18next.t("variant") + " " + 1,
			name: "keyTaglia",
			operator: CONTAINS,
			type: TEXTFIELD,
		},
		{
			label: i18next.t("variant") + " " + 2,
			name: "keyColore",
			operator: CONTAINS,
			type: TEXTFIELD,
		},
		{
			label: i18next.t("production_lot"),
			name: "lottoProduzione",
			operator: CONTAINS,
			type: TEXTFIELD,
		},
	];

	/*************
	 *	  BUTTONS    *
	 **************/

	const topButtons = [
		{
			text: i18next.t("assign"),
			icon: <CompareArrowsIcon color="primary" />,
			variant: "text",
			function: () => {
				setOpenNewAssignment(true);
			},
		},
	];

	/*************
	 *	FUNCTIONS *
	 **************/

	const deleteAssignedTemplate = async (item, idProfile) => {
		await standardAxiosPost({
			dispatch: dispatch,
			procedureName: "/norma/deleteIwswdnormaProfileTemplates",
			parameters: { idProfileTemplate: item, idProfile: idProfile },
			onSuccess: () => {
				setReloadGrid(true);
			},
		});
	};

	const fetchData = async (idProfileTemplate) => {
		const language = navigator.language.substring(0, 2);
		dispatch(activateLoading(module));
		await axiosInstance
			.get(
				"norma/getTemplateProfileInfo?idProfileTemplate=" +
					idProfileTemplate +
					"&lingua=" +
					language,
				{
					headers: {
						Authorization: `Bearer ${token}`,
						"X-Authentication-Strategy": "oauth2",
					},
				}
			)
			.then((response) => {
				if (response.status === 200) {
					setSelectedAssignment(response.data.data);
				}
				dispatch(deactivateLoading(module));
			})
			.catch((error) => {
				console.error(error);
				dispatch(deactivateLoading(module));
			});
	};

	/*************
	 *	HTML   *
	 **************/

	return (
		<>
			<FiltersAndGrid
				filters={filters}
				gridParams={gridParams}
				topButtons={topButtons}
				resetFilter
				autoload
				controlledFilter={reloadGrid}
				setControlledFilter={setReloadGrid}
			/>
			<NuovaAssociazione
				open={openNewAssignment}
				setControlledFilter={setReloadGrid}
				setSelectedProfile={setSelectedProfile}
				fetchData={fetchData}
				close={() => {
					setOpenNewAssignment(false);
				}}
			/>
			<EditAssociazione
				open={selectedAssignment}
				assignment={selectedAssignment}
				selectedProfile={selectedProfile}
				setAssignment={setSelectedAssignment}
				close={() => {
					setSelectedAssignment({});
					setSelectedProfile({});
				}}
			/>
		</>
	);
}
