import React, { useEffect, useRef } from "react";
import {
	DataGrid,
	GridToolbarColumnsButton,
	GridToolbarContainer,
	GridToolbarDensitySelector,
	itIT,
} from "@mui/x-data-grid";
import {
	Badge,
	Box,
	IconButton,
	Menu,
	Table,
	TableBody,
	TableContainer,
	TableHead,
	TableRow,
	Tooltip,
	Typography,
	styled,
} from "@mui/material";
import { formatLabel } from "../../utils/SaitUtils";
import "./Atomic.css";
import SaitButton from "./SaitButton";
import { excelExport } from "../../utils/ExcelUtils";
import i18next from "i18next";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import DeleteIcon from "@mui/icons-material/Delete";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { useSelector } from "react-redux";
import PivotTableChartIcon from "@mui/icons-material/PivotTableChart";
import NomeFileExcel from "../template/NomeFileExcel";
import ExcelIcon from "../icons/ExcelIcon";

export default function SaitGrid(props) {
	/////////////// DEFAULT PARAMETERS ///////////////
	// MANDATORY:
	//     rows:            Righe da visualizzare in griglia
	//     columns:         Colonne della griglia
	//     isFiltering:     Parametro che maschera la griglia quando sta filtrando
	//     pageSize:        Numero di righe per pagina
	const defaultMinHeight = 200; // Altezza minima griglia
	//////////////////////////////////////////////////

	const [selectionModel, setSelectionModel] = React.useState([]);
	const [columnVisibilityModel, setColumnVisibilityModel] = React.useState(
		props.columns.reduce((acc, item) => {
			acc[item.field] = !item.hidden;
			return acc;
		}, {})
	);

	const [columnsGrid, setColumnsGrid] = React.useState([]);
	const [openNomeExport, setOpenNomeExport] = React.useState(false);
	const [openPivot, setOpenPivot] = React.useState(false);

	let activeTabId = useSelector((state) => state["generalConfig"]["activeTabId"]);

	const childRef = useRef(null);

	const [randomKey, setRandomKey] = React.useState(Math.random() * 100);

	useEffect(() => {
		// Viene settato un random key alla griglia ogni volta che si cambia tab,
		// in modo da far re-renderizzare la griglia
		// e farla ridimensionare correttamente, perchè se cambiavi
		// tab con la griglia che stava caricando si ridimensionava in modo errato.
		const rect = childRef?.current?.getBoundingClientRect();
		if (rect?.width > 0 || rect?.height > 0) {
			setRandomKey(Math.random() * 100);
		}
	}, [activeTabId]);

	useEffect(() => {
		let newColumns = props.columns?.map((column, index) => {
			return manageColumn(column);
		});
		setColumnsGrid(newColumns);
	}, [props.columns]);

	useEffect(() => {
		if (props.selectFirstRow) {
			if (props.rows?.length > 0) {
				cambiaSelezione([props.rows[0].id]);
			}
		}
		// eslint-disable-next-line
	}, [props.rows, props.selectFirstRow]);

	const manageColumn = (column) => {
		if (column.valueFormatter || column.actionColumn) {
			return column;
		} else {
			// Metto il tooltip ai dati che vengono troncati perchè troppo lunghi
			let renderCell = column.renderCell;
			if (!renderCell) {
				renderCell = (params) => {
					if (column.tooltip) {
						return (
							<Tooltip
								title={
									<Typography sx={{ whiteSpace: "pre-line" }} fontSize={14}>
										{formatLabel(column.tooltip, params.row)}
									</Typography>
								}
							>
								<span
									style={{
										overflow: "hidden",
										textOverflow: "ellipsis",
									}}
								>
									{params.value}
								</span>
							</Tooltip>
						);
					} else {
						const charPixels = 7.6;
						const columnWidth = params.colDef.computedWidth;
						if (params.value) {
							const stringLength = params.value.length;
							const isStringCut = columnWidth < stringLength * charPixels;
							if (isStringCut) {
								return (
									<Tooltip
										title={
											<Typography fontSize={14}>{params.value}</Typography>
										}
									>
										<span
											style={{
												overflow: "hidden",
												textOverflow: "ellipsis",
											}}
										>
											{params.value}
										</span>
									</Tooltip>
								);
							} else {
								return (
									<span style={{ overflow: "hidden", textOverflow: "ellipsis" }}>
										{params.value}
									</span>
								);
							}
						}
					}
				};
			}
			return {
				...column,
				renderCell: renderCell,
			};
		}
	};

	const cambiaSelezione = (newSelectionModel) => {
		//Cerco gli ID di newSelectionModel nelle righe perchè se viene rieseguita la find cambiano gli ID delle righe
		if (props.selection?.multipleSelection) {
			let multipleElementArray = [];
			let multipleElementArrayId = [];
			newSelectionModel.forEach((selectedId) => {
				let rigaSelezionata = props.rows.find((row) => row.id === selectedId);
				if (rigaSelezionata) {
					multipleElementArray.push(rigaSelezionata);
					multipleElementArrayId.push(rigaSelezionata.id);
				}
			});

			if (props.selection?.selectionFunction) {
				props.selection.selectionFunction(multipleElementArray);
			}
			setSelectionModel(multipleElementArrayId);
		} else {
			const rigaSelezionata = props.rows.find(
				(row) => row.id === newSelectionModel[newSelectionModel.length - 1]
			);

			let singleElementArrayId = [];
			if (rigaSelezionata) {
				singleElementArrayId.push(rigaSelezionata.id);
			}

			if (props.selection?.selectionFunction) {
				props.selection.selectionFunction(rigaSelezionata);
			}

			if (props.setSelectedRecord) {
				props.setSelectedRecord(rigaSelezionata);
			}
			setSelectionModel(singleElementArrayId);
		}
	};

	function onFilterChange(newFilters) {
		let newGridFilters = newFilters.items;
		let mergedFilters = [...props.gridFilters];

		newGridFilters.forEach((newGridFilter) => {
			const existingFilterIndex = mergedFilters.findIndex(
				(filter) => filter.id === newGridFilter.id
			);

			if (existingFilterIndex !== -1) {
				if (
					newGridFilter.value ||
					newGridFilter.value === 0 ||
					newGridFilter.operatorValue === "isEmpty" ||
					newGridFilter.operatorValue === "isNotEmpty"
				) {
					// Aggiorno il filtro
					mergedFilters[existingFilterIndex] = newGridFilter;
				} else {
					// Rimuovi il filtro
					mergedFilters = mergedFilters.filter(
						(filter) => filter.id !== newGridFilter.id
					);
				}
			} else {
				// Aggiungo il filtro
				if (
					newGridFilter.value ||
					newGridFilter.value === 0 ||
					newGridFilter.operatorValue === "isEmpty" ||
					newGridFilter.operatorValue === "isNotEmpty"
				) {
					mergedFilters.push(newGridFilter);
				}
			}
		});

		props.changeGridFilters?.(mergedFilters);
	}

	function deleteGridFilter(filterId) {
		let updatedFilters = [...props.gridFilters];
		updatedFilters = updatedFilters.filter((filter) => filter.id !== filterId);
		props.changeGridFilters?.(updatedFilters);
	}

	function getColumnLabelFromField(columnField) {
		return columnsGrid.find((column) => column.field === columnField)["headerName"];
	}

	function getLocaleOperator(operator) {
		switch (operator) {
			case "contains":
				return i18next.t("contains");
			case "equals":
				return i18next.t("equalsTo");
			case "startsWith":
				return i18next.t("startsWith");
			case "endsWith":
				return i18next.t("endsWith");
			case "isEmpty":
				return i18next.t("isEmpty");
			case "isNotEmpty":
				return i18next.t("isNotEmpty");
			case "isAnyOf":
				return i18next.t("isAnyOf");
			case "=":
				return "=";
			case "!=":
				return "!=";
			case ">":
				return ">";
			case ">=":
				return ">=";
			case "<":
				return "<";
			case "<=":
				return "<=";
			case "is":
				return i18next.t("is");
			case "not":
				return i18next.t("is_not");
			case "after":
				return i18next.t("after_the");
			case "onOrAfter":
				return i18next.t("from_the");
			case "before":
				return i18next.t("before_the");
			case "onOrBefore":
				return i18next.t("to_the");

			default:
				return i18next.t("contains");
		}
	}

	function CustomToolbar() {
		const [gridFiltersAnchorEl, setGridFiltersAnchorEl] = React.useState(null);
		const openGridFilters = Boolean(gridFiltersAnchorEl);

		const handleClick = (event) => {
			setGridFiltersAnchorEl(event.currentTarget);
		};
		const handleClose = () => {
			setGridFiltersAnchorEl(null);
		};

		const StyledTableCell = styled(TableCell)(({ theme }) => ({
			[`&.${tableCellClasses.head}`]: {
				backgroundColor: theme.palette.primary.light,
			},
			[`&.${tableCellClasses.body}`]: {
				fontSize: 14,
			},
		}));

		return (
			(props.standardToolbar || props.topButtons?.length > 0) && (
				<GridToolbarContainer className="custom-toolbar">
					{props.standardToolbar ? (
						<Box>
							<GridToolbarColumnsButton />
							{/* <GridToolbarFilterButton /> */}
							<GridToolbarDensitySelector />
							{/* <GridToolbarExport
								csvOptions={{
									fileName: "EstrazioneExcel",
									delimiter: ";",
									utf8WithBom: true,
								}}
							/> */}

							{/* ------------------------ FILTRI GRID ------------------------ */}
							<Badge
								badgeContent={props.gridFilters.length}
								color="primary"
								invisible={props.gridFilters.length === 0}
							>
								<SaitButton
									text={i18next.t("filters")}
									loading={props.isFiltering}
									onClick={handleClick}
									startIcon={<FilterAltIcon />}
									variant="text"
									disabled={props.gridFilters.length === 0}
								/>
							</Badge>
							<Menu
								anchorEl={gridFiltersAnchorEl}
								open={openGridFilters}
								onClose={handleClose}
							>
								<TableContainer>
									<Table>
										<TableHead>
											<TableRow>
												<StyledTableCell width={10}>
													{i18next.t("column")}
												</StyledTableCell>
												<StyledTableCell>
													{i18next.t("operator")}
												</StyledTableCell>
												<StyledTableCell>
													{i18next.t("value")}
												</StyledTableCell>
												<StyledTableCell />
											</TableRow>
										</TableHead>
										<TableBody>
											{props.gridFilters.map((gridFilter, index) => (
												<TableRow key={gridFilter.id}>
													<TableCell>
														{getColumnLabelFromField(
															gridFilter.columnField
														)}
													</TableCell>
													<TableCell>
														{getLocaleOperator(
															gridFilter.operatorValue
														)}
													</TableCell>
													<TableCell>
														{Array.isArray(gridFilter.value)
															? gridFilter.value.join(", ")
															: gridFilter.value}
													</TableCell>
													<TableCell>
														<IconButton
															size="small"
															color="error"
															onClick={() => {
																deleteGridFilter(gridFilter.id);
															}}
														>
															<DeleteIcon />
														</IconButton>
													</TableCell>
												</TableRow>
											))}
										</TableBody>
									</Table>
								</TableContainer>
							</Menu>
							{/* ------------------------ END FILTRI GRID ------------------------ */}
							<SaitButton
								text="Excel"
								loading={props.isFiltering}
								onClick={() => setOpenNomeExport(true)}
								startIcon={<ExcelIcon />}
								variant="text"
								disabled={props.rows.length === 0}
							/>
							{props.pivotFunction && (
								<SaitButton
									text="Pivot"
									loading={props.isFiltering}
									onClick={() => setOpenPivot(true)}
									startIcon={<PivotTableChartIcon />}
									variant="text"
									disabled={props.rows.length === 0}
								/>
							)}
						</Box>
					) : (
						<Box />
					)}
					<Box>
						{props.topButtons?.map((topButton, index) => (
							<SaitButton
								key={index}
								sx={{ ml: "5px" }}
								text={topButton.text}
								loading={props.isFiltering}
								onClick={topButton.function}
								endIcon={topButton.icon}
								color={topButton.color || "primary"}
								variant={topButton.variant || "text"}
								disabled={topButton.disabled}
							/>
						))}
					</Box>
				</GridToolbarContainer>
			)
		);
	}

	const CustomFilterMenu = (props) => {
		const { colDef } = props;

		return <div style={{ zIndex: 99999 }}>{colDef.headerName}</div>;
	};

	return (
		<>
			<DataGrid
				key={randomKey}
				ref={childRef}
				components={{
					Toolbar: CustomToolbar,
					FilterMenu: CustomFilterMenu,
					...props.components,
				}}
				sx={{
					boxShadow: 1,
					border: 1,
					borderColor: "primary.light",
					"& .MuiDataGrid-columnHeader": {
						backgroundColor: "primary.light",
					},
					"& .MuiDataGrid-row:nth-of-type(even)": {
						backgroundColor: "evenRowGrid.main",
					},
					"& .MuiDataGrid-row:nth-of-type(odd)": {
						backgroundColor: "background.default",
					},
					"& .MuiDataGrid-row.Mui-selected": {
						color: "primary.dark",
						backgroundColor: "primary.light",
					},
					"& .MuiDataGrid-row:hover": {
						backgroundColor: "hoverRowGrid.main",
					},
					"& .MuiDataGrid-footerContainer": {
						height: "30px",
						minHeight: "30px",
					},
					"& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell:focus": {
						outline: "none",
					},

					minHeight: props.height ? props.height : props.minHeight || defaultMinHeight,
				}}
				rows={props.rows}
				columns={columnsGrid.map((col) => ({
					...col,
					cellClassName: (params) => {
						return props.isFiltering ? "disabled-cell" : "";
					},
					type: col.type || "string",

					sortable: false,
					disableColumnMenu: true,
				}))}
				pageSize={props.pageSize}
				rowsPerPageOptions={[props.pageSize]}
				checkboxSelection={
					props.selection?.checkboxSelection || props.selection?.multipleSelection
				}
				loading={props.isFiltering}
				onPageChange={(newPage) => props.handlePageChanged(newPage)}
				page={props.page}
				paginationMode={props.paginationMode || "server"}
				rowCount={props.rowCount}
				sortingMode={props.sortingMode || "server"}
				onSortModelChange={(newSort) =>
					props.handleSortChanged ? props.handleSortChanged(newSort) : null
				}
				rowHeight={props.rowHeight || 26}
				headerHeight={props.headerHeight || 32}
				hideFooterSelectedRowCount={!props.selection?.multipleSelection}
				onSelectionModelChange={(newSelectionModel) => {
					cambiaSelezione(newSelectionModel);
				}}
				selectionModel={selectionModel}
				localeText={itIT.components.MuiDataGrid.defaultProps.localeText}
				onRowDoubleClick={props.handleRowDoubleClick}
				hideFooter={props.hideFooter}
				// getRowClassName={(params) => {
				// 	if (params.row.nome === "Mario") {
				// 		return "green-row";
				// 	} else if ((params.indexRelativeToCurrentPage + 1) % 2 === 0) {
				// 		return "evenRowGrid";
				// 	} else {
				// 		return "oddRowGrid";
				// 	}
				// 	return "";
				// }}
				isRowSelectable={props.isRowSelectable}
				columnVisibilityModel={columnVisibilityModel}
				onColumnVisibilityModelChange={(newModel) => setColumnVisibilityModel(newModel)}
				filterMode="server"
				onFilterModelChange={onFilterChange}
			/>
			<NomeFileExcel
				open={openNomeExport}
				close={() => setOpenNomeExport(false)}
				exportFunction={excelExport}
				exportParams={{
					rowCount: props.rowCount,
					columns: props.columns,
					columnsVisibility: columnVisibilityModel,
					fetchDataForExcel: props.fetchDataForExcel,
				}}
			/>
			{props.pivotFunction && (
				<NomeFileExcel
					open={openPivot}
					close={() => setOpenPivot(false)}
					exportFunction={props.pivotFunction}
					exportParams={{
						rowCount: props.rowCount,
						columns: props.columns,
						columnsVisibility: columnVisibilityModel,
						fetchDataForExcel: props.fetchDataForExcel,
					}}
				/>
			)}
		</>
	);
}
