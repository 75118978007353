import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import Paper from "@mui/material/Paper";
import axiosInstance from "../../AxiosConfig";
import i18next from "i18next";
import { CircularProgress } from "@mui/material";

export default function ReadQrLandingPage(props) {
	const [product, setProduct] = React.useState({});
	const [loading, setLoading] = React.useState(true);
	const borderStyle = "1px solid #e0e0e0";

	useEffect(() => {
		const fetchData = async () => {
			try {
				const language = navigator.language.substring(0, 2);
				const response = await axiosInstance.get(
					"norma/getProductInfo?product_uid=" + props.idProfile + "&lingua=" + language
				);
				if (response.status === 200) {
					setProduct(response.data.data);
				} else {
					setProduct({});
				}
			} catch (error) {
				setProduct({});
			}
			setLoading(false);
		};

		fetchData();
	}, [props.idProfile]);

	return (
		<Box
			display="flex"
			flexDirection="row"
			sx={{
				justifyContent: "center",
				m: 1,
			}}
		>
			<Paper
				elevation={12}
				sx={{ align: "center", flex: 1, maxWidth: 700, pl: 3, pr: 3, pb: 2 }}
			>
				{loading ? (
					<Box
						flex={1}
						display="flex"
						sx={{
							justifyContent: "center",
							pt: 2,
						}}
					>
						<CircularProgress color="inherit" />
					</Box>
				) : !product?.["uid"] ? (
					<Box
						flex={1}
						display="flex"
						sx={{
							justifyContent: "center",
							pt: 2,
						}}
					>
						<Typography fontWeight="bold">{i18next.t("product_not_found")}</Typography>
					</Box>
				) : (
					<>
						<Box flex={1} display="flex">
							<Box
								sx={{
									flexBasis: "50%",
								}}
								display="flex"
								alignItems="center"
								justifyContent="center"
							>
								<Box>
									<Typography variant="h5" sx={{ mt: 2, ml: 2 }}>
										{product["nome"]}
									</Typography>
									{product["mainImage"] && (
										<img
											style={{
												margin: "auto",
												height: "100%", // Altezza dell'immagine al 100% del contenitore
												maxHeight: 250, // Altezza massima dell'immagine al 100% del contenitore
												width: "auto", // Larghezza automatica per mantenere le proporzioni
												maxWidth: 180, // Larghezza massima dell'immagine al 100% del contenitore+
												display: "block",
											}}
											src={
												window.Configs.cdnAddressDev + product["mainImage"]
											}
											alt="profileImage"
										/>
									)}
								</Box>
							</Box>
							<Box
								sx={{
									flexBasis: "50%",
								}}
								display="flex"
								alignItems="center"
							>
								<Box sx={{ mt: 2, pl: 1 }}>
									<Typography variant="subtitle1" fontWeight="bold">
										{i18next.t("product_quantity")}:
									</Typography>
									<Typography sx={{ mb: 2 }}>{product["quantita"]}</Typography>

									<Typography variant="subtitle1" fontWeight="bold">
										{i18next.t("year")}:
									</Typography>
									<Typography sx={{ mb: 2 }}>{product["annata"]}</Typography>

									<Typography variant="subtitle1" fontWeight="bold">
										{i18next.t("lot_number")}:
									</Typography>
									<Typography sx={{ mb: 2 }}>
										{product["lottoProduzione"]}
									</Typography>
								</Box>
							</Box>
						</Box>
						{product["normative"]?.map((normativa) => (
							<Box key={normativa.id} flex={1}>
								<Typography variant="h5" sx={{ mt: 2, ml: 2, mr: 2 }}>
									{normativa.titolo}
								</Typography>
								{normativa.descrizioneSopra && (
									<Typography
										variant="subtitle1"
										sx={{ ml: 2, whiteSpace: "pre-line" }}
									>
										{normativa.descrizioneSopra}
									</Typography>
								)}
								<Box
									sx={{
										justifyContent: "center",
									}}
									display="flex"
									alignItems="center"
								>
									<TableContainer
										sx={{
											border: 1,
											borderColor: "grey.400",
											ml: 2,
											mr: 2,
										}}
										style={{ width: "100%" }}
									>
										<Table
											sx={{
												minWidth: 70,
											}}
											id={normativa.id}
										>
											<TableHead key={normativa.id}>
												<TableRow>
													<TableCell
														style={{ borderRight: borderStyle }}
													></TableCell>
													{normativa.elementi.map((elem) => (
														<TableCell
															style={{ borderRight: borderStyle }}
															key={elem.idElemento}
															// align="right">
														>
															<Typography
																variant="body1"
																fontWeight="bold"
															>
																{elem.descElemento}
															</Typography>
														</TableCell>
													))}
												</TableRow>
											</TableHead>
											<TableBody>
												{normativa.dimensioni.map((dim) => (
													<TableRow key={dim.idDimensione}>
														<TableCell
															style={{ borderRight: borderStyle }}
															component="th"
															scope="row"
														>
															<Typography
																variant="body1"
																fontWeight="bold"
															>
																{dim.descDimensione}
															</Typography>
														</TableCell>

														{normativa.elementi.map((elemento) => {
															const valore = normativa.valori.find(
																(val) =>
																	val.idElemento ===
																		elemento.idElemento &&
																	val.idDimensione ===
																		dim.idDimensione
															);
															return (
																<TableCell
																	style={{
																		borderRight: borderStyle,
																	}}
																	key={`${elemento.idElemento}-${dim.idDimensione}`}
																	// align="right"
																>
																	<Typography variant="body1">
																		{valore
																			? valore.valore
																			: "-"}
																	</Typography>
																</TableCell>
															);
														})}
													</TableRow>
												))}
											</TableBody>
										</Table>
									</TableContainer>
								</Box>
								{normativa.descrizioneSotto && (
									<Typography
										variant="subtitle1"
										sx={{ ml: 2, mt: 2, whiteSpace: "pre-line" }}
									>
										{normativa.descrizioneSotto}
									</Typography>
								)}
							</Box>
						))}
					</>
				)}
			</Paper>
		</Box>
	);
}
