import React, { Fragment, useEffect, useMemo } from "react";
import SaitButton from "../atomic/SaitButton";
import CancelIcon from "@mui/icons-material/Cancel";
import SaveIcon from "@mui/icons-material/Save";
import "dayjs/locale/it";
import SaitDialog from "../atomic/SaitDialog";
import { setGlobalError } from "../../store/features/globalMessageReducer";
import { useDispatch, useSelector } from "react-redux";
import i18next from "../../localization/i18n";
import EditPanel from "./EditPanel";
import { checkOpen } from "../../utils/SaitUtils";
import { TAB } from "../../utils/ComponentList";

export default function EditWindow(props) {
	const dispatch = useDispatch();
	const module = useSelector((state) => state["module"]["module"]);

	const [attemptSave, setAttemptSave] = React.useState(false);
	const [requiredFields, setRequiredFields] = React.useState([]);

	const stringPropsFields = useMemo(() => JSON.stringify(props.fields), [props.fields]);

	useEffect(() => {
		let newRequiredArray = [];
		JSON.parse(stringPropsFields).forEach((paragraph) => {
			if (paragraph.type === TAB) {
				paragraph.items.forEach((card) => {
					card.items.forEach((item) => {
						if (item.required) {
							newRequiredArray.push(item.name);
						}
					});
				});
			} else {
				paragraph.items.forEach((item) => {
					if (item.required) {
						newRequiredArray.push(item.name);
					}
				});
			}
		});
		setRequiredFields(newRequiredArray);
	}, [stringPropsFields]);

	useEffect(() => {
		if (!checkOpen(props.open)) {
			setAttemptSave(false);
		}
	}, [props.open]);

	const handleClose = () => {
		props.close();
	};

	const handleOnSave = () => {
		setAttemptSave(true);

		let allOk = true;
		requiredFields.some((requiredField) => {
			if (Array.isArray(requiredField)) {
				// Controllo che almeno uno degli storeName della Combo sia valorizzato
				let allComboNull = true;
				requiredField.forEach((reqField) => {
					if (props.stateElement[reqField.storeName]) {
						allComboNull = false;
					}
				});
				if (allComboNull) {
					allOk = false;
					return true;
				}
			} else {
				if (!props.stateElement[requiredField]) {
					allOk = false;
					return true;
				}
			}
			return false;
		});
		if (allOk) {
			props.onSave();
		} else {
			dispatch(
				setGlobalError({
					module: module,
					errorMessage: i18next.t("error_enter_all_required_fields"),
				})
			);
		}
	};

	return (
		<SaitDialog
			open={checkOpen(props.open)}
			title={props.title}
			topButtons={props.topButtons}
			width={props.width || "lg"}
			minHeight={props.minHeight}
			scroll={props.scroll || "paper"}
			flexDirection="column"
			titleTextAlign={props.titleTextAlign}
			fullScreen={props.fullScreen}
			fullWidth={props.fullWidth}
			content={
				<EditPanel
					attemptSave={attemptSave}
					setFunction={props.setFunction}
					stateElement={props.stateElement}
					fields={props.fields}
					open={props.open}
				/>
			}
			actions={
				<Fragment>
					<SaitButton
						text={i18next.t("close")}
						color="error"
						endIcon={<CancelIcon />}
						onClick={handleClose}
						{...props.cancelProps}
					/>
					<div style={{ flexGrow: 1 }} />
					{props.onSave && (
						<SaitButton
							text={props.text || i18next.t("save")}
							endIcon={props.endIcon || <SaveIcon />}
							onClick={handleOnSave}
							color={props.color || "success"}
							{...props.confirmProps}
							disabled={props.disabled}
						/>
					)}
				</Fragment>
			}
		/>
	);
}
