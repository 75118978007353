import axios from "axios";
import { stringify } from "qs";

const axiosInstance = axios.create({
	baseURL: `${window.Configs.backendProtocol}://${window.location.hostname}:${window.Configs.backendPort}/`,
	paramsSerializer: {
		serialize: (params) => stringify(params, { encode: false }),
	},
});

export default axiosInstance;
