import React, { Fragment, useEffect } from "react";
import { useDispatch } from "react-redux";
import { setModule } from "./store/features/moduleReducer";
import { Tooltip, Typography } from "@mui/material";
import Starter from "./scenes/Starter/Starter";
import TemplateNormative from "./scenes/TemplateNormative/TemplateNormative";
import AssociazioneTemplateProfilo from "./scenes/AssociazioneTemplate/AssociazioneTemplateProfilo";
import Profili from "./scenes/Profili/Profili";
import Brands from "./scenes/Brands/Brands";
import StoreIcon from "@mui/icons-material/Store";
import DescriptionIcon from "@mui/icons-material/Description";
import LinkIcon from "@mui/icons-material/Link";
import PersonIcon from "@mui/icons-material/Person";
import ListAltIcon from "@mui/icons-material/ListAlt";
import FactCheckIcon from "@mui/icons-material/FactCheck";
import ContactPageIcon from "@mui/icons-material/ContactPage";
import Objects from "./scenes/Objects/Objects";

const componentList = (component, theme) => {
	switch (component.id) {
		case "M001":
			return {
				component: <Brands />,
				icon: (
					<StoreIcon
						style={{
							color: window.Configs.menuColorIcon
								? window.Configs.menuColorIcon
								: null,
						}}
					/>
				),
			};
		case "M002":
			return {
				component: <Profili />,
				icon: (
					<DescriptionIcon
						style={{
							color: window.Configs.menuColorIcon
								? window.Configs.menuColorIcon
								: null,
						}}
					/>
				),
			};
		case "M003":
			return {
				component: <Objects />,
				icon: (
					<ContactPageIcon
						style={{
							color: window.Configs.menuColorIcon
								? window.Configs.menuColorIcon
								: null,
						}}
					/>
				),
			};
		case "M004":
			return {
				component: <Starter />,
				icon: (
					<LinkIcon
						style={{
							color: window.Configs.menuColorIcon
								? window.Configs.menuColorIcon
								: null,
						}}
					/>
				),
			};
		case "M005":
			return {
				component: <Starter />,
				icon: (
					<PersonIcon
						style={{
							color: window.Configs.menuColorIcon
								? window.Configs.menuColorIcon
								: null,
						}}
					/>
				),
			};
		case "M006":
			return {
				component: <TemplateNormative />,
				icon: (
					<ListAltIcon
						style={{
							color: window.Configs.menuColorIcon
								? window.Configs.menuColorIcon
								: null,
						}}
					/>
				),
			};
		case "M007":
			return {
				component: <AssociazioneTemplateProfilo />,
				icon: (
					<FactCheckIcon
						style={{
							color: window.Configs.menuColorIcon
								? window.Configs.menuColorIcon
								: null,
						}}
					/>
				),
			};
		default:
			return "";
	}
};

export function RenderComponent(props) {
	const dispatch = useDispatch();
	useEffect(() => {
		const dispatchModule = (moduleId) => {
			dispatch(setModule(moduleId));
		};

		dispatchModule(props.activeTabId);
	}, [props.activeTabId, dispatch]);

	// @ts-ignore
	return <Fragment>{componentList(props.component, props.theme).component}</Fragment>;
}

export function RenderIcon(props) {
	const title = props.component.text ? (
		<Typography fontSize={14}>{props.component.text}</Typography>
	) : (
		<Typography fontSize={14}>{props.component.id}</Typography>
	);
	const placement = props.component.text ? "right-start" : "bottom";
	return (
		<Fragment>
			<Tooltip PopperProps={{ style: { zIndex: 10001 } }} title={title} placement={placement}>
				{
					// @ts-ignore
					componentList(props.component, props.theme).icon
				}
			</Tooltip>
		</Fragment>
	);
}
