// @ts-nocheck
import React, { useEffect } from "react";
import MuiAppBar from "@mui/material/AppBar";
import Snackbar from "@mui/material/Snackbar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import MenuIcon from "@mui/icons-material/Menu";
import AccountCircle from "@mui/icons-material/AccountCircle";
import LogoutIcon from "@mui/icons-material/Logout";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import MenuList from "@mui/material/MenuList";
import { styled } from "@mui/material/styles";
import { useDispatch } from "react-redux";
import IconButton from "@mui/material/IconButton";
import { setMenuOpen } from "../../store/features/generalConfigReducer";
import { Box, Divider, ListItemText } from "@mui/material";
import { unsetUserPreferences } from "../../store/features/authReducer";
import { handleOpenTabs } from "../../store/features/generalConfigReducer";
import { unsetOpenTabs } from "../../store/features/generalConfigReducer";
import { useTranslation } from "react-i18next";
import { defaultAppBarHeight } from "../../theme";
import { useMediaQuery, useTheme } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import SaitButton from "../../components/atomic/SaitButton";

const AppBar = styled(MuiAppBar, {
	shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open, drawerwidth }) => ({
	zIndex: 9999, //theme.zIndex.drawer + 1,
	transition: theme.transitions.create(["width", "margin"], {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.leavingScreen,
	}),
	...(open && {
		marginLeft: drawerwidth,
		width: `calc(100% - ${drawerwidth}px)`,
		transition: theme.transitions.create(["width", "margin"], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
	}),
}));

const ColoredTab = styled(Tab)({
	"&.Mui-selected": {
		backgroundColor: window.Configs.tabSelectedMain,
		borderRadius: 25,
		color: window.Configs.tabSelectedTextMain,
	},
});

export default function SaitAppBar(props) {
	const dispatch = useDispatch();

	const [tabMenuAnchorEl, setTabMenuAnchorEl] = React.useState(null);
	const [tabMenuAnchorElOnSmall, setTabMenuAnchorElOnSmall] = React.useState(null);
	const [tabMenuId, setTabMenuId] = React.useState(null);
	const [anchorEl, setAnchorEl] = React.useState(null);
	const [openToast, setOpenToast] = React.useState(false);
	const theme = useTheme();
	const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

	const { t } = useTranslation();

	useEffect(() => {
		// Se ridimensiono lo schermo lasciando aperto il menu ristretto delle tab aperte,
		// allora ne forzo la chiusura
		if (!fullScreen && tabMenuAnchorElOnSmall) handleTabMenuCloseOnSmallScreen();
		// eslint-disable-next-line
	}, [fullScreen]);

	const handleDrawerOpen = () => {
		dispatch(setMenuOpen(true));
	};

	const handleChangeTab = (newTabId) => {
		if (props.activeTabId !== newTabId) {
			props.setMenuActiveTabId(newTabId);
		}
	};

	const handleTabMenuClick = (event, tabId) => {
		event.preventDefault();
		setTabMenuAnchorEl(event.currentTarget);
		setTabMenuId(tabId);
	};

	const handleTabMenuClose = () => {
		setTabMenuAnchorEl(null);
		setTabMenuId(null);
	};

	const handleMenu = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const handleTabMenuOnSmallScreen = (event) => {
		event.preventDefault();
		setTabMenuAnchorElOnSmall(event.currentTarget);
	};

	const handleTabMenuCloseOnSmallScreen = () => {
		setTabMenuAnchorElOnSmall(null);
	};

	const handleLogout = async () => {
		dispatch(unsetUserPreferences());
		unsetOpenTabsOnLogout();
	};

	const handleCloseTab = () => {
		if (props.activeTabId === tabMenuId) {
			let newTab = props.openTabs.find((tab) => tab.id !== tabMenuId);
			props.setMenuActiveTabId(newTab?.id);
		}
		handleTabClosure(props.openTabs.filter((tab) => tab.id !== tabMenuId));
		setTabMenuAnchorEl(null);
		setTabMenuId(null);
	};

	const handleCloseAllTab = () => {
		handleTabClosure([]);
		setTabMenuAnchorEl(null);
		setTabMenuId(null);
		props.setMenuActiveTabId(null);
	};

	const handleCloseAllRightTab = () => {
		let menuIndex = props.openTabs.findIndex((tab) => tab.id === tabMenuId);
		let activeIndex = props.openTabs.findIndex((tab) => tab.id === props.activeTabId);
		if (menuIndex >= 0) {
			if (activeIndex > menuIndex) props.setMenuActiveTabId(props.openTabs[menuIndex].id);
			handleTabClosure(props.openTabs.filter((tab, index) => index <= menuIndex));
			setTabMenuAnchorEl(null);
			setTabMenuId(null);
		}
	};

	const handleTabClosure = (newOpenTabs) => {
		dispatch(handleOpenTabs(newOpenTabs));
	};

	const unsetOpenTabsOnLogout = () => {
		dispatch(unsetOpenTabs());
	};

	const handleCloseOnToast = (event, reason) => {
		if (reason === "clickaway") {
			return;
		}

		setOpenToast(false);
	};

	const handleOpenOnToast = () => {
		if (openToast !== true) setOpenToast(true);
	};

	return (
		<AppBar
			position="fixed"
			open={props.open}
			drawerwidth={props.drawerwidth}
			sx={{ backgroundColor: "homeAppbar.main" }}
		>
			<Toolbar
				style={{
					minHeight: `${window.Configs.appBarHeight || defaultAppBarHeight}px`,
				}}
			>
				<IconButton
					color="homeAppbarIcon"
					aria-label="open drawer"
					onClick={handleDrawerOpen}
					edge="start"
					sx={{
						marginRight: 5,
						...(props.open && { display: "none" }),
					}}
				>
					<MenuIcon />
				</IconButton>
				{props.openTabs.length > 0 && !fullScreen ? (
					<Box
						sx={{
							flexGrow: 10,
							minWidth: 200,
						}}
					>
						<Tabs
							value={props.activeTabId}
							onChange={(event, newValue) => handleChangeTab(newValue)}
							TabIndicatorProps={{
								style: { display: "none" }, //nascondo la sottolineatura della tab selezionata
							}}
							variant="scrollable"
							scrollButtons="auto"
							aria-label="scrollable auto tabs"
						>
							{props.openTabs.map((tab, index) => (
								<ColoredTab
									key={tab.id}
									value={tab.id}
									label={tab.label}
									onContextMenu={(event) => handleTabMenuClick(event, tab.id)}
									sx={{
										color: window.Configs.homeAppBarTextNotSelectedColor
											? window.Configs.homeAppBarTextNotSelectedColor
											: null,
									}}
								/>
							))}
						</Tabs>
						<Menu
							anchorEl={tabMenuAnchorEl}
							open={Boolean(tabMenuAnchorEl)}
							sx={{ zIndex: 10000 }}
							onClose={handleTabMenuClose}
						>
							<MenuItem
								onClick={() => {
									if (window.isSecureContext) {
										navigator.clipboard.writeText(tabMenuId).then(() => {
											handleOpenOnToast();
										});
									}
								}}
								dense
								divider
								sx={{ fontStyle: "italic" }}
							>
								<Typography
									variant="body2"
									color="text.secondary"
									align="center"
									display="block"
								>
									{tabMenuId}
								</Typography>
							</MenuItem>
							<MenuItem onClick={handleCloseTab}>{t("close")}</MenuItem>
							<MenuItem onClick={handleCloseAllRightTab}>
								{t("close_all_right")}
							</MenuItem>
							<Divider />
							<MenuItem onClick={handleCloseAllTab}>{t("close_all")}</MenuItem>
						</Menu>
					</Box>
				) : (
					<Box>
						<SaitButton
							onClick={(event) => {
								handleTabMenuOnSmallScreen(event);
							}}
							text={t("open_tabs")}
							variant={"text"}
							endIcon={<KeyboardArrowDownIcon />}
							color={window.Configs.homeAppBarTextColor}
							sx={{ display: !props.openTabs.length > 0 && "none" }}
						></SaitButton>
						<Menu
							anchorEl={tabMenuAnchorElOnSmall}
							open={Boolean(tabMenuAnchorElOnSmall)}
							sx={{ zIndex: 10000 }}
							onClose={handleTabMenuCloseOnSmallScreen}
						>
							{props.openTabs.length > 0 &&
								props.openTabs.map((tab, index) => (
									<MenuItem
										key={tab.id}
										onClick={() => {
											handleChangeTab(tab.id);
										}}
										sx={{ color: props.activeTabId === tab.id && "red" }}
									>
										{tab.label}
									</MenuItem>
								))}
							<Divider />
							<MenuItem
								onClick={() => {
									handleCloseAllTab();
									handleTabMenuCloseOnSmallScreen();
								}}
							>
								{t("close_all")}
							</MenuItem>
						</Menu>
					</Box>
				)}
				<Typography variant="h6" component="div" sx={{ flexGrow: 1 }}></Typography>
				{/* {props.theme.palette.mode === "dark" ? (
					<Tooltip title={t("light_theme")}>
						<IconButton color="inherit" onClick={props.colorMode.toggleColorMode}>
							<LightModeOutlinedIcon />
						</IconButton>
					</Tooltip>
				) : (
					<Tooltip title={t("dark_theme")}>
						<IconButton
							color="homeAppbarIcon"
							onClick={props.colorMode.toggleColorMode}
						>
							<DarkModeOutlinedIcon />
						</IconButton>
					</Tooltip>
				)} */}
				<div>
					<Tooltip title={t("user")}>
						<IconButton
							size="large"
							aria-label="account of current user"
							aria-controls="menu-appbar"
							aria-haspopup="true"
							onClick={handleMenu}
							color="homeAppbarIcon"
						>
							<AccountCircle />
						</IconButton>
					</Tooltip>
					<Menu
						id="menu-appbar"
						sx={{ zIndex: 10000 }}
						PaperProps={{
							sx: {
								width: 350,
							},
						}}
						anchorEl={anchorEl}
						anchorOrigin={{
							vertical: "bottom",
							horizontal: "left",
						}}
						keepMounted
						transformOrigin={{
							vertical: "top",
							horizontal: "right",
						}}
						open={Boolean(anchorEl)}
						onClose={handleClose}
					>
						<MenuList>
							<MenuItem
								onClick={() => {
									if (window.isSecureContext) {
										navigator.clipboard
											.writeText(props.userPreferences.username)
											.then(() => {
												handleOpenOnToast();
											});
									}
								}}
							>
								<ListItemText>{t("user")}</ListItemText>
								<Typography variant="body2" color="text.secondary">
									{props.userPreferences.username}
								</Typography>
							</MenuItem>
							<MenuItem
								onClick={() => {
									if (window.isSecureContext) {
										navigator.clipboard
											.writeText(props.userPreferences.roles.join(", "))
											.then(() => {
												handleOpenOnToast();
											});
									}
								}}
							>
								<ListItemText>{t("role")}</ListItemText>
								<Typography variant="body2" color="text.secondary">
									{props.userPreferences.roles.join(", ")}
								</Typography>
							</MenuItem>
						</MenuList>
					</Menu>
					<Snackbar
						open={openToast}
						autoHideDuration={1000}
						onClose={handleCloseOnToast}
						message={t("copied_to_clipboard")}
						anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
					/>
				</div>
				<Tooltip title={t("logout")}>
					<IconButton
						size="large"
						aria-label="logout"
						aria-controls="menu-appbar"
						aria-haspopup="true"
						onClick={handleLogout}
						color="homeAppbarIcon"
					>
						<LogoutIcon />
					</IconButton>
				</Tooltip>
			</Toolbar>
		</AppBar>
	);
}
