import React, { useEffect } from "react";
import { CONTAINS, EQUAL } from "../../utils/FiltersOperators";
import { COMBO, DATEFIELD, TEXTFIELD } from "../../utils/ComponentList";
import i18next from "../../localization/i18n";
import FiltersAndGrid from "../../components/template/FiltersAndGrid";
import AddIcon from "@mui/icons-material/Add";
import InfoIcon from "@mui/icons-material/Info";
import { useSelector } from "react-redux";
import SaitDateColumn from "../../components/atomic/Columns/SaitDateColumn";
import SaitRowAction from "../../components/atomic/Columns/SaitRowAction";
import NuovoProfilo from "./NuovoProfilo/NuovoProfilo";
import SaitRowActionEdit from "../../components/atomic/Columns/SaitRowActionEdit";
import DettagliProfilo from "./DettagliProfilo/DettagliProfilo";
import { axiosFetchAllCombo } from "../../utils/AxiosUtils";
import Box from "@mui/material/Box";

export default function Profili() {
	const userPreferences = useSelector((state) => state["auth"]);
	const { isMaker } = userPreferences;
	const [reloadGrid, setReloadGrid] = React.useState(false);
	const [profile, setProfile] = React.useState({});
	const [selectedProfile, setSelectedProfile] = React.useState({});
	const [openNewProfile, setOpenNewProfile] = React.useState(false);
	const [itemsOtidObjectProfileStati, setItemsOtidObjectProfileStati] = React.useState([]);
	const profiloBase = {
		descrizioneProdotto: "",
		prodotto: "",
		redirectUrl: "",
		technicalImagePath: "",
		mainImagePath: "",
		descrizioneCommLingua1: "",
		descrizioneTecLingua1: "",
		intro: "",
		marchio: "",
		articoloArchivio: "",
		articoloCod: "",
		keyTaglia: "",
		keyColore: "",
		lottoProduzione: "",
		flRegulationUrl: window.Configs.defaultValueFlRedirectNormativa || "N",
	};

	useEffect(() => {
		if (openNewProfile) {
			setProfile(profiloBase);
		}
		// eslint-disable-next-line
	}, [openNewProfile]);

	useEffect(() => {
		const comboQueries = [
			{
				findFunction: "/productProfile/findOtidObjectProfileStati",
				orderBy: [{ property: "stato", direction: "ASC" }],
				findFilters: [],
				setItems: setItemsOtidObjectProfileStati,
			},
		];
		axiosFetchAllCombo(comboQueries);
	}, []);

	const gridParams = {
		findFunction: "/productProfile/findIwstotidObjectProfile",
		orderBy: [
			{
				property: "idProfile",
				direction: "ASC",
			},
		],
		columns: [
			{
				headerName: i18next.t("main_image"),
				field: "mainImagePath",
				width: 140,
				renderCell: (params) => {
					return params.row.mainImagePath ? (
						<Box sx={{ textAlign: "center" }} height={80} width={80}>
							<img
								src={window.Configs.cdnAddressDev + params.row.mainImagePath}
								alt=""
								style={{
									height: "100%", // Altezza dell'immagine al 100% del contenitore
									maxHeight: "100%", // Altezza massima dell'immagine al 100% del contenitore
									width: "auto", // Larghezza automatica per mantenere le proporzioni
									maxWidth: "100%", // Larghezza massima dell'immagine al 100% del contenitore
								}}
							/>
						</Box>
					) : null;
				},
			},
			{
				headerName: i18next.t("profile_description"),
				field: "descrizioneProdotto",
				flex: 1,
			},
			{
				headerName: i18next.t("profile_code"),
				field: "prodotto",
				width: 120,
			},
			{
				headerName: i18next.t("item_code"),
				field: "articoloCod",
				width: 120,
			},
			{
				headerName: i18next.t("variant") + " " + 1,
				field: "keyTaglia",
				width: 120,
			},
			{
				headerName: i18next.t("variant") + " " + 2,
				field: "keyColore",
				width: 120,
			},
			{
				headerName: i18next.t("production_lot"),
				field: "lottoProduzione",
				width: 120,
			},
			SaitDateColumn({
				headerName: i18next.t("insertion_date"),
				field: "dataCreazione",
				width: 120,
			}),
			{
				headerName: i18next.t("state"),
				field: "descStatoValidazione",
				width: 200,
			},
			SaitRowAction({
				id: "DettaglioProdotto",
				icon: <InfoIcon color="success" />,
				tooltip: i18next.t("detail"),
				onClick: (params) => {
					setSelectedProfile(params.row);
				},
			}),
			SaitRowActionEdit({
				id: "EditProdotto",
				onClick: (params) => {
					setProfile(params.row);
				},
				// se l'utente connesso non è un maker, nascondo la row action di edit
				conditionalRendering: () => {
					return isMaker;
				},
			}),
		],
	};

	const filters = [
		{
			label: i18next.t("profile_description"),
			name: "descrizioneProdotto",
			operator: CONTAINS,
			type: TEXTFIELD,
		},
		{
			label: i18next.t("profile_code"),
			name: "prodotto",
			operator: CONTAINS,
			type: TEXTFIELD,
		},
		{
			label: i18next.t("item_code"),
			name: "articoloCod",
			operator: CONTAINS,
			type: TEXTFIELD,
		},
		{
			label: i18next.t("variant") + " " + 1,
			name: "keyTaglia",
			operator: CONTAINS,
			type: TEXTFIELD,
		},
		{
			label: i18next.t("variant") + " " + 2,
			name: "keyColore",
			operator: CONTAINS,
			type: TEXTFIELD,
		},
		{
			label: i18next.t("production_lot"),
			name: "lottoProduzione",
			operator: CONTAINS,
			type: TEXTFIELD,
		},
		{
			label: i18next.t("validation_state"),
			name: [{ storeName: "statoValidazione", comboName: "stato" }],
			type: COMBO,
			description: "$(stato) - $(descrizione)",
			items: itemsOtidObjectProfileStati,
			operator: EQUAL,
			width: 180,
		},
		{
			label: i18next.t("date_from"),
			name: "dataCreazione",
			operator: "DATE_GREATER_EQUAL",
			type: DATEFIELD,
			width: 180,
		},
		{
			label: i18next.t("date_to"),
			name: "dataCreazione",
			operator: "DATE_LESS_EQUAL",
			type: DATEFIELD,
			width: 180,
		},
	];

	const topButtons = [
		{
			text: i18next.t("new") + " " + i18next.t("product_profile"),
			icon: <AddIcon color="primary" />,
			variant: "text",
			disabled: !isMaker, // se l'utente connesso non è un maker, impedisco l'aggiunta di un nuovo prodotto
			function: () => {
				setOpenNewProfile(true);
			},
		},
	];

	return (
		<>
			<FiltersAndGrid
				filters={filters}
				gridParams={gridParams}
				topButtons={topButtons}
				controlledFilter={reloadGrid}
				setControlledFilter={setReloadGrid}
				rowHeight={100}
				resetFilter
				autoload
			/>
			<NuovoProfilo
				open={profile}
				newProfile={openNewProfile}
				profile={profile}
				setProfile={setProfile}
				setControlledFilter={setReloadGrid}
				itemsOtidObjectProfileStati={itemsOtidObjectProfileStati}
				close={() => {
					setOpenNewProfile(false);
					setProfile({});
				}}
			/>
			<DettagliProfilo
				open={selectedProfile}
				selectedProfile={selectedProfile}
				close={() => {
					setSelectedProfile({});
				}}
			/>
		</>
	);
}
