import React from "react";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";

const MapComponent = (props) => {
	const mapStyles = {
		height: "100vh",
		width: "100%",
	};

	const options = { minZoom: 3, maxZoom: 20 };

	return (
		<LoadScript googleMapsApiKey="AIzaSyBenQV-GOyM-kly32cASpMhb5FuQh3z57s">
			<GoogleMap
				mapContainerStyle={mapStyles}
				zoom={10}
				center={props.position}
				options={options}
			>
				{props.items.map((marker) => (
					<Marker
						key={marker.id}
						position={{
							lat: marker.gpsLatitude,
							lng: marker.gpsLongitude,
						}}
						title={marker.uniqueId}
					/>
				))}
			</GoogleMap>
		</LoadScript>
	);
};

export default MapComponent;
