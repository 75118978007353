import React from "react";
import i18next from "i18next";
import { CONTAINS } from "../../../utils/FiltersOperators";
import SaitDateColumn from "../../../components/atomic/Columns/SaitDateColumn";
import SaitDialog from "../../../components/atomic/SaitDialog";
import FiltersAndGrid from "../../../components/template/FiltersAndGrid";
import SaitButton from "../../../components/atomic/SaitButton";
import CancelIcon from "@mui/icons-material/Cancel";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import WrapperMappaProfile from "../../Mappa/WrapperMappaProfile";

export default function DettagliProfilo(props) {
	/*************
	 *	DICHIARAZIONI   *
	 **************/

	/*************
	 *	MODEL   *
	 **************/

	/*************
	 *	  STATI    *
	 **************/

	const [openMapDetail, setOpenMapDetail] = React.useState(false);

	/*************
	 *	LISTENER   *
	 **************/

	/*************
	 *	  GRID    *
	 **************/

	const gridParams = {
		findFunction: "/product/getObjectsByIdProfile",
		orderBy: [{ property: "uid", direction: "ASC" }],
		columns: [
			{
				headerName: i18next.t("unique_id"),
				field: "uid",
				flex: 1,
			},
			{
				headerName: i18next.t("maker_id"),
				field: "maker",
				width: 100,
			},
			{
				headerName: i18next.t("profile_id"),
				field: "profile",
				width: 100,
			},
			SaitDateColumn({
				headerName: i18next.t("insertion_date"),
				field: "date",
				width: 150,
			}),
			{
				headerName: i18next.t("state"),
				field: "ownType",
				width: 180,
			},
		],
	};

	/*************
	 *	  FILTERS    *
	 **************/

	const filters = [
		{
			operator: CONTAINS,
			name: "idProfile",
			defaultValue: props.selectedProfile.idProfile,
		},
	];

	/*************
	 *	  BUTTONS    *
	 **************/

	/*************
	 *	FUNCTIONS   *
	 **************/

	const handleClose = () => {
		props.close();
	};

	/*************
	 *	  HTML     *
	 **************/

	return (
		<>
			<SaitDialog
				open={props.open}
				title={i18next.t("profile_details")}
				width="lg"
				content={
					<FiltersAndGrid
						filters={filters}
						height={250}
						gridParams={gridParams}
						autoload
					/>
				}
				actions={
					<>
						<SaitButton
							text={i18next.t("close")}
							color="error"
							endIcon={<CancelIcon />}
							onClick={handleClose}
						/>
						<SaitButton
							text={i18next.t("map")}
							color="success"
							endIcon={<LocationOnIcon />}
							onClick={() => {
								setOpenMapDetail(true);
							}}
						/>
					</>
				}
			/>
			<WrapperMappaProfile
				open={openMapDetail}
				close={() => setOpenMapDetail(false)}
				idProfile={props.selectedProfile.idProfile}
			/>
		</>
	);
}
