import React, { Fragment } from "react";
import { createPortal } from "react-dom";

import SaitButton from "./SaitButton";
import i18next from "../../localization/i18n";
import SaitDialog from "./SaitDialog";
import { useDispatch, useSelector } from "react-redux";
import { unsetGlobalConfirm } from "../../store/features/globalConfirmReducer";
import { Box, Typography } from "@mui/material";

const SaitGlobalConfirm = (props) => {
	const dispatch = useDispatch();

	const globalConfirm = useSelector((state) => state["globalConfirm"]["globalConfirm"]);

	const onCancel = () => {
		globalConfirm.find((obj) => obj.module === props.module).onCancel?.();
		dispatch(
			unsetGlobalConfirm({
				module: props.module,
			})
		);
	};

	const onConfirm = () => {
		globalConfirm.find((obj) => obj.module === props.module).onConfirm?.();
		dispatch(
			unsetGlobalConfirm({
				module: props.module,
			})
		);
	};

	return createPortal(
		<SaitDialog
			open={globalConfirm.some((obj) => obj.module === props.module)}
			title={props.title || i18next.t("confirm_operation")}
			width="xs"
			content={
				<Typography align="center">
					{globalConfirm.find((obj) => obj.module === props.module)?.confirmMessage}
				</Typography>
			}
			actions={
				<Fragment>
					<SaitButton text={i18next.t("cancel")} onClick={onCancel} color="error" />
					<Box sx={{ flexGrow: 1 }} />
					<SaitButton text={i18next.t("confirm")} onClick={onConfirm} color="success" />
				</Fragment>
			}
		/>,
		document.body
	);
};

export default SaitGlobalConfirm;
