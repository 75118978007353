import React from "react";
import { CONTAINS, DATE_GREATER_EQUAL, DATE_LESS_EQUAL } from "../../utils/FiltersOperators";
import { DATEFIELD, TEXTFIELD } from "../../utils/ComponentList";
import i18next from "../../localization/i18n";
import FiltersAndGrid from "../../components/template/FiltersAndGrid";
import InfoIcon from "@mui/icons-material/Info";
import SaitDateColumn from "../../components/atomic/Columns/SaitDateColumn";
import SaitRowAction from "../../components/atomic/Columns/SaitRowAction";
import DettagliObject from "./DettagliObject/DettagliObject";
import CompareArrowsIcon from "@mui/icons-material/CompareArrows";
import { Box, IconButton, TextField } from "@mui/material";
import SearchWindow from "../../components/template/SearchWindow";
import SaitButton from "../../components/atomic/SaitButton";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";
import { useDispatch, useSelector } from "react-redux";
import { standardAxiosPost } from "../../utils/AxiosUtils";

export default function Objects() {
	const userPreferences = useSelector((state) => state["auth"]);
	const { isMaker } = userPreferences;
	const dispatch = useDispatch();
	const [reloadGrid, setReloadGrid] = React.useState(false);
	const [selectedObjectDetails, setSelectedObjectDetails] = React.useState({});
	const [associazioneObjectsSelectedRows, setAssociazioneObjectsSelectedRows] = React.useState(
		[]
	);
	const [openSearchWindow, setOpenSearchWindow] = React.useState(false);
	const [selectedProfile, setSelectedProfile] = React.useState({});

	const handleOpenSearchWindow = (bool) => {
		setOpenSearchWindow(bool);
	};

	const handleOnSelection = (selectedProfile) => {
		setSelectedProfile(selectedProfile);
	};

	const itemAssociationsLotto = async () => {
		const reqItems = {};
		const items = associazioneObjectsSelectedRows.map((item) => ({
			uniqueId: item.uniqueId,
			...selectedProfile,
		}));

		reqItems.items = items;

		await standardAxiosPost({
			dispatch: dispatch,
			procedureName: "/product/itemAssociationsLotto",
			parameters: reqItems,
			onSuccess: (response) => {
				setReloadGrid(true);
				// sbiancare l'array con gli oggetti
				setAssociazioneObjectsSelectedRows([]);
				// sbiancare il profilo selezionato dalla searchWindow
				setSelectedProfile({});
			},
		});
	};

	const gridParams = {
		selection: {
			multipleSelection: true,
			selectionFunction: (selectedRows) => {
				setAssociazioneObjectsSelectedRows(selectedRows);
			},
		},
		findFunction: "/product/findIwswotidObjects",
		orderBy: [
			{
				property: "uniqueId",
				direction: "ASC",
			},
		],
		columns: [
			{
				headerName: i18next.t("main_image"),
				field: "mainImagePath",
				width: 150,
				renderCell: (params) => {
					return params.row.mainImagePath ? (
						<Box sx={{ textAlign: "center" }} height={80} width={80}>
							<img
								src={window.Configs.cdnAddressDev + params.row.mainImagePath}
								alt=""
								style={{
									height: "100%", // Altezza dell'immagine al 100% del contenitore
									maxHeight: "100%", // Altezza massima dell'immagine al 100% del contenitore
									width: "auto", // Larghezza automatica per mantenere le proporzioni
									maxWidth: "100%", // Larghezza massima dell'immagine al 100% del contenitore
								}}
							/>
						</Box>
					) : null;
				},
			},
			{
				headerName: i18next.t("unique_id"),
				field: "uniqueId",
				width: 120,
			},
			{
				headerName: i18next.t("profile_description"),
				field: "descrizioneProdotto",
				flex: 1,
			},
			{
				headerName: i18next.t("item_code"),
				field: "articoloCod",
				width: 120,
			},
			{
				headerName: i18next.t("variant") + " " + 1,
				field: "keyTaglia",
				width: 120,
			},
			{
				headerName: i18next.t("variant") + " " + 2,
				field: "keyColore",
				width: 120,
			},
			{
				headerName: i18next.t("production_lot"),
				field: "lottoProduzione",
				width: 120,
			},
			SaitDateColumn({
				headerName: i18next.t("insertion_date"),
				field: "dataCreazioneObject",
				width: 150,
			}),
			SaitRowAction({
				id: "DettaglioProdotto",
				icon: <InfoIcon color="success" />,
				tooltip: i18next.t("detail"),
				onClick: (params) => {
					setSelectedObjectDetails(params.row);
				},
			}),
		],
	};

	const filters = [
		{
			label: i18next.t("unique_id"),
			name: "uniqueId",
			operator: CONTAINS,
			type: TEXTFIELD,
		},
		{
			label: i18next.t("profile_description"),
			name: "descrizioneProdotto",
			operator: CONTAINS,
			type: TEXTFIELD,
		},
		{
			label: i18next.t("profile_code"),
			name: "prodotto",
			operator: CONTAINS,
			type: TEXTFIELD,
		},
		{
			label: i18next.t("item_code"),
			type: TEXTFIELD,
			name: "articoloCod",
			operator: CONTAINS,
		},
		{
			label: i18next.t("variant") + " " + 1,
			type: TEXTFIELD,
			name: "keyTaglia",
			operator: CONTAINS,
		},
		{
			label: i18next.t("variant") + " " + 2,
			type: TEXTFIELD,
			name: "keyColore",
			operator: CONTAINS,
		},
		{
			label: i18next.t("production_lot"),
			type: TEXTFIELD,
			name: "lottoProduzione",
			operator: CONTAINS,
		},
		{
			label: i18next.t("date_from"),
			name: "dataCreazioneObject",
			operator: DATE_GREATER_EQUAL,
			type: DATEFIELD,
		},
		{
			label: i18next.t("date_to"),
			name: "dataCreazioneObject",
			operator: DATE_LESS_EQUAL,
			type: DATEFIELD,
		},
	];

	const searchWindowGridParams = {
		findFunction: "/norma/findIwswotidObjectProfile",
		orderBy: [
			{
				property: "idProfile",
				direction: "ASC",
			},
		],
		columns: [
			{
				headerName: i18next.t("profile_id"),
				field: "idProfile",
				flex: 1,
			},
			{
				headerName: i18next.t("product"),
				field: "prodotto",
				flex: 1,
			},
			{
				headerName: i18next.t("product_description_short"),
				field: "descrizioneProdotto",
				flex: 1,
			},
			{
				headerName: i18next.t("item_code"),
				field: "articoloCod",
				flex: 1,
			},
			{
				headerName: i18next.t("production_lot"),
				field: "lottoProduzione",
				flex: 1,
			},
			{
				headerName: i18next.t("variant") + " " + 1,
				field: "keyTaglia",
				flex: 1,
			},
			{
				headerName: i18next.t("variant") + " " + 2,
				field: "keyColore",
				flex: 1,
			},
		],
	};

	const searchWindowFilters = [
		{
			label: i18next.t("profile_id"),
			operator: CONTAINS,
			name: "idProfile",
			type: TEXTFIELD,
		},
		{
			label: i18next.t("item_code"),
			operator: CONTAINS,
			name: "articoloCod",
			type: TEXTFIELD,
		},
		{
			label: i18next.t("production_lot"),
			operator: CONTAINS,
			name: "lottoProduzione",
			type: TEXTFIELD,
		},
		{
			label: i18next.t("variant") + " " + 1,
			operator: CONTAINS,
			name: "keyTaglia",
			type: TEXTFIELD,
		},
		{
			label: i18next.t("variant") + " " + 2,
			operator: CONTAINS,
			name: "keyColore",
			type: TEXTFIELD,
		},
	];

	return (
		<>
			<FiltersAndGrid
				filters={filters}
				gridParams={gridParams}
				controlledFilter={reloadGrid}
				setControlledFilter={setReloadGrid}
				rowHeight={100}
				resetFilter
				autoload
			/>
			{isMaker && (
				<Box
					sx={{
						display: "flex",
						justifyContent: "center",
						mt: 2,
					}}
				>
					<TextField
						size="small"
						sx={{ width: 350 }}
						value={
							Object.keys(selectedProfile).length > 0
								? selectedProfile["idProfile"] +
								  " - " +
								  selectedProfile["descrizioneProdotto"]
								: ""
						}
						InputProps={{
							readOnly: true,
							endAdornment:
								Object.keys(selectedProfile).length > 0 ? (
									<IconButton
										onClick={() => {
											handleOnSelection({});
										}}
									>
										<ClearIcon />
									</IconButton>
								) : (
									<IconButton
										onClick={() => {
											handleOpenSearchWindow(true);
										}}
									>
										<SearchIcon />
									</IconButton>
								),
						}}
					/>
					<SearchWindow
						open={openSearchWindow}
						close={() => {
							handleOpenSearchWindow(false);
						}}
						title={i18next.t("profile_selection")}
						onSelection={handleOnSelection}
						filters={searchWindowFilters}
						gridParams={searchWindowGridParams}
					/>
					<SaitButton
						sx={{ ml: 2, color: "secondary.main" }}
						text={i18next.t("change_selected")}
						endIcon={<CompareArrowsIcon />}
						disabled={
							!(Object.keys(selectedProfile).length > 0) ||
							!(associazioneObjectsSelectedRows.length > 0)
						}
						onClick={() => {
							itemAssociationsLotto();
						}}
					/>
				</Box>
			)}
			<DettagliObject
				open={selectedObjectDetails}
				object={selectedObjectDetails}
				close={() => {
					setSelectedObjectDetails({});
				}}
			/>
		</>
	);
}
