import React from "react";
import i18next from "i18next";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { Button } from "@mui/material";

export default function SaitButtonUpload(props) {
	const { onUpload, ...buttonProps } = props;

	const handleFileChange = (event) => {
		const selectedFiles = props.multiple ? event.target.files : event.target.files[0];
		props.onUpload(selectedFiles);
		event.target.value = null;
	};

	var attr = {};
	if (props.multiple) {
		attr["multiple"] = true;
	}
	if (props.accept) {
		attr["accept"] = props.accept;
	}

	return (
		<Button
			{...buttonProps}
			variant={props.variant || "contained"}
			component="label"
			endIcon={props.endIcon || <CloudUploadIcon />}
		>
			{props.text || i18next.t("upload")}
			<input type="file" hidden onChange={handleFileChange} {...attr} />
		</Button>
	);
}
