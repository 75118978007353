import React from "react";
import { axiosFetchAllCombo } from "../../utils/AxiosUtils";

import { debounce } from "@mui/material";
import SaitCombo from "./SaitCombo";
import { STARTS_WITH } from "../../utils/FiltersOperators";
import SaitComboFilter from "./SaitComboFilter";

export default function SaitDynamicCombo(props) {
	const onChangeComboItem = (selectedRow, name, afterChange) => {
		if (!selectedRow) {
			selectedRow = {};
		}

		let newStateElement = { ...props.stateElement };
		name.forEach((item) => {
			newStateElement[item.storeName] = selectedRow[item.comboName];
		});

		props.setFunction(newStateElement);
		if (Object.keys(selectedRow).length > 0) debounceCombo(selectedRow[props.filterField]);
		if (afterChange) {
			afterChange(selectedRow);
		}
	};

	const onChangeComboFilter = (selectedRow, index) => {
		const newFilters = [...props.valueFilters];

		if (selectedRow) {
			newFilters[index].value = selectedRow;
		} else {
			newFilters[index].value = {};
		}

		props.setValueFilters(newFilters);

		if (selectedRow) debounceCombo(selectedRow[props.filterField]);
	};

	const onChangeCombo = (newInputValue) => {
		if (typeof newInputValue === "undefined" || newInputValue.length === 0) {
			props.setItems([]);
		} else {
			updateCombo(newInputValue);
		}
	};

	const debounceCombo = debounce(onChangeCombo, 300);

	const updateCombo = (newInputValue) => {
		const comboQuery = [
			{
				findFunction: props.findParameters
					? props.findParameters.findFunction
					: props.findFunction,
				orderBy: [{ property: props.filterField, direction: "ASC" }],
				findFilters: [
					{
						field: props.filterField,
						operator: STARTS_WITH,
						value: newInputValue,
					},
				],
				setItems: props.setItems,
			},
		];

		axiosFetchAllCombo(comboQuery);
	};

	return props.filterCombo ? (
		<SaitComboFilter
			{...props}
			key={props.index}
			label={props.label}
			value={props.value}
			name={props.name}
			onChange={(row) => onChangeComboFilter(row, props.index)}
			disabled={props.disabled}
			size={props.size}
			description={props.description}
			findParameters={props.findParameters}
			items={props.items}
			required={props.required}
			filterOptions={(x) => x}
			onInputChange={(event, newInputValue) => debounceCombo(newInputValue)}
		/>
	) : (
		<SaitCombo
			{...props}
			key={props.index}
			label={props.label}
			value={props.stateElement}
			name={props.name}
			onChange={(row) => onChangeComboItem(row, props.name, props.afterChange)}
			disabled={props.disabled}
			size={props.size}
			description={props.description}
			findParameters={props.findParameters}
			items={props.items}
			required={props.required}
			filterOptions={(x) => x}
			onInputChange={(event, newInputValue) => debounceCombo(newInputValue)}
		/>
	);
}
