import React from "react";
import i18next from "i18next";
import { useDispatch } from "react-redux";
import { TEXTFIELD } from "../../../utils/ComponentList";
import EditWindow from "../../../components/template/EditWindow";
import { standardAxiosPost } from "../../../utils/AxiosUtils";

export default function NuovoBrand(props) {
	const dispatch = useDispatch();
	const onClose = () => {
		props.close();
	};

	const onSave = async () => {
		await standardAxiosPost({
			dispatch: dispatch,
			procedureName: "brand/createBrand",
			parameters: props.brand,
			onSuccess: (response) => {
				props.setControlledFilter(true);
				onClose();
			},
		});
	};

	const fields = [
		{
			items: [
				{
					label: i18next.t("brand"),
					name: "name",
					type: TEXTFIELD,
					required: true,
				},
				{
					label: i18next.t("app_name"),
					name: "appName",
					type: TEXTFIELD,
					required: true,
				},
			],
		},
	];
	return (
		<>
			<EditWindow
				title={i18next.t("new") + " " + i18next.t("brand")}
				open={props.open}
				close={() => onClose()}
				width={"sm"}
				fields={fields}
				stateElement={props.brand}
				setFunction={props.setBrand}
				onSave={onSave}
			/>
		</>
	);
}
