import React from "react";
import { TextField } from "@mui/material";
import {
	DesktopDatePicker,
	DesktopDateTimePicker,
	LocalizationProvider,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

export default function SaitDateField(props) {
	return (
		<LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="it">
			{props.time ? (
				<DesktopDateTimePicker
					label={props.label}
					inputFormat={props.inputFormat || "DD/MM/YYYY HH:mm"}
					disabled={props.disabled}
					value={props.value}
					onChange={(newDate) => props.onChange(newDate)}
					views={props.views || null}
					openTo={props.openTo || "day"}
					disablePast={props.disablePast || false}
					renderInput={(params) => (
						<TextField
							{...params}
							error={props.error}
							style={props.style}
							sx={props.sx}
							inputProps={{
								...params.inputProps,
								placeholder: "gg/mm/aaaa hh:mm",
							}}
							size={props.size}
							required={props.required}
							onKeyDown={(event) => props.onKeyDown?.(event)}
						/>
					)}
				/>
			) : (
				<DesktopDatePicker
					label={props.label}
					inputFormat={props.inputFormat || "DD/MM/YYYY"}
					disabled={props.disabled}
					value={props.value}
					onChange={(newDate) => props.onChange(newDate)}
					views={props.views || null}
					openTo={props.openTo || "day"}
					disablePast={props.disablePast || false}
					renderInput={(params) => (
						<TextField
							{...params}
							error={props.error}
							style={props.style}
							sx={props.sx}
							inputProps={{
								...params.inputProps,
								placeholder: props.placeholder || "gg/mm/aaaa",
							}}
							size={props.size}
							required={props.required}
							onKeyDown={(event) => props.onKeyDown?.(event)}
						/>
					)}
				/>
			)}
		</LocalizationProvider>
	);
}
