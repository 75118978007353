import React from "react";
import { useDispatch, useSelector } from "react-redux";
import i18next from "i18next";
import SaitDialog from "../atomic/SaitDialog";
import SaitTextField from "../atomic/SaitTextField";
import SaitButton from "../atomic/SaitButton";
import { Fragment, useEffect, useRef } from "react";
import { setGlobalError } from "../../store/features/globalMessageReducer";
import { checkOpen } from "../../utils/SaitUtils";
import { Box } from "@mui/material";

export default function NomeFileExcel(props) {
	const dispatch = useDispatch();
	const module = useSelector((state) => state["module"]["module"]);

	const inputRef = useRef(null);

	const [nome, setNome] = React.useState("JSWS_Export_Excel");

	useEffect(() => {
		if (checkOpen(props.open)) {
			setTimeout(() => {
				inputRef.current.focus();
			}, 0);
		}
	}, [props.open]);

	const onConfirm = async () => {
		if (nome) {
			await props.exportFunction(dispatch, nome, props.exportParams);
			props.close();
			setNome("JSWS_Export_Excel");
		} else {
			dispatch(
				setGlobalError({
					module: module,
					errorMessage: i18next.t("error_enter_all_required_fields"),
				})
			);
		}
	};

	return (
		<SaitDialog
			open={props.open}
			title={i18next.t("excel_export")}
			width="xs"
			content={
				<Fragment>
					<SaitTextField
						inputRef={inputRef}
						value={nome}
						onChange={(e) => setNome(e.target.value)}
						size="large"
						required
						label={i18next.t("file_name")}
					/>
				</Fragment>
			}
			actions={
				<Fragment>
					<SaitButton text={i18next.t("cancel")} color="error" onClick={props.close} />
					<Box sx={{ flexGrow: 1 }} />
					<SaitButton text={i18next.t("confirm")} color="success" onClick={onConfirm} />
				</Fragment>
			}
		/>
	);
}
