import React from "react";
import { InputAdornment, TextField } from "@mui/material";
import FontDownloadIcon from "@mui/icons-material/FontDownload";

export default function SaitTextField(props) {
	const { noAutocomplete, password, ...textProps } = props;
	const defaultSize = "small";

	const [isCapsLockOn, setIsCapsLockOn] = React.useState(false);

	const checkCapsLock = (event) => {
		if (event.getModifierState("CapsLock")) {
			setIsCapsLockOn(true);
		} else {
			setIsCapsLockOn(false);
		}
	};

	return (
		<TextField
			{...textProps}
			type={textProps.type ? textProps.type : password ? "password" : "text"}
			size={textProps.size || defaultSize}
			onKeyDown={(event) =>
				textProps.onKeyDown
					? textProps.onKeyDown(event)
					: password
					? checkCapsLock(event)
					: null
			}
			inputProps={{
				maxLength: textProps.maxLength,
				autoComplete: noAutocomplete ? "new-password" : null,
			}}
			InputProps={{
				endAdornment: textProps.endadornment,
				startAdornment: textProps.startadornment,
				...(password && isCapsLockOn
					? {
							endAdornment: (
								<InputAdornment position="end">
									<FontDownloadIcon />
								</InputAdornment>
							),
					  }
					: {}), // Un oggetto vuoto se la condizione non è soddisfatta
			}}
			variant={textProps.variant || (textProps.disabled ? "filled" : "outlined")}
			sx={{
				...textProps.sx,
				"& .Mui-disabled": {
					color: "#303030",
				},
				"& .MuiInputBase-input.Mui-disabled": {
					WebkitTextFillColor: "#151515",
				},
			}}
		/>
	);
}
