import React, { Fragment } from "react";
import { createPortal } from "react-dom";

import SaitButton from "./SaitButton";
import i18next from "../../localization/i18n";
import SaitDialog from "./SaitDialog";
import { useDispatch, useSelector } from "react-redux";
import { unsetGlobalError } from "../../store/features/globalMessageReducer";
import { Box, Typography } from "@mui/material";

const SaitGlobalMessage = (props) => {
	const dispatch = useDispatch();

	const globalMessage = useSelector((state) => state["globalMessage"]["globalMessage"]);

	const currentMessage = globalMessage.find((obj) => obj.module === props.module);

	return createPortal(
		<SaitDialog
			open={currentMessage}
			title={
				currentMessage?.title
					? currentMessage?.title
					: currentMessage?.errorMessage
					? i18next.t("error")
					: null
			}
			titleColor={currentMessage?.errorMessage ? "red" : null}
			width="xs"
			content={
				<Typography align="center">
					{currentMessage?.errorMessage || currentMessage?.message}
				</Typography>
			}
			actions={
				<Fragment>
					<Box sx={{ flexGrow: 1 }} />
					<SaitButton
						sx={{ color: "secondary.main" }}
						text={i18next.t("close")}
						onClick={() => dispatch(unsetGlobalError())}
					/>
					<Box sx={{ flexGrow: 1 }} />
				</Fragment>
			}
		/>,
		document.body
	);
};

export default SaitGlobalMessage;
