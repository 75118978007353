import React from "react";
import Checkbox from "@mui/material/Checkbox";
import { FormControlLabel } from "@mui/material";

export default function SaitCheckbox(props) {
	const { afterChange, value, ...checkboxProps } = props;

	const handleOnChange = () => {
		let newValue;
		if (props.value === "Y") {
			newValue = "N";
		} else if (props.value === "N" || props.value === null || props.value === undefined) {
			newValue = "Y";
		} else {
			newValue = !props.value;
		}
		props.onChange(newValue);
	};
	return (
		<FormControlLabel
			{...checkboxProps}
			control={<Checkbox />}
			checked={props.value === true || props.value === "Y" ? true : false}
			onChange={handleOnChange}
		/>
	);
}
