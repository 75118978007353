import React from "react";
import { useDispatch } from "react-redux";
import { setUserPreferences, unsetUserPreferences } from "../../store/features/authReducer";
import LoadingButton from "@mui/lab/LoadingButton";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import axiosInstance from "../../AxiosConfig";
import { useTranslation } from "react-i18next";
import { InputAdornment } from "@mui/material";
import FontDownloadIcon from "@mui/icons-material/FontDownload";
import { setMenuOpen } from "../../store/features/generalConfigReducer";
import { changeLocalization, browserLocalization } from "../../localization/i18n";

function Copyright(props) {
	return window.Configs.copyrightText ? (
		<Typography
			component="pre"
			variant="body2"
			color="text.secondary"
			align="center"
			{...props}
		>
			{window.Configs.copyrightText}
			<Link
				color="inherit"
				href={window.Configs.copyrightWeb}
				underline="none"
				sx={{ color: window.Configs.primaryMain }}
			>
				{window.Configs.copyrightLinkText}
			</Link>
		</Typography>
	) : (
		<Typography variant="body2" color="text.secondary" align="center" {...props}>
			<Link color="inherit" href="https://1trueid.net/">
				1TrueID S.R.L.
			</Link>
		</Typography>
	);
}

export default function LogIn(props) {
	const dispatch = useDispatch();

	const [loading, setLoading] = React.useState(false);
	const [error, setError] = React.useState(false);

	const { t } = useTranslation();

	const [isCapsLockOn, setIsCapsLockOn] = React.useState(false);

	// This function is triggered on the keyup event
	const checkCapsLock = (event) => {
		if (event.getModifierState("CapsLock")) {
			setIsCapsLockOn(true);
		} else {
			setIsCapsLockOn(false);
		}
	};

	const handleSubmit = async (event) => {
		event.preventDefault();
		dispatch(setMenuOpen(false));
		const data = new FormData(event.currentTarget);
		const username = data.get("username");
		const password = data.get("password");
		if (username && password) {
			setLoading(true);

			try {
				const response = await axiosInstance.post("oauth/token", {
					username: username,
					password: password,
					grant_type: "password",
					client_id: "maker",
					client_secret: "maker",
				});
				if (response?.data?.access_token) {
					const roles = response.data.scope;
					// controllo che l'utenza connessa sia associata a un maker
					let isMaker = false;
					roles.forEach((role) => {
						if (role === "maker") {
							isMaker = true;
							return;
						}
					});
					response.data.username = username;
					response.data.isMaker = isMaker;
					dispatch(setUserPreferences(response.data));
					changeLocalization(response.data.userPreferences?.linguaIso);
				} else {
					setLoading(false);
					setError(true);
					dispatch(unsetUserPreferences());
					browserLocalization();
				}
			} catch (error) {
				setLoading(false);
				setError(true);
				dispatch(unsetUserPreferences());
				browserLocalization();
			}
		}
	};

	return (
		<div
			style={{
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
				height: "100vh",
				width: "100%",
				backgroundColor: window.Configs.backgroundImg
					? null
					: window.Configs.homeAppbarMain,
				backgroundImage: window.Configs.backgroundImg
					? `url(${process.env.PUBLIC_URL + "/" + window.Configs.backgroundImg})`
					: null,
				backgroundSize: "cover",
				backgroundPosition: "center",
			}}
		>
			<Container
				component="main"
				maxWidth="xs"
				style={{
					background: "#f2f6fc",
					height: "600px",
					borderRadius: "5px",
				}}
				sx={{ boxShadow: 23 }}
			>
				<CssBaseline />
				<Box
					sx={{
						marginTop: 2,
						display: "flex",
						flexDirection: "column",
						alignItems: "center",
					}}
				>
					<Box m={5}>
						<img
							src={process.env.PUBLIC_URL + "/logo-login.png"}
							height={
								window.Configs.loginLogoHeight
									? window.Configs.loginLogoHeight
									: null
							}
							alt="logo"
						/>
					</Box>
					<Typography component="h1" variant="h5">
						{t(window.Configs.welcomeMsgKey ? window.Configs.welcomeMsgKey : "sign_in")}
					</Typography>
					{error && (
						<Typography component="h2" variant="h6" style={{ color: "red" }}>
							{t("error_during_login")}
						</Typography>
					)}
					<Box
						component="form"
						onSubmit={handleSubmit}
						noValidate
						sx={{ mt: error ? 1 : 4.4 }}
					>
						<TextField
							margin="normal"
							required
							fullWidth
							id="username"
							label={t("username")}
							name="username"
							autoComplete="username"
							autoFocus
							sx={{
								"& .MuiOutlinedInput-root": {
									"&.Mui-focused fieldset": {
										borderColor: window.Configs.primaryMain || "#297dea",
									},
								},
							}}
						/>
						<TextField
							margin="normal"
							sx={{
								"& .MuiOutlinedInput-root": {
									"&.Mui-focused fieldset": {
										borderColor: window.Configs.primaryMain || "#297dea",
									},
								},
							}}
							required
							fullWidth
							name="password"
							label={t("password")}
							type="password"
							id="password"
							autoComplete="current-password"
							InputProps={
								isCapsLockOn
									? {
											endAdornment: (
												<InputAdornment position="end">
													<FontDownloadIcon />
												</InputAdornment>
											),
									  }
									: {} // Un oggetto vuoto se la condizione non è soddisfatta
							}
							onKeyDown={checkCapsLock}
						/>
						<LoadingButton
							type="submit"
							fullWidth
							variant="contained"
							loading={loading}
							sx={{
								mt: 3,
								mb: 2,
								borderRadius: "5px",
								color: window.Configs.loginButtonText,
							}}
						>
							{t("sign_in")}
						</LoadingButton>
					</Box>
				</Box>
				<Copyright sx={{ mt: 8, mb: 4 }} />
			</Container>
		</div>
	);
}
