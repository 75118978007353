import React, { Fragment, useEffect } from "react";
import { IconButton } from "@mui/material";
import SaitTextField from "./SaitTextField";
import SearchIcon from "@mui/icons-material/Search";
import { formatLabel } from "../../utils/SaitUtils";
import ClearIcon from "@mui/icons-material/Clear";

export default function SaitSearchTextField(props) {
	const [openSearchWindow, setOpenSearchWindow] = React.useState(false);
	const [formattedValue, setFormattedValue] = React.useState("");

	const { name, searchWindow, filterSearchWindow, noClearable, ...rest } = props;

	useEffect(() => {
		let valueDescription = "";

		if (filterSearchWindow) {
			if (props.value[name[0].gridName] || props.value[name[0].gridName] === 0) {
				valueDescription = formatLabel(props.description, props.value);
			}
		} else {
			if (props.value[name[0].storeName] || props.value[name[0].storeName] === 0) {
				valueDescription = formatLabel(props.description, props.value);
			}
		}

		setFormattedValue(valueDescription);
	}, [props.description, props.value, name, filterSearchWindow]);

	const handleOnSave = () => {
		props.onChange({});
	};

	return (
		<Fragment>
			<SaitTextField
				{...rest}
				value={formattedValue}
				width={600}
				disabled={true}
				variant="outlined"
				endadornment={
					formattedValue && !noClearable ? (
						<IconButton onClick={handleOnSave}>
							<ClearIcon />
						</IconButton>
					) : (
						<IconButton
							onClick={() => setOpenSearchWindow(!openSearchWindow)}
							disabled={props.disabled}
						>
							<SearchIcon />
						</IconButton>
					)
				}
			/>
			{searchWindow(
				openSearchWindow,
				() => setOpenSearchWindow(false),
				props.label,
				props.onChange
			)}
		</Fragment>
	);
}
