import React from "react";
import "./LoadingPage.css";

export default function LoadingPage() {
	return (
		<div className="Loading-page">
			<header className="Loading-page-header">
				<img
					src={process.env.PUBLIC_URL + "/logo-loading-page.png"}
					className="Loading-page-logo"
					alt="logo"
				/>
			</header>
		</div>
	);
}
