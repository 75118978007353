import React, { useEffect } from "react";
import TextField from "@mui/material/TextField";
import CircularProgress from "@mui/material/CircularProgress";
import { axiosGet } from "../../utils/AxiosUtils";
import Autocomplete from "@mui/material/Autocomplete";

import { formatLabel } from "../../utils/SaitUtils";
import { Checkbox, InputAdornment } from "@mui/material";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

export default function SaitComboFilter(props) {
	const defaultSize = "small";

	const [itemsCombo, setItemsCombo] = React.useState([]);
	const [loadingFind, setLoadingFind] = React.useState(false);

	useEffect(() => {
		setItemsForAutocomplete(props.items);
		// eslint-disable-next-line
	}, [JSON.stringify(props.items)]);

	/////// FIND A DB ///////
	useEffect(() => {
		if (props.findParameters && !props.disabled) {
			setLoadingFind(true);
		}
		// eslint-disable-next-line
	}, [JSON.stringify(props.findParameters), props.disabled]);

	useEffect(() => {
		if (loadingFind) {
			getItemsFindFunction();
		}
		// eslint-disable-next-line
	}, [loadingFind]);

	const getItemsFindFunction = async () => {
		const resp = await axiosGet(
			props.findParameters.findFunction,
			props.findParameters.findFilters,
			props.findParameters.orderBy
		);
		setItemsForAutocomplete(resp.rows);
		setLoadingFind(false);
	};
	////////////////////////////

	const setItemsForAutocomplete = (items) => {
		let autoCompleteArray = [];
		items?.forEach((item) => {
			let labelDescription = formatLabel(props.description, item);

			item.customOptionLabel = labelDescription;

			autoCompleteArray.push(item);
		});
		setItemsCombo(autoCompleteArray);
	};

	const handleOnChange = (newValue) => {
		props.onChange(newValue);
	};

	const getOptionLabel = (option) => {
		if (option.customOptionLabel) {
			return option.customOptionLabel;
		} else {
			const item = itemsCombo.find((row) => {
				return props.name.every((obj) => {
					return row[obj.comboName] === props.value[obj.comboName];
				});
			});
			return item ? item.customOptionLabel : "";
		}
	};

	return (
		<Autocomplete
			options={itemsCombo}
			sx={{
				...props.sx,
			}}
			size={props.size || defaultSize}
			disabled={
				(!props.findParameters && props.items.length === 0 && !props.filterOptions) ||
				loadingFind ||
				props.disabled
			}
			value={
				props.multiple
					? props.value?.[0]?.[props.name[0].comboName] ||
					  props.value?.[0]?.[props.name[0].comboName] === 0
						? props.value
						: []
					: props.value[props.name[0].comboName] ||
					  props.value[props.name[0].comboName] === 0
					? props.value
					: null
			}
			onChange={(event, newValue) => handleOnChange(newValue)}
			isOptionEqualToValue={(option, value) => {
				return props.name.every(
					(combo) => option[combo.comboName] === value[combo.comboName]
				);
			}}
			getOptionLabel={getOptionLabel}
			filterOptions={props.filterOptions}
			onInputChange={props.onInputChange}
			multiple={props.multiple || false}
			limitTags={props.limitTags || 1}
			disableCloseOnSelect={props.multiple ? true : false}
			renderInput={(params) =>
				((!props.findParameters && props.items.length === 0) || loadingFind) &&
				!props.disabled &&
				!props.filterOptions ? (
					// Se sto caricando i record da DB metto un textfield con un CircularProgress
					<TextField
						{...params}
						label={props.label}
						size={props.size || defaultSize}
						variant={props.disabled ? "filled" : "outlined"}
						InputProps={{
							startAdornment: (
								<InputAdornment position="start">
									<CircularProgress size={30} />
								</InputAdornment>
							),
						}}
					/>
				) : (
					<TextField
						{...params}
						required={props.required}
						label={props.label}
						error={props.error}
						size={props.size || defaultSize}
						variant={props.disabled ? "filled" : "outlined"}
						sx={{
							position: "relative",
						}}
					/>
				)
			}
			renderOption={(renderProps, option, { selected }) => (
				<li {...renderProps}>
					{props.multiple && (
						<Checkbox
							icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
							checkedIcon={<CheckBoxIcon fontSize="small" />}
							checked={selected}
						/>
					)}

					{getOptionLabel(option)}
				</li>
			)}
		/>
	);
}
