import React, { useEffect } from "react";
import i18next from "i18next";
import CancelIcon from "@mui/icons-material/Cancel";
import { Box } from "@mui/material";
import SaitButton from "../../components/atomic/SaitButton";
import SaitDateField from "../../components/atomic/SaitDateField";
import SaitDialog from "../../components/atomic/SaitDialog";
import { standardAxiosGet } from "../../utils/AxiosUtils";
import { useDispatch } from "react-redux";
import { CONTAINS, DATE_GREATER_EQUAL, DATE_LESS } from "../../utils/FiltersOperators";
import Mappa from "./Mappa";
import dayjs from "dayjs";
import { getFirstDayOfMonth, getLastDayOfMonth } from "../../utils/SaitUtils";
import SearchIcon from "@mui/icons-material/Search";

export default function WrapperMappaProfile(props) {
	/*************
	 *	DICHIARAZIONI   *
	 **************/

	const dispatch = useDispatch();
	const defaultItaly = {
		lat: 41.9028,
		lng: 12.4964,
	};

	/*************
	 *	MODEL   *
	 **************/

	/*************
	 *	  STATI    *
	 **************/

	const [dateFrom, setDateFrom] = React.useState(getFirstDayOfMonth());
	const [dateTo, setDateTo] = React.useState(getLastDayOfMonth());
	const [items, setItems] = React.useState([]);
	const [browserPosition, setBrowserPosition] = React.useState(defaultItaly);

	/*************
	 *	LISTENER   *
	 **************/

	useEffect(() => {
		if (props.open) {
			if (navigator.geolocation) {
				navigator.geolocation.getCurrentPosition((position) => {
					const pos = {
						lat: position.coords.latitude,
						lng: position.coords.longitude,
					};
					setBrowserPosition(pos);
				});

				getItems();
			}
		}
		// eslint-disable-next-line
	}, [props.open]);

	/*************
	 *	  GRID    *
	 **************/

	/*************
	 *	  FILTERS    *
	 **************/

	/*************
	 *	  BUTTONS    *
	 **************/

	/*************
	 *	FUNCTIONS   *
	 **************/

	const getItems = async () => {
		let resp = await standardAxiosGet({
			dispatch: dispatch,
			findFunction: "/product/getObjectsByIdProfileForCombo",
			jsonFilter: [
				{
					operator: CONTAINS,
					field: "idProfile",
					value: props.idProfile,
				},
				{
					operator: DATE_GREATER_EQUAL,
					field: "dateFrom",
					value: dateFrom,
				},
				{
					operator: DATE_LESS,
					field: "dateTo",
					value: dayjs(dateTo).set("date", dayjs(dateTo).get("date") + 1),
				},
			],
			orderBy: [{ property: "uid", direction: "ASC" }],
		});
		setItems(resp.rows);
	};

	/*************
	 *	  HTML     *
	 **************/

	return (
		<>
			<SaitDialog
				open={props.open}
				title={i18next.t("map")}
				width="lg"
				content={
					<>
						<Box sx={{ flexGrow: 1, display: "flex", justifyContent: "center", pb: 2 }}>
							<SaitDateField
								label={i18next.t("date_from")}
								value={dateFrom}
								onChange={(date) => {
									setDateFrom(date);
								}}
								onKeyDown={(event) => event.key === "Enter" && getItems()}
							/>
							<Box sx={{ padding: 1 }}></Box>
							<SaitDateField
								label={i18next.t("date_to")}
								value={dateTo}
								onChange={(date) => setDateTo(date)}
								onKeyDown={(event) => event.key === "Enter" && getItems()}
							/>
							<Box sx={{ padding: 1 }}></Box>
							<SaitButton
								endIcon={<SearchIcon />}
								text={i18next.t("search_v2")}
								sx={{ color: "secondary.main" }}
								onClick={() => {
									getItems();
								}}
							/>
						</Box>
						<Mappa items={items} position={browserPosition} />
					</>
				}
				actions={
					<SaitButton
						text={i18next.t("close")}
						color="error"
						endIcon={<CancelIcon />}
						onClick={() => {
							props.close();
							setItems([]);
							setDateFrom(getFirstDayOfMonth());
							setDateTo(getLastDayOfMonth());
							setBrowserPosition(defaultItaly);
						}}
					/>
				}
			/>
		</>
	);
}
