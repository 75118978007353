import React from "react";
import LoadingButton from "@mui/lab/LoadingButton";
import { Button, CircularProgress } from "@mui/material";

export default function SaitButton(props) {
	const { loading, ...buttonProps } = props;

	return props.icon ? (
		<Button
			{...buttonProps}
			variant={props.variant || "contained"}
			color={props.color || "primary"}
			disabled={props.disabled || false}
			sx={props.sx || {}}
		>
			{props.loading ? <CircularProgress size={20} color="inherit" /> : props.icon}
			{props.children}
		</Button>
	) : (
		<LoadingButton
			{...props}
			loadingPosition={props.startIcon ? "start" : props.endIcon ? "end" : "center"}
			variant={props.variant || "contained"}
			color={props.color || "primary"}
		>
			<span>{props.text}</span>
			{props.children}
		</LoadingButton>
	);
}
