import React, { useEffect, useMemo } from "react";
import i18next from "i18next";
import { useDispatch, useSelector } from "react-redux";
import {
	BUTTON,
	CHECKBOX,
	COMBO,
	GRID,
	IMAGE_BOX,
	TAB,
	TEXTAREA,
	TEXTFIELD,
} from "../../../utils/ComponentList";
import { axiosFetchAllCombo, axiosPost, standardAxiosPost } from "../../../utils/AxiosUtils";
import { BUTTON_UPLOAD } from "../../../utils/ButtonTypes";
import { checkOpen } from "../../../utils/SaitUtils";
import AsyncFileReader from "../../../utils/AsyncFileReader";
import axiosInstance from "../../../AxiosConfig";
import { activateLoading, deactivateLoading } from "../../../store/features/globalLoadingReducer";
import { setGlobalError } from "../../../store/features/globalMessageReducer";
import SaitDialog from "../../../components/atomic/SaitDialog";
import EditPanel from "../../../components/template/EditPanel";
import SaitButton from "../../../components/atomic/SaitButton";
import CancelIcon from "@mui/icons-material/Cancel";
import SaveIcon from "@mui/icons-material/Save";
import Box from "@mui/material/Box";
import { CONTAINS, EQUAL } from "../../../utils/FiltersOperators";
import SaitRowActionDelete from "../../../components/atomic/Columns/SaitRowActionDelete";
import SaitRowActionEdit from "../../../components/atomic/Columns/SaitRowActionEdit";
import { TextField } from "@mui/material";
import NuovaAssociazioneTemplate from "./NuovaAssociazioneTemplate/NuovaAssociazioneTemplate";
import EditAssociazioneTemplate from "./EditAssociazioneTemplate/EditAssociazioneTemplate";

export default function NuovoProfilo(props) {
	const dispatch = useDispatch();
	const module = useSelector((state) => state["module"]["module"]);
	const token = useSelector((state) => state["auth"]["token"]);
	const [attemptSave, setAttemptSave] = React.useState(false);
	const [selectedMainImage, setSelectedMainImage] = React.useState(null);
	const [mainImageUrl, setMainImageUrl] = React.useState(null);
	const [mainImageArrayBuffer, setMainImageArrayBuffer] = React.useState(null);
	const [selectedTechImage, setSelectedTechImage] = React.useState(null);
	const [techImageUrl, setTechImageUrl] = React.useState(null);
	const [techImageArrayBuffer, setTechImageArrayBuffer] = React.useState(null);
	const [itemsOtidMarchi, setItemsOtidMarchi] = React.useState([]);
	const [itemsIwswdnormaMaker, setItemsIwswdnormaMaker] = React.useState([]);
	const [itemsIwswdnormaTemplate, setItemsIwswdnormaTemplate] = React.useState([]);
	const [requiredFields, setRequiredFields] = React.useState([]);
	const [openNewAssignment, setOpenNewAssignment] = React.useState(false);
	const [reloadTemplatesGrid, setReloadTemplatesGrid] = React.useState(false);
	const [selectedAssignment, setSelectedAssignment] = React.useState({});

	const fields = [
		{
			title: i18next.t("profile_details"),
			type: TAB,
			items: [
				{
					title: i18next.t("profile_details"),
					items: [
						{
							label: i18next.t("profile"),
							name: "descrizioneProdotto",
							type: TEXTFIELD,
							required: true,
							maxLength: 200,
						},
						{
							label: i18next.t("product"),
							name: "prodotto",
							type: TEXTFIELD,
							required: true,
							maxLength: 40,
						},
						{
							label: i18next.t("brand"),
							name: [{ storeName: "marchio", comboName: "name" }],
							type: COMBO,
							description: "$(name)",
							items: itemsOtidMarchi,
							required: true,
						},
						{
							label: i18next.t("item_catalogue"),
							name: "articoloArchivio",
							type: TEXTFIELD,
							required: true,
							maxLength: 8,
						},
						{
							label: i18next.t("item_code"),
							name: "articoloCod",
							type: TEXTFIELD,
							required: true,
							maxLength: 40,
						},
						{
							label: i18next.t("variant") + " " + 1,
							name: "keyTaglia",
							type: TEXTFIELD,
							required: true,
							maxLength: 20,
						},
						{
							label: i18next.t("variant") + " " + 2,
							name: "keyColore",
							type: TEXTFIELD,
							required: true,
							maxLength: 20,
						},
						{
							label: i18next.t("production_lot"),
							name: "lottoProduzione",
							type: TEXTFIELD,
							required: true,
							maxLength: 40,
						},
						{
							label: i18next.t("use_regulation_redirect_url"),
							type: CHECKBOX,
							name: "flRegulationUrl",
							afterChange: (value) => {
								let newValue = "N";
								if (value === "Y" || value === true) newValue = "Y";

								props.setProfile((prevState) => ({
									...prevState,
									flRegulationUrl: newValue,
									redirectUrl: "",
								}));
							},
						},
						{
							label: i18next.t("redirect_url"),
							name: "redirectUrl",
							disabled: props.profile.flRegulationUrl === "Y",
							required: !(props.profile.flRegulationUrl === "Y"),
							type: TEXTFIELD,
							maxLength: 4000,
							width: 250,
						},
					],
				},
				{
					title: i18next.t("main_image"),
					items: [
						{
							type: IMAGE_BOX,
							imageUrl:
								mainImageUrl ||
								(props.profile.mainImagePath &&
									window.Configs.cdnAddressDev + props.profile.mainImagePath),
							alt: "mainImage",
						},
						{
							type: BUTTON_UPLOAD,
							text: i18next.t("upload_image"),
							disabled: props.profile.mainImagePath ? true : false,
							accept: "image/*",
							onUpload: async (uploadedFiles) => {
								setSelectedMainImage(uploadedFiles);
								let afs = new AsyncFileReader([uploadedFiles]);
								const mainImage = {
									arrayBuffer: (await afs.readFile().asArrayBuffer())[0],
								};
								if (mainImage.arrayBuffer)
									setMainImageArrayBuffer(mainImage.arrayBuffer);
							},
						},
						{
							type: BUTTON,
							label: i18next.t("remove_image"),
							variant: "text",
							onClick: async () => {
								// se a db ho un'immagine
								if (props.profile.mainImagePath) {
									await deleteImage("deleteMainImage", "mainImagePath");
								} // se non ho caricato l'immagine a db ma solo lato client
								else removeMainImage();
							},
						},
					],
				},
			],
		},
		{
			title: i18next.t("profile_description"),
			type: TAB,
			items: [
				{
					title: i18next.t("profile_description"),
					items: [
						{
							label: i18next.t("profile_description"),
							name: "descrizioneCommLingua1", // COLUMN = DESCRIZIONE_COMM_LINGUA1
							type: TEXTAREA,
							minRows: 4,
							required: true,
							maxLength: 2000,
						},
					],
				},
			],
		},
		{
			title: i18next.t("tech_image"),
			type: TAB,
			items: [
				{
					title: i18next.t("tech_image"),
					items: [
						{
							type: IMAGE_BOX,
							imageUrl:
								techImageUrl ||
								(props.profile.technicalImagePath &&
									window.Configs.cdnAddressDev +
										props.profile.technicalImagePath),
							alt: "techImage",
						},
						{
							type: BUTTON_UPLOAD,
							text: i18next.t("upload_image"),
							disabled: props.profile.technicalImagePath ? true : false,
							accept: "image/*",
							onUpload: async (uploadedFiles) => {
								setSelectedTechImage(uploadedFiles);
								let afs = new AsyncFileReader([uploadedFiles]);
								const techImage = {
									arrayBuffer: (await afs.readFile().asArrayBuffer())[0],
								};
								if (techImage.arrayBuffer)
									setTechImageArrayBuffer(techImage.arrayBuffer);
							},
						},
						{
							type: BUTTON,
							label: i18next.t("remove_image"),
							variant: "text",
							onClick: async () => {
								if (props.profile.technicalImagePath) {
									await deleteImage("deleteTechnicalImage", "technicalImagePath");
								} // se non ho caricato l'immagine a db ma solo lato client
								else removeTechImage();
							},
						},
					],
				},
			],
		},
		{
			title: i18next.t("technical_description"),
			type: TAB,
			items: [
				{
					title: i18next.t("technical_description"),
					items: [
						{
							label: i18next.t("technical_description"),
							name: "descrizioneTecLingua1", // COLUMN = DESCRIZIONE_TEC_LINGUA1
							type: TEXTAREA,
							minRows: 4,
							required: true,
							maxLength: 2000,
						},
					],
				},
			],
		},
		{
			title: i18next.t("templates_assigned"),
			type: TAB,
			hidden: props.newProfile,
			items: [
				{
					title: i18next.t("templates_assigned"),
					items: [
						{
							type: GRID,
							height: 250,
							autoload: true,
							inLineButtonFilter: true,
							controlledFilter: reloadTemplatesGrid,
							setControlledFilter: setReloadTemplatesGrid,
							topButtons: [
								{
									text: i18next.t("assign"),
									variant: "text",
									function: () => {
										setOpenNewAssignment(true);
									},
								},
							],
							gridParams: {
								findFunction: "norma/findIwswdnormaProfileTemplates",
								orderBy: [{ property: "idTemplate", direction: "ASC" }],
								columns: [
									// {
									// 	headerName: i18next.t("regulation_id"),
									// 	field: "idTipoNorma",
									// 	width: 100,
									// },
									{
										headerName: i18next.t("regulation_description"),
										field: "descNormativa",
										flex: 1,
									},
									{
										headerName: i18next.t("template_description"),
										field: "descrizioneTemplate",
										flex: 1,
									},
									{
										headerName: i18next.t("seq_nr_short"),
										field: "seqTemplate",
										width: 100,
									},
									{
										headerName: i18next.t("profile_description"),
										field: "descrizioneProdotto",
										width: 120,
									},
									{
										headerName: i18next.t("item_code"),
										field: "articoloCod",
										width: 120,
									},
									{
										headerName: i18next.t("variant") + " " + 1,
										field: "keyTaglia",
										width: 120,
									},
									{
										headerName: i18next.t("variant") + " " + 2,
										field: "keyColore",
										width: 120,
									},
									{
										headerName: i18next.t("production_lot"),
										field: "lottoProduzione",
										width: 120,
									},
									SaitRowActionEdit({
										id: "EditAssociazione",
										onClick: (params) => {
											fetchData(params.row.idProfileTemplate);
										},
									}),
									SaitRowActionDelete({
										id: "EliminaAssociazione",
										description: (params) => {
											return (
												"template " +
												params.row.descrizioneTemplate +
												" (" +
												i18next.t("regulation") +
												": " +
												params.row.descNormativa +
												")"
											);
										},
										onConfirm: (params) => {
											deleteAssignedTemplate(
												params.row.idProfileTemplate,
												params.row.idProfile
											);
										},
									}),
								],
							},
							filters: [
								{
									operator: EQUAL,
									name: "idProfile",
									defaultValue: props.profile.idProfile,
								},
								{
									label: i18next.t("regulation"),
									name: [{ storeName: "idTipoNorma", comboName: "idTipoNorma" }],
									type: COMBO,
									description: "$(idTipoNorma) - $(descNormativa)",
									items: itemsIwswdnormaMaker,
									operator: EQUAL,
								},
								{
									label: i18next.t("template"),
									name: [{ storeName: "idTemplate", comboName: "idTemplate" }],
									type: COMBO,
									description: "$(descNormativa) - $(descrizioneTemplate)",
									items: itemsIwswdnormaTemplate,
									operator: EQUAL,
								},
								{
									label: i18next.t("item_code"),
									name: "articoloCod",
									operator: CONTAINS,
									type: TEXTFIELD,
								},
								{
									label: i18next.t("variant") + " " + 1,
									name: "keyTaglia",
									operator: CONTAINS,
									type: TEXTFIELD,
								},
								{
									label: i18next.t("variant") + " " + 2,
									name: "keyColore",
									operator: CONTAINS,
									type: TEXTFIELD,
								},
								{
									label: i18next.t("production_lot"),
									name: "lottoProduzione",
									operator: CONTAINS,
									type: TEXTFIELD,
								},
							],
						},
					],
				},
			],
		},
	];

	// eslint-disable-next-line
	const stringPropsFields = useMemo(() => JSON.stringify(fields), [fields]);

	useEffect(() => {
		const comboQueries = [
			{
				findFunction: "/brand/getBrands",
				orderBy: [{ property: "name", direction: "ASC" }],
				findFilters: [],
				setItems: setItemsOtidMarchi,
			},
			{
				findFunction: "/norma/findIwswdnormaMaker",
				orderBy: [{ property: "descNormativa", direction: "ASC" }],
				findFilters: [],
				setItems: setItemsIwswdnormaMaker,
			},
			{
				findFunction: "/norma/findIwswdnormaTemplateForCombo",
				orderBy: [{ property: "descrizioneTemplate", direction: "ASC" }],
				findFilters: [],
				setItems: setItemsIwswdnormaTemplate,
			},
		];
		axiosFetchAllCombo(comboQueries);
		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		let newRequiredArray = [];
		JSON.parse(stringPropsFields).forEach((paragraph) => {
			if (paragraph.type === TAB) {
				paragraph.items.forEach((card) => {
					card.items.forEach((item) => {
						if (item.required) {
							newRequiredArray.push(item.name);
						}
					});
				});
			} else {
				paragraph.items.forEach((item) => {
					if (item.required) {
						newRequiredArray.push(item.name);
					}
				});
			}
		});
		setRequiredFields(newRequiredArray);
	}, [stringPropsFields]);

	useEffect(() => {
		if (!checkOpen(props.open)) {
			setAttemptSave(false);
		}
	}, [props.open]);

	useEffect(() => {
		if (selectedMainImage) {
			setMainImageUrl(URL.createObjectURL(selectedMainImage));
		}
	}, [selectedMainImage]);

	useEffect(() => {
		if (selectedTechImage) {
			setTechImageUrl(URL.createObjectURL(selectedTechImage));
		}
	}, [selectedTechImage]);

	const handleOnSave = () => {
		setAttemptSave(true);

		let allOk = true;
		requiredFields.some((requiredField) => {
			if (Array.isArray(requiredField)) {
				// Controllo che almeno uno degli storeName della Combo sia valorizzato
				let allComboNull = true;
				requiredField.forEach((reqField) => {
					if (props.profile[reqField.storeName]) {
						allComboNull = false;
					}
				});
				if (allComboNull) {
					allOk = false;
					return true;
				}
			} else {
				if (!props.profile[requiredField]) {
					allOk = false;
					return true;
				}
			}
			return false;
		});
		if (allOk) {
			onSave();
		} else {
			dispatch(
				setGlobalError({
					module: module,
					errorMessage: i18next.t("error_enter_all_required_fields"),
				})
			);
		}
	};

	const onClose = () => {
		removeMainImage();
		removeTechImage();
		props.close();
	};

	const removeMainImage = () => {
		setMainImageUrl(null);
		setSelectedMainImage(null);
		setMainImageArrayBuffer(null);
	};

	const removeTechImage = () => {
		setTechImageUrl(null);
		setSelectedTechImage(null);
		setTechImageArrayBuffer(null);
	};

	const validaProfilo = async () => {
		const reqItems = {
			profileId: props.profile.idProfile,
		};

		await standardAxiosPost({
			dispatch: dispatch,
			procedureName: "/productProfile/validateProductProfile",
			parameters: reqItems,
			onSuccess: (response) => {
				props.setControlledFilter(true);
				props.close();
			},
		});
	};

	const uploadImage = async (endpoint, imageArrayBuffer, uid) => {
		const token = localStorage.getItem("1TrueIdToken");
		const formData = new FormData();
		formData.append("image", new Blob([imageArrayBuffer.content]), "image");
		formData.append("profileId", uid);

		await axiosInstance
			.post("/productProfile/" + endpoint, formData, {
				headers: {
					Authorization: `Bearer ${token}`,
					"X-Authentication-Strategy": "oauth2",
					"Content-Type": "multipart/form-data",
				},
			})
			.catch((error) => {
				console.error(error);
			});
	};

	const deleteImage = async (endpoint, key) => {
		const token = localStorage.getItem("1TrueIdToken");
		const body = {
			profileId: props.profile.idProfile,
		};
		dispatch(activateLoading(module));
		await axiosInstance
			.post("/productProfile/" + endpoint, body, {
				headers: {
					Authorization: `Bearer ${token}`,
					"X-Authentication-Strategy": "oauth2",
				},
			})
			.then((response) => {
				if (response.status === 200) {
					props.setProfile((prevState) => ({ ...prevState, [key]: "" }));
					props.setControlledFilter(true);
					props.close();
					dispatch(deactivateLoading(module));
				}
			})
			.catch((error) => {
				console.error(error);
				dispatch(deactivateLoading(module));
			});
	};

	const onSave = async () => {
		let procedureName;

		if (props.newProfile) procedureName = "addProductLotto";
		else procedureName = "updateProductLotto";

		dispatch(activateLoading(module));
		const resp = await axiosPost("/productProfile/" + procedureName, props.profile);
		if (resp["success"]) {
			// Se ricevo dal backend l'oggetto appena creato con uno uid valido
			const createdProduct = resp.data;
			if (createdProduct?.uid) {
				// se ho settato un'immagine principale e ho ottenuto l'arrayBuffer corrispondente,
				// allora invio l'immagine al backend
				if (mainImageArrayBuffer) {
					await uploadImage("updateMainImage", mainImageArrayBuffer, createdProduct.uid);
				}
				// se ho settato un'immagine tecnica e ho ottenuto l'arrayBuffer corrispondente,
				// allora invio l'immagine al backend
				if (techImageArrayBuffer) {
					await uploadImage(
						"updateTechnicalImage",
						techImageArrayBuffer,
						createdProduct.uid
					);
				}
			}
			dispatch(deactivateLoading(module));
			props.setControlledFilter(true);
			onClose();
		} else {
			dispatch(deactivateLoading(module));
			dispatch(
				setGlobalError({
					module: module,
					title: resp["title"],
					errorMessage: resp["message"],
				})
			);
		}
	};

	const fetchData = async (idProfileTemplate) => {
		dispatch(activateLoading(module));
		const language = navigator.language.substring(0, 2);
		await axiosInstance
			.get(
				"norma/getTemplateProfileInfo?idProfileTemplate=" +
					idProfileTemplate +
					"&lingua=" +
					language,
				{
					headers: {
						Authorization: `Bearer ${token}`,
						"X-Authentication-Strategy": "oauth2",
					},
				}
			)
			.then((response) => {
				if (response.status === 200) {
					setSelectedAssignment(response.data.data);
				}
				dispatch(deactivateLoading(module));
			})
			.catch((error) => {
				console.error(error);
				dispatch(deactivateLoading(module));
			});
	};

	const deleteAssignedTemplate = async (item, idProfile) => {
		await standardAxiosPost({
			dispatch: dispatch,
			procedureName: "/norma/deleteIwswdnormaProfileTemplates",
			parameters: { idProfileTemplate: item, idProfile: idProfile },
			onSuccess: () => {
				setReloadTemplatesGrid(true);
				props.setControlledFilter(true);
				invalidaProfilo();
			},
		});
	};

	const invalidaProfilo = () => {
		const statiValidita = props.itemsOtidObjectProfileStati;
		let descrStato = "";
		statiValidita.forEach((statoObj) => {
			if (statoObj.stato === 5) {
				descrStato = statoObj.descrizione;
				return;
			}
		});

		props.setProfile((prevState) => ({
			...prevState,
			statoValidazione: 5,
			descStatoValidazione: descrStato,
		}));
	};

	return (
		<>
			<SaitDialog
				open={checkOpen(props.open)}
				title={
					props.newProfile
						? i18next.t("new") + " " + i18next.t("product_profile")
						: i18next.t("edit") + " " + i18next.t("product_profile")
				}
				width={"lg"}
				scroll={"paper"}
				flexDirection="column"
				content={
					<>
						{!props.newProfile && (
							<Box
								component="form"
								sx={{
									"& > :not(style)": { m: 1 },
									display: "flex",
									flexWrap: "wrap",
									gap: "10px",
									flexDirection: "row",
									mt: "10px",
									mr: "0px",
								}}
								noValidate
								justifyContent={"center"}
								autoComplete="off"
								alignItems="center"
							>
								<TextField
									size="small"
									label={i18next.t("validation_state")}
									color={
										props.profile.statoValidazione === 30 ? "success" : "error"
									}
									value={props.profile.descStatoValidazione}
									InputProps={{
										readOnly: true,
									}}
									focused
								/>
								<SaitButton
									disabled={props.profile.statoValidazione === 30}
									text={i18next.t("validate")}
									sx={{ color: "secondary.main" }}
									onClick={validaProfilo}
								/>
							</Box>
						)}
						<EditPanel
							attemptSave={attemptSave}
							stateElement={props.profile}
							setFunction={props.setProfile}
							fields={fields}
							open={props.open}
						/>
					</>
				}
				actions={
					<>
						<SaitButton
							text={i18next.t("close")}
							color="error"
							endIcon={<CancelIcon />}
							onClick={() => onClose()}
						/>
						<div style={{ flexGrow: 1 }} />
						{onSave && (
							<SaitButton
								text={i18next.t("save")}
								endIcon={<SaveIcon />}
								onClick={handleOnSave}
								color={"success"}
							/>
						)}
					</>
				}
			/>
			<NuovaAssociazioneTemplate
				open={openNewAssignment}
				setControlledFilter={setReloadTemplatesGrid}
				setControlledExternalFilter={props.setControlledFilter}
				profile={props.profile}
				fetchData={fetchData}
				invalidaProfilo={invalidaProfilo}
				close={() => {
					setOpenNewAssignment(false);
				}}
			/>
			<EditAssociazioneTemplate
				open={selectedAssignment}
				assignment={selectedAssignment}
				selectedProfile={{ idProfile: props.profile.idProfile }}
				setControlledExternalFilter={props.setControlledFilter}
				setAssignment={setSelectedAssignment}
				invalidaProfilo={invalidaProfilo}
				close={() => {
					setSelectedAssignment({});
				}}
			/>
		</>
	);
}
